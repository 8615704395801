import { CryptoContainer, Container } from "./CryptoBoard.styles";

import EtheriumIcon from "../../../assets/EtheriumIcon.svg";
import USDCIcon from "../../../assets/USDCIcon.svg";
import USDTIcon from "../../../assets/USDTIcon.svg";
import BitcoinIcon from "../../../assets/bitcoinIcon.svg";

const data = [
	{
		icon: BitcoinIcon,
		symbol: "BTC",
		price: "$62.9",
		chartColor: "#79FF86",
		chartStroke: "#FF4B4A",
	},
	{
		icon: EtheriumIcon,
		symbol: "Etherium",
		price: "$4,879",
		chartColor: "#FFD700",
		chartStroke: "#1E90FF",
	},
	{
		icon: USDCIcon,
		symbol: "USDC",
		price: "$310.5",
		chartColor: "#FFA500",
		chartStroke: "#32CD32",
	},
	{
		icon: USDTIcon,
		symbol: "USDT",
		price: "$1.29",
		chartColor: "#FF4500",
		chartStroke: "#8A2BE2",
	},
];

export function CryptoBoard() {
	return (
		<Container>
			<h3 className="font-bold ml-2 mb-2 xl:text-xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl">
				Crypto
			</h3>
			<CryptoContainer>
				{data.map((item, index) => (
					<div
						key={index}
						className="flex items-center justify-between py-1 px-1 bg-transparent rounded-[10px] 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl"
					>
						<span className="flex items-center gap-2 w-[110px]">
							<img
								src={item.icon}
								alt={`${item.symbol} Icon`}
								className="2xl:w-[30px] 2xl:h-[30px] 3xl:w-[40px] 3xl:h-[40px] 4xl:w-[50px] 4xl:h-[50px]"
							/>
							{item.symbol}
						</span>
						<span className="flex items-center gap-1">
							{item.price}
							<svg
								width="16"
								height="12"
								viewBox="0 0 16 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4.00007 5.49998L4.00007 4.99998L3.70325 4.99998L3.56112 5.26055L4.00007 5.49998ZM10.5001 5.49998L10.5001 5.99998L10.7072 5.99998L10.8536 5.85353L10.5001 5.49998ZM16.0001 0.499936C16.0001 0.223794 15.7762 -6.23152e-05 15.5001 -6.17277e-05L11.0001 -3.96305e-05C10.7239 -3.81e-05 10.5001 0.22382 10.5001 0.499963C10.5001 0.776105 10.7239 0.999962 11.0001 0.99996L15.0001 0.999941L15.0001 4.99994C15.0001 5.27608 15.2239 5.49994 15.5001 5.49994C15.7762 5.49994 16.0001 5.27608 16.0001 4.99994L16.0001 0.499936ZM1.439 11.2394L4.43901 5.73941L3.56112 5.26055L0.561104 10.7605L1.439 11.2394ZM4.00007 5.99998L10.5001 5.99998L10.5001 4.99998L4.00007 4.99998L4.00007 5.99998ZM10.8536 5.85353L15.8536 0.85349L15.1465 0.146387L10.1465 5.14643L10.8536 5.85353Z"
									fill={item.chartColor}
								/>
							</svg>
						</span>
						<span>
							<svg
								width="99"
								height="22"
								viewBox="0 0 99 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 10.7126L2.7963 11.4064C4.59259 12.1001 8.18519 13.4877 11.7778 10.7126C15.3704 7.93759 18.963 1 22.5556 1C26.1481 1 29.7407 7.93759 33.3333 13.4877C36.9259 19.0377 40.5185 23.2003 44.1111 19.7315C47.7037 16.2627 51.2963 5.16255 54.8889 5.85631C58.4815 6.55007 62.0741 19.0377 65.6667 20.4252C69.2593 21.8128 72.8519 12.1001 76.4444 11.4064C80.037 10.7126 83.6296 19.0377 87.2222 20.4252C90.8148 21.8128 94.4074 16.2627 98 12.1001"
									stroke={item.chartStroke}
									strokeWidth="1.5"
								/>
							</svg>
						</span>
						<div className="flex items-center gap-2">
							<button
								type="button"
								className="px-5 py-2 rounded-lg text-[10px] 1xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl 4xl:px-10 4xl:py-4 text-white leading-3 hover:brightness-75 transition bg-[#1e1e1e]"
							>
								Ver histórico
							</button>
							<button
								type="button"
								className="px-5 py-2 rounded-lg text-[10px] 1xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text- 5xl:text-4xl 4xl:px-10 4xl:py-4 text-white leading-3 hover:brightness-75 transition bg-[#ff9500]"
							>
								Comprar
							</button>
						</div>
					</div>
				))}
			</CryptoContainer>
		</Container>
	);
}
