import { useNavigate } from "react-router-dom";
import copyAndPastePix from "../../assets/copyAndPastePix.svg";
import favoritesPix from "../../assets/favoritesPix.svg";
import pixQrCode from "../../assets/pixQrCode.svg";
import { LinkOption } from "../../components/LinkOptions";
import { NewPix } from "./components/NewPix";

export function PixOptions() {
	const navigate = useNavigate();

	return (
		<section className="flex items-start justify-start gap-7 flex-col px-7 py-11">
			<div className="space-y-7">
				<h4 className="text-xl font-bold">Ações</h4>
				<div className="flex items-center justify-start flex-wrap gap-7">
					<NewPix />
					<button type="button" onClick={() => navigate("/pix/new-qrcode")}>
						<LinkOption title="Pix QR" icon={pixQrCode} />
					</button>
					<LinkOption title="Copia e Cola" icon={copyAndPastePix} />
				</div>
			</div>
			<div className="space-y-7">
				<h4 className="text-xl font-bold">Chaves pix</h4>
				<button
					type="button"
					onClick={() => navigate("/pix/favorites")}
					className="flex items-center justify-items-start flex-wrap gap-7"
				>
					<LinkOption title="Favoritos" icon={favoritesPix} />
				</button>
			</div>
		</section>
	);
}
