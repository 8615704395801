// Arquivo: UserBalance.tsx
import { Eye } from "lucide-react";
import YellowBagOfMoney from "../../../assets/bagOfMoney.svg";
import { Container } from "./UserBalance.styles";

export function UserBalance() {
	function getGreetingMessage(name: string): string {
		const currentHour = new Date().getHours();

		if (currentHour >= 5 && currentHour < 12) {
			return `Bom dia, ${name}`;
		}
		if (currentHour >= 12 && currentHour < 18) {
			return `Boa tarde, ${name}`;
		}
		return `Boa noite, ${name}`;
	}

	function formatValueToBRL(value: number): string {
		return value.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});
	}

	return (
		<Container>
			<div className="flex items-center justify-between 3xl:mt-5">
				<h4 className="font-bold text-2xl 1xl:text-3xl 2xl:text-4xl 3xl:text-5xl 4xl:text-6xl">
					{getGreetingMessage("Clemilson")}
				</h4>
				<Eye className="w-[25px] h-[25px] 1xl:w-[35px] 1xl:h-[35px] 2xl:w-[50px] 2xl:h-[50px] 3xl:w-[70px] 3xl:h-[70px] 4xl:w-[100px] 4xl:h-[100px]" />
			</div>
			<div className="flex items-center justify-between h-[70%] 3xl:h-[90%] 3xl:justify-around">
				<div className="flex items-center justify-start gap-3 3xl:gap-10">
					<img
						src={YellowBagOfMoney}
						className="w-[50px] h-[50px] 2xl:w-[100px] 2xl:h-[100px] 3xl:w-[150px] 3xl:h-[150px]"
						alt=""
					/>
					<div className="flex flex-col justify-start items-start gap-1">
						<span className="text-[20px] 1xl:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl text-[#718ebf]">
							Meu saldo
						</span>
						<span className="font-bold text-2xl 1xl:text-3xl 2xl:text-4xl 3xl:text-5xl 4xl:text-6xl">
							{formatValueToBRL(1275)}
						</span>
					</div>
				</div>

				<hr className="h-[60px] w-[2px] 2xl:h-[100px] 3xl:h-[132px] 4xl:h-[200px] border border-[#E2EBFD]" />

				<div>
					<div className="flex items-center justify-between gap-5">
						<span className="text-[#718ebf] 1xl:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-6xl">
							Entrada:
						</span>
						<span className="text-[#16dbaa] text-2xl 1xl:text-3xl 2xl:text-4xl 3xl:text-5xl 4xl:text-6xl font-bold">
							{formatValueToBRL(1275)}
						</span>
					</div>
					<div className="flex items-center justify-between gap-5">
						<span className="text-[#718ebf] 1xl:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl">
							Saída:
						</span>
						<span className="text-[#FF4B4A] text-2xl 1xl:text-3xl 2xl:text-4xl 3xl:text-5xl 4xl:text-6xl font-bold">
							{formatValueToBRL(1275)}
						</span>
					</div>
				</div>
			</div>
		</Container>
	);
}
