import styled from "styled-components";

export const Container = styled.div`
  left: -1%;
 position: relative;
 bottom: -1.5%;
 min-width: 100%; 
  min-height: 53%; 
  display: flex;
  flex-direction: column;

  @media (max-width: 3024px) {
    /* Para MacBook Pro 14" e resoluções menores */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2560px) {
    /* Para MacBook Air e Pro 13" */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2304px) {
    /* Para iMac 21.5" (4K Retina) */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2234px) {
    /* Para MacBook Pro 16" (M1) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2880px) {
    /* Para iMac 27" (5K Retina) */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 2520px) {
    /* Para iMac 24" (M1) */
    width: 47%;
    height: 47%;
    margin: 1.5%;
  }

  @media (max-width: 3384px) {
    /* Para Pro Display XDR (32") */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  /* Media Queries para dispositivos Windows */
  @media (max-width: 1366px) {
    /* Para laptops básicos (HD) */
    width: 100%; /* Divs embaixo uma da outra */
    height: 48%;
    margin-bottom: 2%;
  }

  @media (max-width: 1920px) {
    /* Para monitores padrão (Full HD) */
    width: 48%;
    height: 48%;
    margin: 1%;
  }

  @media (max-width: 2560px) {
    /* Para monitores avançados (QHD/2K) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3200px) {
    /* Para laptops premium (QHD+) */
    width: 45%;
    height: 48%;
    margin: 1.5%;
  }

  @media (max-width: 3840px) {
    /* Para monitores 4K */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3440px) {
    /* Para monitores Ultrawide */
    width: 48%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 5120px) {
    /* Para monitores 5K */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 7680px) {
    /* Para monitores 8K (futuro) */
    width: 45%;
    height: 50%;
    margin: 1%;
  }
`;

// Contêiner principal do componente
export const CryptoContainer = styled.div`
  min-width: 100%;
  min-height: 90%; /* Garante que o filho não ultrapasse o limite do contêiner */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;
  background: transparent;
  border-radius: 25px;
  padding: 1.5rem 3.375rem; 
  margin-bottom: 0.5rem;
  border: 1px solid #B1B1B1;
  background-color: #FAFAFA;

  /* Media Queries para responsividade */
  @media (max-width: 768px) {
    min-height: 70%;
    gap: 4px;
  }

  @media (max-width: 1366px) {
    /* Laptops básicos (HD) */
    padding: 0.5rem 1.375rem; 
  }

  @media (max-width: 480px) {
    min-height: 60%;
    gap: 2px;
  }
`;

export const CryptoHeader = styled.h4`
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1rem;

  /* Responsividade */
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

// Cartão para cada criptomoeda
export const CryptoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: transparent;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  /* Responsividade */
  @media (max-width: 768px) {
    padding: 0.3rem 0.8rem;
  }

  @media (max-width: 480px) {
    padding: 0.2rem 0.5rem;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 110px;

  img {
    width: 24px;
    height: 24px;
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.1);
  }

  /* Responsividade */
  @media (max-width: 768px) {
    width: 90px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 480px) {
    width: 70px;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

export const PriceWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// Ícone de gráfico
export const ChartIcon = styled.svg.attrs((props) => ({
	xmlns: "http://www.w3.org/2000/svg",
	width: 99,
	height: 22,
	viewBox: "0 0 99 22",
}))`
  path {
    stroke: ${(props) => props.stroke};
    fill: none;
    stroke-width: 1.5;
  }
`;

// Seção de ações
export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HistoryButton = styled.button`
  width: 80px;
  height: 24px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #1e1e1e;
  transition: brightness 0.3s ease;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const BuyButton = styled.button`
  width: 80px;
  height: 24px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #ff9500;
  transition: brightness 0.3s ease;

  &:hover {
    filter: brightness(0.8);
  }
`;

// Estilo adicional para a área de rolagem
export const ScrollableContent = styled.div`
  width: 551px;
  height: 228px;
  background-color: #fbfafa;
  border: 0.5px solid #818181;
  border-radius: 25px;
  padding: 1rem;
  overflow-y: hidden;
  transition: all 0.3s ease;

  &:hover {
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
