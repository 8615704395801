import { SendHorizonal } from "lucide-react";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import logoBlack from "src/assets/logo-black.png";
import { Loading } from "src/components/loading";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import {
  Message,
  RabinhoDaMensagem,
  Ticket,
} from "src/pages/support/ChatModal";
import { api } from "src/services/api";
import socket from "src/services/socket-io";

const SupportBackoffice: React.FC = () => {
  const { user } = useAuthBackoffice();

  const [text, setText] = useState("");

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();

  const [loadingTickets, setLoadingTickets] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  const token = localStorage.getItem("@backoffice:token");

  const [tab, setTab] = useState<"CHATS" | "CLOSEDS">("CHATS");

  const closedTickets = tickets.filter((t) => t.status === "CLOSE");
  const restTickets = tickets.filter((t) => t.status != "CLOSE");

  const inputRef = useRef<HTMLInputElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const listTickets = async () => {
    try {
      setLoadingTickets(true);
      const response = await api.get("/tickets", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setTickets(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoadingTickets(false);
  };

  const handleNewMessage = (data: Message) => {
    if (data) {
      setTickets((tickets) =>
        tickets.map((ticket) =>
          ticket.id === data.ticketId
            ? { ...ticket, messages: [...ticket.messages, data] }
            : ticket
        )
      );

      setSelectedTicket((t) => {
        if (t && t.id === data.ticketId) {
          return { ...t, messages: [...t.messages, data] };
        }
        return t;
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedTicket]);

  useEffect(() => {
    listTickets();

    socket.on("updateTicket", (ticket: Ticket) => {
      setTickets((tickets) =>
        tickets.map((t) => (ticket?.id == t.id ? ticket : t))
      );
      if (selectedTicket?.id == ticket.id) {
        setSelectedTicket(ticket);
      }
    });

    socket.on("ticket-new-message", handleNewMessage);

    socket.on(`ticketCreated-${user?.id}`, (ticket: Ticket) => {
      setTickets((tickets) => [...tickets, ticket]);
    });

    return () => {
      socket.off(`ticketCreated-${user?.id}`);
      socket.off("updateTicket");
      socket.off("ticket-new-message");
    };
  }, []);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const height = messagesContainerRef.current?.offsetHeight / 2;
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight - height;
    }
  };

  const sendMessage = async () => {
    const content = text;
    try {
      if (sendingMessage) return;
      setSendingMessage(true);
      if (!selectedTicket) return;
      if (!text) return;

      setText("");

      await api.post(
        `/tickets/${selectedTicket.number}/message`,
        {
          content,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      scrollToBottom();
      setTimeout(() => {
        inputRef.current?.focus();
      }, 20);

      setText("");
    } catch (error) {
      console.log(error);
      setText(content);
      setSendingMessage(false);
    }
    setSendingMessage(false);
  };

  const closeTicket = async () => {
    try {
      if (selectedTicket) {
        const response = await api.put(
          `/tickets/update`,
          {
            id: selectedTicket.id,
            status: "CLOSE",
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        setTickets((tickets) =>
          tickets.map((t) => (selectedTicket?.id == t.id ? response.data : t))
        );

        setSelectedTicket(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAssignTicket = async (ticket: Ticket) => {
    try {
      const response = await api.put(
        `/tickets/update`,
        {
          id: ticket.id,
          status: "OPEN",
          assignedBy: user?.id,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      setTickets((tickets) =>
        tickets.map((t) => (ticket?.id == t.id ? response.data : t))
      );

      setSelectedTicket(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-full h-[calc(100vh-170px)] rounded-md shadow-xl border border-zinc-100 overflow-hidden"
    >
      <div className="w-full h-14 bg-[#864E13]">
        <div className="flex relative w-full h-full items-center">
          <div className="flex flex-1 items-center justify-center gap-2">
            <span
              onClick={() => setTab("CHATS")}
              className={`text-white cursor-pointer hover:scale-[1.03] transition-all font-semibold rounded-full px-4 ${
                tab == "CHATS" && "bg-white text-[#864E13]"
              }`}
            >
              Conversas abertas
            </span>
            <span
              onClick={() => setTab("CLOSEDS")}
              className={`text-white cursor-pointer hover:scale-[1.03] transition-all font-semibold rounded-full px-4 ${
                tab == "CLOSEDS" && "bg-white text-[#864E13]"
              }`}
            >
              Conversas fechadas
            </span>
          </div>
          <div className="flex flex-[2] items-center justify-center">
            <span className="text-white font-semibold">Chat Vila Pay Bank</span>
          </div>
          {selectedTicket &&
            (selectedTicket.status == "CLOSE" ? (
              <div className="flex shadow-lg absolute right-[20px] px-2 py-1 rounded-md bg-red-600 items-center justify-center">
                <span className="text-white">Conversa encerrada</span>
              </div>
            ) : (
              <button
                onClick={closeTicket}
                className="flex active:bg-opacity-70 shadow-lg absolute right-[20px] px-2 py-1 rounded-md bg-red-600 items-center justify-center"
              >
                <span className="text-white">Encerrar conversa</span>
              </button>
            ))}
        </div>
      </div>
      <div className=" relative grid grid-cols-3 bg-zinc-100 h-full max-h-[calc(100%-56px)] w-full">
        <div className="col-span-1 divide-y-[1px] overflow-y-auto hideScrollbar bg-white">
          {tickets.length == 0 && (
            <div className="flex w-full items-center h-10 justify-center">
              <span>Não há conversas</span>
            </div>
          )}
          {tab == "CHATS"
            ? restTickets.map((item) => {
                if (item.status === "OPEN" && item.assigned != user?.id)
                  return null;
                return (
                  <TicketDetails
                    key={item.id}
                    isSelected={selectedTicket?.id == item.id}
                    onSelectTicket={(ticket) => {
                      setSelectedTicket(ticket);
                      if (ticket.status == "WAITING") {
                        handleAssignTicket(ticket);
                      }
                    }}
                    ticket={item}
                  />
                );
              })
            : closedTickets.map((item) => {
                if (item.status === "OPEN" && item.assigned != user?.id)
                  return null;
                return (
                  <TicketDetails
                    key={item.id}
                    isSelected={selectedTicket?.id == item.id}
                    onSelectTicket={(ticket) => {
                      setSelectedTicket(ticket);
                      if (ticket.status == "WAITING") {
                        handleAssignTicket(ticket);
                      }
                    }}
                    ticket={item}
                  />
                );
              })}
        </div>
        <div
          ref={messagesContainerRef}
          className="col-span-2 relative max-h-[calc(100%-64px)] overflow-y-auto"
        >
          <div className="flex flex-col flex-1 bg-zinc-100 justify-end p-8 gap-4">
            {loadingTickets && (
              <div className="flex w-full items-center h-10 justify-center">
                <span>Carregando...</span>
              </div>
            )}

            {!selectedTicket && tickets?.length != 0 && (
              <div className="flex w-full items-center h-10 justify-center">
                <span>Selecione uma conversa</span>
              </div>
            )}

            {selectedTicket?.messages?.length == 0 && (
              <div className="flex w-full items-center h-10 justify-center">
                <span>Nenhuma mensagem nessa conversa</span>
              </div>
            )}

            {!loadingTickets &&
              selectedTicket?.messages.map((msg, index) => {
                const isClientMessage = msg.author === user?.id;

                const lastMessage = selectedTicket.messages[index - 1];
                const showRabinhoDaMensagem = lastMessage
                  ? msg.author != lastMessage.author
                  : true;

                return (
                  <div
                    key={msg.id}
                    className={`flex flex-col relative min-w-[120px] p-2 max-w-[80%] ${
                      isClientMessage
                        ? showRabinhoDaMensagem
                          ? "self-end text-right bg-[#AA7339] text-white text-base shadow-md rounded-b-xl rounded-tl-xl"
                          : "self-end text-right bg-[#AA7339] text-white text-base shadow-md rounded-xl"
                        : showRabinhoDaMensagem
                        ? "self-start bg-white text-zinc-800 text-base font-semibold shadow-md rounded-b-xl rounded-tr-xl "
                        : "self-start bg-white text-zinc-800 text-base font-semibold shadow-md rounded-xl"
                    }`}
                  >
                    {msg.content}
                    <span className={`text-xs font-normal`}>
                      {moment(msg.created_at).format("DD/MM/YY - HH:mm")}
                    </span>
                    {showRabinhoDaMensagem && (
                      <RabinhoDaMensagem
                        isClientMessage={isClientMessage}
                        color={isClientMessage ? "#AA7339" : "#fff"}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="absolute flex bottom-0 right-0 w-[66.666%] h-16 ">
          <div className="reltaive flex flex-1">
            <input
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  sendMessage();
                }
              }}
              autoFocus
              ref={inputRef}
              disabled={selectedTicket?.status == "CLOSE"}
              value={text}
              onChange={(e) => setText(e.target.value)}
              type="text"
              placeholder={
                selectedTicket?.status == "CLOSE"
                  ? "Essa conversa foi encerrada."
                  : "Escreva sua mensagem"
              }
              className="flex shadow-inner bg-zinc-50 h-full w-full px-4"
            />
            <button
              disabled={selectedTicket?.status == "CLOSE"}
              onClick={sendMessage}
              className="flex active:opacity-75 bg-[#AA7339] items-center justify-center h-full aspect-square"
            >
              {sendingMessage ? (
                <Loading
                  isLoading
                  style={{
                    color: "white",
                    height: 26,
                    width: 26,
                  }}
                />
              ) : (
                <SendHorizonal size={25} color="#fff" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportBackoffice;

const TicketDetails: FC<{
  onSelectTicket: (ticket: Ticket) => void;
  ticket: Ticket;
  isSelected: boolean;
}> = ({ isSelected, onSelectTicket, ticket }) => {
  return (
    <div
      onClick={() => {
        onSelectTicket(ticket);
      }}
      className={`flex items-center hover:bg-zinc-200 ${
        isSelected ? "bg-zinc-100" : "bg-white"
      } gap-2 p-2 h-16 w-full cursor-pointer`}
    >
      <div className="w-9 h-9 flex items-center justify-center overflow-hidden rounded-full bg-white">
        <img className="object-contain" src={logoBlack} />
      </div>
      <div className="flex relative w-full flex-col justify-center">
        <span className="text-sm font-bold">{ticket.title}</span>
        <span className="text-sm">
          {moment(ticket.created_at).format("DD/MM/YYYY")}
        </span>
        <div className="absolute flex flex-col 2xl:flex-row gap-2 2xl:gap-8 items-end justify-between right-2">
          {ticket.status == "WAITING" ? (
            <span className="underline font-bold text-xs 2xl:text-sm text-zinc-800">
              Clique para pegar o ticket
            </span>
          ) : (
            <></>
          )}
          <TicketStatus status={ticket.status} />
        </div>
      </div>
    </div>
  );
};

const mappedTicketStatus = {
  WAITING: "Esperando",
  OPEN: "Em conversa",
  CLOSE: "Fechado",
};

export const TicketStatus: FC<{ status: "WAITING" | "OPEN" | "CLOSE" }> = ({
  status,
}) => {
  return (
    <div
      className={`flex items-center font-bold justify-center w-24 py-[2px] h-min rounded-full shadow-sm text-xs text-white ${
        status == "CLOSE"
          ? "bg-red-500"
          : status == "OPEN"
          ? "bg-green-500"
          : "bg-amber-600"
      }`}
    >
      {mappedTicketStatus[status]}
    </div>
  );
};
