import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AprovacoesProvider } from "./pages/aprovacoes/AprovacoesContext";

const metaTag = document.createElement("meta");
metaTag.httpEquiv = "X-XSS-Protection";
metaTag.content = "1; mode=block";
document.head.appendChild(metaTag);

const rootElement = document.getElementById("root");

if (rootElement) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<React.StrictMode>
			<AprovacoesProvider>
				<App />
			</AprovacoesProvider>
		</React.StrictMode>,
	);
} else {
	console.error("Elemento raiz 'root' não encontrado.");
}
