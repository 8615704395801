// styles.ts
import styled from "styled-components";

export const PixAreaContainer = styled.div`
  position: relative;
  top: -30%; /* Move para cima */
  min-width: 100%;
  min-height: 60%; /* Garante que o filho não ultrapasse o limite do contêiner */
  margin: 0 1%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  padding: 3.125rem 3.375rem 3.125rem 3.375rem;
  max-height: 100%; /* Garante que o filho não ultrapasse o limite do contêiner */
  overflow: hidden; /* Esconde qualquer conteúdo extra */
  border: 1px solid #B1B1B1;
  background-color: #FAFAFA;

  /* Media Queries para dispositivos Apple e Windows */
  @media (max-width: 1366px) {
    /* Laptops básicos (HD) */
    width: 100%;
    height: 48%;
    padding: 1.125rem 1.375rem 1.125rem 1.375rem;
    margin-bottom: 2%;
  }

  @media (max-width: 1920px) {
    /* Monitores padrão (Full HD) e MacBook 16" */
    width: 48%;
    height: 48%;
    margin: 1%;
  }

  @media (min-width: 2240px) {
    /* Monitores padrão (Full HD) e MacBook 16" */
    padding: 3.125rem 3.375rem 3.125rem 3.375rem;
  }

  @media (max-width: 2304px) {
    /* iMac 21.5" (4K Retina) */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2560px) {
    /* MacBook Pro 13", Air, Monitores 2K */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2880px) {
    /* iMac 27" (5K Retina) */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 3200px) {
    /* Laptops premium (QHD+) */
    width: 45%;
    height: 48%;
    margin: 1.5%;
  }

  @media (max-width: 3440px) {
    /* Monitores Ultrawide */
    width: 48%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3840px) {
    /* Monitores 4K */
    width: 45%;
    height: 50%;
    padding: 0.725rem 0.775rem 0.725rem 0.775rem;
    margin: 1%;
  }

  @media (max-width: 5120px) {
    /* Monitores 5K */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 7680px) {
    /* Monitores 8K */
    width: 45%;
    height: 50%;
    margin: 1%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  h5 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  p {
    color: #718EBF;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 73px;
    height: 73px;
    border-radius: 50%;
    background-color: #1e1e1e;
  }

  span {
    color: #718EBF;
    font-size: 10px;
  }
`;
