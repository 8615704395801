import { IconProps } from "src/interface/iconProps";

export const WalletIcon = ({ className }: IconProps) => {
	return (
		<svg
			viewBox="0 0 30 30"
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.2423 14.5456V9.69712H7.27263C6.62968 9.69712 6.01307 9.44171 5.55843 8.98707C5.1038 8.53244 4.84839 7.91582 4.84839 7.27288C4.84839 5.93954 5.9393 4.84863 7.27263 4.84863H21.8181V9.69712"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.84839 7.27295V21.8184C4.84839 23.1517 5.9393 24.2426 7.27263 24.2426H24.2423V19.3942"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M21.8183 14.5454C21.1753 14.5454 20.5587 14.8008 20.1041 15.2555C19.6495 15.7101 19.394 16.3267 19.394 16.9697C19.394 18.303 20.485 19.3939 21.8183 19.3939H26.6668V14.5454H21.8183Z"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
