import React from "react";

import {
  BoxBank,
  BoxLetter,
  Container,
  ContentWrapper,
  Divisorleft,
  DivisorRight,
  Line,
  Subtitle,
  SubtitleWrapper,
  Text,
  Title,
  WrapperButton,
  WrapperLeft,
  WrapperRight,
} from "./styles";

import teenagerConsumer from "../../assets/LPteenagerconsumer.png";
import youngbussinessman from "../../assets/LPyoungbussinessman.png";
import letterIcon from "../../assets/LPlettericon.svg";
import bankIcon from "../../assets/LPbankicon.svg";
import ButtonOpenAccount from "../LPbutton";

const SectionAccount: React.FC = () => {
  return (
    <Container id="Account">
      <ContentWrapper>
        <WrapperLeft>
          <SubtitleWrapper>
            <Line />
            <Subtitle >CONTA CORRENTE COMPLETA</Subtitle>
          </SubtitleWrapper>
          <Title>
            Um <strong>Universo</strong> de oportunidades
          </Title>
          <Text>
            Nascemos para fazer diferente. Participamos da vida de milhões de
            pessoas, reunindo em uma mesma plataforma soluções que fazem a
            diferença no seu dia a dia.
          </Text>
          <WrapperButton>
            <ButtonOpenAccount />
          </WrapperButton>
        </WrapperLeft>
        <WrapperRight>
          <Divisorleft>
            <img src={teenagerConsumer} alt={"teenager consumer"} />
            <BoxLetter>
              <img src={letterIcon} alt={"letter"} />
            </BoxLetter>
          </Divisorleft>
          <DivisorRight>
            <BoxBank>
              <img src={bankIcon} alt={"bank"} />
            </BoxBank>
            <img src={youngbussinessman} alt={"young bussiness man"} />
          </DivisorRight>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
};

export default SectionAccount;
