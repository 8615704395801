import { FC, useState } from "react";
import { FormInput, FormSelectInput, GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { serviceError } from "../../services/ajio";
import { useNotification } from "../../hooks/notification";
import { service } from "../../services/ajio";

import "./styles.css";
import { api } from "src/services/api";

const FormSchema = z.object({
  cnpj: z.string().nonempty("Insira o CNPJ."),
  email: z.string().nonempty("Insira um e-mail").email("O e-mail fornecido não é válido."),
  companyName: z.string().nonempty("Insira a Razão Social."),
  fantasyName: z.string().nonempty("Insira o Nome Fantasia."),
  phone: z.string().nonempty("Insira o Telefone."),
  foundationDate: z.string().nonempty("Insira a Data de fundação."),
  legalNature: z.string().nonempty("Insira a Natureza jurídica."),
  CNAE: z.string().nonempty("Insira o CNAE."),
  monthlyRevenue: z.string().nonempty("Insira sua Receita média mensal."),
  patrimony: z.string().nonempty("Insira seu Patrimônio."),
  shareCapital: z.string().nonempty("Insira seu Capital Social."),
  monthlyBilling: z.string().nonempty("Insira seu Faturamento Mensal."),
});

type FormInputs = z.infer<typeof FormSchema>;

export const InsertAccountDataPj: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const { setOpenNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue } = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      cnpj: '',
      email: '',
      companyName: '',
      fantasyName: '',
      phone: '',
      foundationDate: '',
      legalNature: '',
      CNAE: '',
      monthlyRevenue: '',
      patrimony: '',
      shareCapital: '',
      monthlyBilling: '',
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (data: FormInputs) => {
    setLoading(true);
    try {
      const formatCNPJ = (cnpj: string) => {
        return cnpj.replace(/[^\d]/g, '');
      };
      const formattedCNPJ = formatCNPJ(data.cnpj);
      const response = await api.get(`graphic/verify/email/${data.email}/${formattedCNPJ}`)
      console.log(response)

      localStorage.setItem("@yntech:signup-data-01", JSON.stringify(data));
      setLoading(false);
      navigate("/create-account-wallet/choose-plan", { state: { accountType } });


    } catch (err) {
      setLoading(false);
      setOpenNotification({
          title: "Alerta",
          notification:" E-mail ou Cnpj já esta cadastrado.",
          type: "warning",
      });
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/choose-account-type", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="700" height="95vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#AA7339" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="670"
            height="93vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[8vh] ml-20">
          <div className="w-full flex items-start gap-10">
            <p className="text-white font-medium text-lg mr-28 max-2xl:text-sm">
              Você está a poucos passos de uma <br />
              nova <strong>experiência financeira</strong>
            </p>

            <GradientButton title="1/7" tailwindStyles="bg-black text-white w-14" onClick={() => console.log("")} />
          </div>

          <p className="text-white text-base font-light mt-3 mb-4 max-2xl:text-xs">
            Todos os dados a seguir devem ser <br />
            obrigatoriamente do titular.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item: any, index: number) => {
              return (
                <div key={item.id}>
                  {item.breakPoint === false && (
                    <Controller
                      name={item.id as any}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormInput
                          onBlur={async () => {
                            try {
                              if(item.id === "cnpj") {
                                const formatedCNPJ = field?.value.replace(/\D/g, "");
                                const response = await service.get(`/auth/me/${formatedCNPJ}`);
                                const info = response.data;

                                if (!info) {
                                  setOpenNotification({
                                    notification: `Informações do CNPJ não encontradas`,
                                    title: `Erro`,
                                    type: "error",
                                  });
                                  return;
                                }

                                setValue("companyName", info.nome);
                                setValue("cnpj", info.cnpj);
                                setValue("email", info.email);
                                setValue("legalNature", info.natureza_juridica);
                                setValue("fantasyName", info.fantasia);
                                setValue("foundationDate", info.abertura);
                                setValue("CNAE", info.atividade_principal[0].code);
                                setValue("phone", info.telefone);
                                setValue("shareCapital", info.capital_social);
                              }
                            } catch (error) {
                              console.log(error);
                              return setLoading(false);
                            }
                          }}
                          onChangeText={(e) => field.onChange(e)}
                          height={55}
                          placeholder={item.placeholder}
                          required
                          value={field.value}
                          mask={item.mask}
                          errorMessage={fieldState.error?.message}
                        />
                      )}
                    />
                  )}

                  {item.breakPoint && (
                    <div className="flex items-center gap-5 justify-between">
                      {item.isDropdown && (
                        <Controller
                          name={item.id as any}
                          control={control}
                          render={({ field, fieldState }) => (
                            <FormSelectInput
                              style={{
                                width: 250,
                              }}
                              {...field}
                              placeholder={item.placeholder}
                              options={item!.options!}
                              onChanged={(e) => setValue("legalNature", e.target.value)}
                              error={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                      {!item.isDropdown && (
                        <Controller
                          name={item.id as any}
                          control={control}
                          render={({ field, fieldState }) => (
                            <FormInput
                              onChangeText={(e) => field.onChange(e)}
                              style={{
                                width: 250,
                              }}
                              autoFocus
                              placeholder={item.placeholder}
                              required
                              {...field}
                              mask={field?.value?.length > 0 ? item.mask : undefined}
                              errorMessage={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                      <Controller
                        name={fields[index + 1].id as any}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormInput
                            autoFocus
                            onChangeText={(e) => field.onChange(e)}
                            style={{
                              width: 250,
                            }}
                            placeholder={fields[index + 1].placeholder}
                            required
                            {...field}
                            mask={field?.value?.length > 0 ? fields[index + 1].mask : undefined}
                            errorMessage={fieldState.error?.message}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              );
            })}

            <div className="flex w-full gap-5 items-center justify-between mt-7">
              <GradientButton
                isLoading={loading}
                title="Voltar"
                tailwindStyles="bg-[#171717] text-white w-[16rem] h-12"
                onClick={() => navigate("/create-account-wallet/choose-account-type")}
              />
              <GradientButton
                isLoading={loading}
                title="Avançar"
                tailwindStyles="bg-[#AA7339] text-white w-[16rem] h-12"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const fields = [
  {
    id: "cnpj",
    placeholder: "CNPJ",
    breakPoint: false,
    mask: "99.999.999/9999-99",
  },
  {
    id: "email",
    placeholder: "Email",
    breakPoint: false,
  },
  {
    id: "companyName",
    placeholder: "Razão Social",
    breakPoint: false,
  },
  {
    id: "fantasyName",
    placeholder: "Nome fantasia",
    breakPoint: false,
  },
  {
    id: "phone",
    placeholder: "Telefone",
    mask: "(99) 99999-9999",
    breakPoint: true,
  },
  {
    id: "foundationDate",
    placeholder: "Data de fundação",
    mask: "99/99/9999",
    breakPoint: undefined,
  },
  {
    id: "legalNature",
    placeholder: "Natureza jurídica",
    breakPoint: true,
    isDropdown: true,
    options: ["SA", "LTDA", "SS", "ME", "EPP", "MEI", "EIRELI"],
  },
  {
    id: "CNAE",
    placeholder: "CNAE",
    mask: "9999-9/99",
    breakPoint: undefined,
  },
  {
    id: "monthlyRevenue",
    placeholder: "Receita média mensal (R$0,00)",
    breakPoint: true,
    mask: "currency",
  },
  {
    id: "patrimony",
    placeholder: "Patrimonio (R$0,00)",
    mask: "currency",
    breakPoint: undefined,
  },
  {
    id: "shareCapital",
    placeholder: "Capital Social (R$0,00)",
    breakPoint: true,
    mask: "currency",
  },
  {
    id: "monthlyBilling",
    placeholder: "Faturamento Mensal (R$0,00)",
    mask: "currency",
    breakPoint: undefined,
  },
];
