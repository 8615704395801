import { useState } from "react";
import { BeneficiaryDataStep } from "./BeneficiaryDataStep";
import { PasswordStep } from "./PasswordStep";
import { PixKeyStep } from "./PixKeyStep";
import { ProcessingTransactionStep } from "./ProcessingTransactionStep";
import { SuccessOnTransactionStep } from "./SuccessOnTrasactionStep";

export type NewPixFormProps = {
	nextStep: () => void;
};

export type BeneficiaryDataProps = {
	name?: string;
	document?: string;
	bank?: string;
	key?: string;
};

export function NewPixForm() {
	const [formStep, setFormStep] = useState(1);
	const [beneficiaryData, setBeneficiaryData] = useState<BeneficiaryDataProps>(
		{},
	);

	return (
		<div>
			{formStep === 1 ? (
				<PixKeyStep
					setBeneficiaryData={setBeneficiaryData}
					nextStep={() => setFormStep(formStep + 1)}
				/>
			) : formStep === 2 ? (
				<BeneficiaryDataStep
					beneficiaryData={beneficiaryData}
					nextStep={() => setFormStep(formStep + 1)}
				/>
			) : formStep === 3 ? (
				<PasswordStep nextStep={() => setFormStep(formStep + 1)} />
			) : formStep === 4 ? (
				<ProcessingTransactionStep nextStep={() => setFormStep(formStep + 1)} />
			) : formStep === 5 ? (
				<SuccessOnTransactionStep />
			) : null}
		</div>
	);
}
