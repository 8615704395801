import { z } from "zod";

export const keyStepSchema = z.object({
	key: z.string().min(1, "Selecione uma chave."),
});

export const valueStepDynamicSchema = z.object({
	value: z.string().min(1, "Selecione um valor."),
	name: z.string().min(1, "Selecione um nome."),
	tax_number: z.string().min(1, "Selecione um CPF/CNPJ."),
	date_expiration: z.string().min(10, "Selecione uma data de expiração."),
});

export const valueStepStaticSchema = z.object({
	value: z.string().min(1, "Selecione um valor."),
});

export type KeyStep = z.infer<typeof keyStepSchema>;
export type ValueStepDynamic = z.infer<typeof valueStepDynamicSchema>;
export type ValueStepStatic = z.infer<typeof valueStepStaticSchema>;
