import { LastTransactions } from "../LastTransactions";
import { Container } from "./MainLeftSection.styles";

const MainLeftSection: React.FC = () => {
  return (
    <Container>
            <LastTransactions />
    </Container>
  );
};

export default MainLeftSection;
