import { createContext, useContext, useState, type ReactNode } from "react";

export interface FormDataProps {
	amount?: string;
	hash?: string;
	image?: string;
}

interface MultiStepFormContextProps {
	formDynamicStep: number;
	formDynamicData: FormDataProps;
	setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>;
	nextStep: () => void;
	prevStep: () => void;
}

const QrCodeDynamicFormContext = createContext<
	MultiStepFormContextProps | undefined
>(undefined);

export function useQrCodeDynamicStepForm() {
	const context = useContext(QrCodeDynamicFormContext);
	if (!context) {
		throw new Error(
			"useMultiStepForm must be used within a MultiStepFormProvider",
		);
	}
	return context;
}

export const QrCodeDynamicFormProvider = ({
	children,
}: { children: ReactNode }) => {
	const [formDynamicStep, setFormDynamicStep] = useState(1);
	const [formDynamicData, setFormData] = useState<FormDataProps>({});

	const nextStep = () => setFormDynamicStep((prev) => prev + 1);
	const prevStep = () => setFormDynamicStep((prev) => prev - 1);

	return (
		<QrCodeDynamicFormContext.Provider
			value={{
				formDynamicStep,
				formDynamicData,
				setFormData,
				nextStep,
				prevStep,
			}}
		>
			{children}
		</QrCodeDynamicFormContext.Provider>
	);
};
