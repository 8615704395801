import React, {
	createContext,
	useCallback,
	useState,
	useContext,
	useEffect,
} from "react";

import { service as api, CommonHeaderProperties } from "../services/ajio";
import { useClient } from "./client";
import { useNotification } from "./notification";

export interface Account {
	status_pin_eletronic: boolean;
	id: string;
	refId: string;
	status: string;
	virtual_account_id: string;
	bank_account_id: string;
	branch_number: string;
	account_number: string;
	account_digit: any;
	alias_status: string;
	id_master_user?: string;
	status_master_user?: boolean;
	balance: number;
	created_at: string;
	updated_at: string;
	user_id: string;
	name: string;
	isPool: boolean;
	document: string;
	bank: string;
	accountNumber: string;
	branch: string;
	account: any;
	email: string;
	accountDigit: string;
	graphic_balance: number;
	graphic_transactions: number;
	number_identifier: string; // Ou `number` se for um número
	pin: string;
	security_eletronic: string;
	gestor_graphic: boolean;
	userMasterType?: string;
	userStatus?: string;
	password: string;
}

interface User {
	franchise_id: string;
	gestor_graphic: boolean;
	id: string;
	name: string;
	document: string;
	email: string;
	phone: {
		ddd: string;
		number: string;
		type: string;
	};
	gender: string;
	pin: string;
	security_eletronic: string;
	birthDate: string;
	monthlyInvoicing: string;
	educationLevel: string;
	maritalStatus: string;
	role: string;
	status: string;
	refId: string;
	type: string;
	created_at: string;
	updated_at: string;
	company_type: string | null;
	config_key_pix: string | null;
	api_key: string | null;
	password: string;
}

interface AuthState {
	token: string;
	account: Account;
	user: User;
}

type SignInCredentials =
	| {
			document: string;
			password: string;
	  }
	| { user_master_token?: string; individuals_token?: string };

interface AuthContextData {
	account: Account;
	user: User & {
		id_master_user?: string;
		status_master_user?: boolean;
		isMaster?: boolean;
	};
	reportBalance: number;
	signIn(credentials: SignInCredentials): Promise<void>;
	signInWithToken(token: string): Promise<void>;
	signOut(): void;
	refresh: () => void;
	refreshAccount: () => void;
	checkStep: (account: Account, token: string) => Promise<boolean | void>;
	// updateBalance(balance: number): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
	children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
	const { client } = useClient();
	const [data, setData] = useState<AuthState>(() => {
		const token = localStorage.getItem("@stricv2:token");
		const account = localStorage.getItem("@stricv2:account") as string;
		const user = localStorage.getItem("@stricv2:user") as string;

		if (token && account && user) {
			const accountData = JSON.parse(account);
			const userData = JSON.parse(user);

			api.defaults.headers = {
				Authorization: `Bearer ${token}`,
				account: accountData?.id,
			} as CommonHeaderProperties;

			return { token, account: accountData, user: userData };
		}
		console.log("opa estou aqui");
		return {} as AuthState;
	});
	const [balance, setBalance] = useState(0);

	async function checkStep(data: Account, token: string, user?: User) {
		console.log("ACCOUNT", data);

		if (data.status === "PENDING_DOCUMENTATION") {
			window.localStorage.setItem("@stricv2:status", "PENDING_DOCUMENTATION");
			window.localStorage.setItem("@stricv2:id", token);
			localStorage.removeItem("@stricv2:token");
			localStorage.removeItem("@stricv2:account");
			sessionStorage.removeItem("@stricv2:token");
			sessionStorage.removeItem("@stricv2:account");

			localStorage.setItem("@stricv2:token", token);
			return;
		}

		if (data.status === "under_review") {
			window.localStorage.setItem("@stricv2:status", "under_review");
			window.localStorage.setItem("@stricv2:id", token);
			localStorage.removeItem("@stricv2:token");
			localStorage.removeItem("@stricv2:account");
			sessionStorage.removeItem("@stricv2:token");
			sessionStorage.removeItem("@stricv2:account");

			localStorage.setItem("@stricv2:token", token);
			return;
		}

		// const type = data.type === 'PJ' ? 'pj' : 'pf';

		//   if (type === 'pj') {
		//       if (data.status === 'WAITING_TOKEN') {
		//         window.localStorage.setItem('@stricv2:status', 'WAITING_TOKEN');
		//         window.localStorage.setItem('@stricv2:id', token);
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         // window.location.href = `/create-account/?type=${type}`;
		//         return;
		//       }

		//       if (data.status === 'WAITING_FLOW') {
		//         window.localStorage.setItem('@stricv2:status', 'WAITING_FLOW');
		//         window.localStorage.setItem('@stricv2:id', token);
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         // window.location.href = `/create-account/?type=${type}`;
		//         return;
		//       }

		//       if (data.status === 'WAITING_PARTNERS') {
		//         window.localStorage.setItem('@stricv2:status', 'WAITING_PARTNERS');
		//         window.localStorage.setItem('@stricv2:id', token);
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         // window.location.href = `/create-account/?type=${type}`;
		//         return;
		//       }

		//       if (data.status === 'WAITING_ADDRESS') {
		//         window.localStorage.setItem('@stricv2:status', 'WAITING_ADDRESS');
		//         window.localStorage.setItem('@stricv2:id', token);
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         // window.location.href = `/create-account/?type=${type}`;
		//         return;
		//       }

		//       if (data.status === 'WAITING_DOCUMENTS') {
		//         window.localStorage.setItem('@stricv2:status', 'WAITING_DOCUMENTS');
		//         window.localStorage.setItem('@stricv2:id', token);
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         sessionStorage.removeItem('@stricv2:token');
		//         sessionStorage.removeItem('@stricv2:account');
		//         // window.location.href = `/create-account/?type=${type}`;
		//         return;
		//       }

		//       if (data.status === 'WAITING_VALIDATION') {
		//         window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
		//         window.localStorage.setItem('@stricv2:id', token);
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         sessionStorage.removeItem('@stricv2:token');
		//         sessionStorage.removeItem('@stricv2:account');
		//         // window.location.href = `/create-account/?type=${type}`;
		//         return;
		//       }

		//       if (data.status === 'WAITING_VALIDATION') {
		//         window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         localStorage.removeItem('@stricv2:token');
		//         localStorage.removeItem('@stricv2:account');
		//         sessionStorage.removeItem('@stricv2:token');
		//         sessionStorage.removeItem('@stricv2:account');
		//         // window.location.href = `/create-account/?type=${type}`;
		//         return;
		//       }
		//   }

		//   if (type === 'pf') {
		//     if (data.status === 'WAITING_TOKEN') {
		//       window.localStorage.setItem('@stricv2:status', 'WAITING_TOKEN');
		//       window.localStorage.setItem('@stricv2:id', token);
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       // window.location.href = `/create-account/?type=${type}`;
		//       return;
		//     }

		//     if (data.status === 'WAITING_FLOW') {
		//       window.localStorage.setItem('@stricv2:status', 'WAITING_FLOW');
		//       window.localStorage.setItem('@stricv2:id', token);
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       // window.location.href = `/create-account/?type=${type}`;
		//       return;
		//     }

		//     if (data.status === 'WAITING_PARTNERS') {
		//       window.localStorage.setItem('@stricv2:status', 'WAITING_PARTNERS');
		//       window.localStorage.setItem('@stricv2:id', token);
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       // window.location.href = `/create-account/?type=${type}`;
		//       return;
		//     }

		//     if (data.status === 'WAITING_ADDRESS') {
		//       window.localStorage.setItem('@stricv2:status', 'WAITING_ADDRESS');
		//       window.localStorage.setItem('@stricv2:id', token);
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       // window.location.href = `/create-account/?type=${type}`;
		//       return;
		//     }

		//     if (data.status === 'WAITING_DOCUMENTS') {
		//       window.localStorage.setItem('@stricv2:status', 'WAITING_DOCUMENTS');
		//       window.localStorage.setItem('@stricv2:id', token);
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       sessionStorage.removeItem('@stricv2:token');
		//       sessionStorage.removeItem('@stricv2:account');
		//       // window.location.href = `/create-account/?type=${type}`;
		//       return;
		//     }

		//     if (data.status === 'WAITING_VALIDATION') {
		//       window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
		//       window.localStorage.setItem('@stricv2:id', token);
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       sessionStorage.removeItem('@stricv2:token');
		//       sessionStorage.removeItem('@stricv2:account');
		//       // window.location.href = `/create-account/?type=${type}`;
		//       return;
		//     }

		//     if (data.status === 'WAITING_VALIDATION') {
		//       window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       localStorage.removeItem('@stricv2:token');
		//       localStorage.removeItem('@stricv2:account');
		//       sessionStorage.removeItem('@stricv2:token');
		//       sessionStorage.removeItem('@stricv2:account');
		//       // window.location.href = `/create-account/?type=${type}`;
		//       return;
		//     }
		// }

		// if (data.status !== "active" && user?.type !== "company") {
		//  alert("Você ainda não está ativo, mas não se preocupe. Volte mais tarde e continuaremos juntos!");

		// Configura informações no armazenamento local
		//  window.localStorage.setItem('@stricv2:status', 'waiting_documents');
		// window.localStorage.setItem('@stricv2:id', token);
		//
		// Remove informações de autenticação do armazenamento local e de sessão
		// localStorage.removeItem('@stricv2:token');
		// localStorage.removeItem('@stricv2:account');
		// sessionStorage.removeItem('@stricv2:token');
		// sessionStorage.removeItem('@stricv2:account');

		// Redireciona o usuário para a página de login (ou outra página)
		//  window.location.href = '/signin'; // Substitua '/login' pela URL desejada

		//// return;
		// }

		return true;
	}

	let isFetchingAccount = false;

	async function getAccount() {
		const token = window.localStorage.getItem("@stricv2:token");
		const account = window.localStorage.getItem("@stricv2:account");
		const user = window.localStorage.getItem("@stricv2:user");

		if (token && account && user) {
			try {
				api.defaults.headers = {
					Authorization: `Bearer ${token}`,
				} as CommonHeaderProperties;

				const { data } = await api.get("/me");

				await checkStep(data.account, data.user.access_token);
				localStorage.setItem("@stricv2:token", data.user.access_token);

				setData({
					token: data.user.access_token,
					account: data.account,
					user: data.user,
				});
			} catch (err) {
				console.error(err);
				signOut();
			}
		}
	}

	async function getBalance() {
		const token = window.localStorage.getItem("@stricv2:token");
		const account = window.localStorage.getItem("@stricv2:account");
		const user = window.localStorage.getItem("@stricv2:user");

		if (token && account && user) {
			try {
				api.defaults.headers = {
					Authorization: `Bearer ${token}`,
				} as CommonHeaderProperties;

				const { data } = await api.get("/account/balance");
				setBalance(data);
			} catch (err) {
				console.error(err);
			}
		}
	}

	function refresh() {
		setTimeout(() => {
			console.log("refresh.1");
			getAccount();
			getBalance();
		}, 2000);

		setTimeout(() => {
			console.log("refresh.2");
			getAccount();
			getBalance();
		}, 60000);
	}

	function refreshAccount() {
		getAccount();
	}

	useEffect(() => {
		const interceptor = api.interceptors.request.use(
			async function (config) {
				if (!isFetchingAccount) {
					isFetchingAccount = true;
					await getAccount();
					await getBalance();
					isFetchingAccount = false;
				}
				return config;
			},
			function (error) {
				return Promise.reject(error);
			},
		);

		return () => {
			api.interceptors.request.eject(interceptor);
		};
	}, []);

	useEffect(() => {
		// console.log('refresh.effect');
		getAccount();
		getBalance();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const signIn = useCallback(
		async ({ document, password, user_master_token, individuals_token }) => {
			window.localStorage.removeItem("@stricv2:status");
			window.localStorage.removeItem("@stricv2:id");

			let response = null;

			if (individuals_token) {
				response = await api.post(
					"/sessions/individuals",
					{},
					{
						headers: {
							Authorization: `Bearer ${individuals_token}`,
						},
					},
				);
			} else if (user_master_token) {
				response = await api.post(
					"/sessions/wallet",
					{},
					{
						headers: {
							Authorization: `Bearer ${user_master_token}`,
						},
					},
				);
			} else {
				response = await api.post(
					"/sessions",
					{
						document,
						password,
					},
					{
						headers: {
							client: client.client_id, // "d2afc12c-bc27-440c-96c2-88e006c92ab2"
						},
					},
				);
			}

			if (!response || !response.data) {
				console.error("Resposta inválida:", response);
				// Lide com o erro de resposta inválida aqui, se necessário.
				return;
			}

			console.log("response do login to aqui", response);
			const { token, user, account } = response.data;
			console.log("response.data do login to aqui", response.data);

			console.log("Configurando headers para API com token:", token);
			api.defaults.headers = {
				Authorization: `Bearer ${token}`,
			} as CommonHeaderProperties;

			console.log("Definindo token, conta e usuário no localStorage");
			localStorage.setItem("@stricv2:token", token);
			localStorage.setItem("@stricv2:account", JSON.stringify(account));
			localStorage.setItem("@stricv2:user", JSON.stringify(user));
			console.log("Definindo token, conta e usuário no localStorage", token);
			console.log(
				"Definindo token, conta e usuário no localStorage",
				JSON.stringify(account),
			);
			console.log(
				"Definindo token, conta e usuário no localStorage",
				JSON.stringify(user),
			);

			console.log("Configurando headers para API novamente com token:", token);
			api.defaults.headers = {
				Authorization: `Bearer ${token}`,
				// account: data.id,
			} as CommonHeaderProperties;

			console.log("Executando checkStep com conta e token");
			await checkStep(account, token);

			console.log("Definindo dados no estado");
			setData({ token, account, user });
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[],
	);

	const signInWithToken = useCallback(async (token) => {
		window.localStorage.removeItem("@stricv2:status");
		window.localStorage.removeItem("@stricv2:id");

		console.log("Configurando headers para API com token:", token);
		api.defaults.headers = {
			Authorization: `Bearer ${token}`,
		} as CommonHeaderProperties;

		console.log("Realizando requisição para /users");
		const { data: user } = await api.get("/users");

		console.log("Realizando requisição para /accounts");
		const { data: responseApiAccount } = await api.get("/accounts");

		console.log("Definindo token e conta no localStorage");
		localStorage.setItem("@stricv2:token", token);
		localStorage.setItem("@stricv2:account", JSON.stringify(data));

		console.log("Executando checkStep com usuário e token");
		await checkStep(user, token);

		console.log("Definindo dados no estado");
		setData({ token, account: responseApiAccount, user });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const signOut = useCallback(async () => {
		// const token = localStorage.getItem("@stricv2:token");

		// api.defaults.headers = {
		//   Authorization: `Bearer ${token}`,
		// } as CommonHeaderProperties;

		// await api.get("/logout");

		localStorage.removeItem("@stricv2:token");
		localStorage.removeItem("@stricv2:account");
		localStorage.removeItem("@stricv2:user");

		setData({} as AuthState);
	}, []);

	let lastActivityTime = 0;
	let inactivityTimer: string | number | NodeJS.Timeout | undefined;

	const env = process.env.NODE_ENV;

	const { setOpenNotification } = useNotification();

	const handleMouseActivity = useCallback(() => {
		lastActivityTime = new Date().getTime();
		clearTimeout(inactivityTimer);
		inactivityTimer = setTimeout(
			() => {
				if (env == "production") {
					signOut();
				}
			},
			2 * 60 * 1000,
		);
	}, []);

	useEffect(() => {
		window.addEventListener("mousemove", handleMouseActivity);

		return () => {
			clearTimeout(inactivityTimer);
			window.removeEventListener("mousemove", handleMouseActivity);
		};
	}, [handleMouseActivity]);

	return (
		<AuthContext.Provider
			value={{
				checkStep: checkStep,
				account: data.account,
				signIn,
				signInWithToken,
				signOut,
				refresh,
				reportBalance: balance,
				refreshAccount,
				user: data.user,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth(): AuthContextData {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
}
