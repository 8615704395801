import type { ButtonHTMLAttributes } from "react";

interface LinkOptionProps extends ButtonHTMLAttributes<HTMLButtonElement>{
  title: string;
  icon: string;
}

export function LinkOption({title, icon, ...props}: LinkOptionProps) {

  return(
      <button {...props} type="button" className="w-[343px] h-[139px] drop-shadow-md rounded-[20px] bg-white relative flex items-center justify-start px-6 overflow-hidden hover:brightness-90 transition " >
        <span className="font-bold text-[2.1875rem] leading-10 text-[#433f3f] w-[130px] text-left">
          {title}
        </span>
        <img src={icon} alt={title} className="absolute bottom-0 right-0" />
      </button>
  )
}
