export const dateMask = (value: string) => {
	if (value !== undefined) {
		return value
			.replace(/[\D]/g, "")
			.replace(/(\d{4})(\d{2})/, "$1/$2")
			.replace(/(\d{2})(\d{2})/, "$1/$2");
	}

	return "";
};

export const currencyMask = (value: string) => {
	if (value !== undefined) {
		const numericValue = value.replace(/[\D]/g, ""); // Remove tudo que não é número
		const formattedValue = (Number(numericValue) / 100).toFixed(2); // Divide por 100 e fixa duas casas decimais
		return formattedValue.replace(".", ","); // Substitui o ponto por vírgula
	}

	return "0,00"; // Retorna um valor padrão caso seja undefined
};

export const phoneMask = (value: string) => {
	if (value !== undefined) {
		return value
			.replace(/[\D]/g, "")
			.replace(/(\d{2})(\d{5})/, "($1) $2")
			.replace(/(\d{5})(\d{4})/, "$1-$2");
	}

	return "";
};

export const removePhoneMask = (value: string) => {
	return value.replace(/\D/g, "");
};
