import { useState } from "react";
import newPixIcon from "../../../../assets/newPixIcon.svg";
import { LinkOption } from "../../../../components/LinkOptions";
import {
	ModalClose,
	ModalContainer,
	ModalContent,
	ModalRoot,
} from "src/components/ui/modal";
import { NewPixForm } from "./NewPixForm";

export function NewPix() {
	const [isModalOpen, setIsModalOpen] = useState(false);

	function handleOpenModal() {
		setIsModalOpen((prev) => !prev);
	}

	return (
		<div>
			<LinkOption
				title="Novo pix"
				icon={newPixIcon}
				onClick={handleOpenModal}
			/>
			<ModalRoot open={isModalOpen} setIsOpen={setIsModalOpen}>
				<ModalContainer>
					<ModalContent>
						<ModalClose />
						<NewPixForm />
					</ModalContent>
				</ModalContainer>
			</ModalRoot>
		</div>
	);
}
