import { Orders } from "src/components/orders";
import React from "react";
import { TransactionList, Container } from "./LastTransactions.styles";
import { Link } from "react-router-dom";

export const LastTransactions: React.FC = () => {
	return (
		<Container>
			<h3 className="font-bold 1xl:mt-3 xl:text-xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl 6xl:mt-5">
				Últimos lançamentos
			</h3>
			<TransactionList>
				<Orders />
			</TransactionList>
			<div className="flex justify-end w-full relative -top-8">
				<Link
					to={"/extract"}
					className="underline text-[#8979FF] 1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl"
				>
					Ver Extrato
				</Link>
			</div>
		</Container>
	);
};
