import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ExtractItem } from "src/utils/extractDescription";
import {
  InternalResponse,
  InternalTaxResponse,
  PagbankPaymentResponse,
} from "../ProofResponses";
import transactionLabel from "src/utils/transactionLabel";
import {
  ButtonWrapper,
  CloseIcon,
  DownloadIcon,
  InfoModal,
  ModalContent,
  ModalHeader,
  Section,
  SectionAccountinfo,
  SectionCPNJ,
  SectionItem,
  SectionPayerName,
  SectionTitle,
} from "../../ModalConfirmOperation/styles";
import toLocaleAmount from "src/utils/toLocaleAmount";
import dateToLine from "src/utils/dateToLine";
import { documentFormatted } from "src/utils/documentFormatted";
import { Button } from "../../button";
import { config } from "src/config";
import { DefaultLabel } from "src/pages/receipt/PDF";

export const PagbankPayment: FC<{
  data: ExtractItem;
  closeModal: () => void;
}> = ({ data, closeModal }) => {
  const txType = data.type.toLowerCase();

  console.log(data);

  const naviagate = useNavigate();

  if (!txType.includes("slip") && !txType.includes("withdraw")) {
    naviagate("/extract");
  }

  const response = data.data as PagbankPaymentResponse;

  const label = transactionLabel(data);

  console.log("a");

  return (
    <div id="content" className="w-[720px] flex flex-col h-screen">
      <div className="w-full h-16 flex px-10 items-center bg-blue-500">
        <span className="text-white text-xl font-bold">{label}</span>
      </div>
      <div className="flex w-full justify-between p-10">
        <div>
          <span className="text-zinc-400 text-2xl font-bold">VILAPAY BANK</span>
          <p className="text-blue-500 text-5xl font-bold">
            R$ {toLocaleAmount(data.amount)}
          </p>
        </div>
        <span className="font-bold text-lg">{dateToLine(data.created_at)}</span>
      </div>
      <div className="flex flex-col px-10 py-4 gap-1">
        <span className="text-zinc-500 font-semibold mb-2 text-3xl">
          Pagador
        </span>

        <DefaultLabel title="NOME" value={response.payer.name} />
        <DefaultLabel
          title={response.payer.document.length > 11 ? "CNPJ" : "CPF"}
          value={documentFormatted(response.payer.document)}
        />
        <DefaultLabel title="INSTITUIÇÃO" value={"VILAPAY BANK"} />
      </div>
      <div className="flex flex-col px-10 py-4 gap-1">
        <span className="text-zinc-500 font-semibold mb-2 text-3xl">
          Favorecido
        </span>
        <DefaultLabel title="NOME" value={response.beneficiary.name} />
        <DefaultLabel
          title={response.beneficiary.document.length > 11 ? "CNPJ" : "CPF"}
          value={documentFormatted(response.beneficiary.document)}
        />
        <DefaultLabel title="INSTITUIÇÃO" value={"VILAPAY BANK"} />
      </div>
    </div>
    //   <div className="flex flex-1">
    //     <div>
    //       <span>{label}</span>
    //       <button onClick={closeModal}>
    //         <CloseIcon />
    //       </button>
    //     </div>
    //     <div>
    //       <span>{response.beneficiary.name}</span>
    //       <p>R$ {toLocaleAmount(data.amount)}</p>
    //     </div>
    //     <span>{dateToLine(data.created_at)}</span>
    //     <SectionTitle>Pagador</SectionTitle>
    //     <span>NOME</span>
    //     <p>{response.payer.name}</p>
    //     <span>{response.payer.document.length > 11 ? "CNPJ" : "CPF"}</span>
    //     <p>{documentFormatted(response.payer.document)}</p>
    //     <span>INSTITUIÇÃO</span>
    //     <p>{response.payer.name}</p>

    //     <SectionTitle>Favorecido</SectionTitle>
    //     <span>NOME</span>
    //     <p>{response.beneficiary.name}</p>
    //     <span>{response.beneficiary.document.length > 11 ? "CNPJ" : "CPF"}</span>
    //     <p>
    //       {/* @ts-ignore */}
    //       {documentFormatted(response.beneficiary.document)}
    //     </p>
    //     <span>INSTITUIÇÃO</span>
    //     <p>{response.beneficiary.name}</p>
    //   </div>
  );
};
