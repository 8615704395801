import { createContext, useContext, useState, type ReactNode } from "react";

export interface FormDataProps {
	amount?: string;
	hash?: string;
	image?: string;
}

interface MultiStepFormContextProps {
	formStaticStep: number;
	formStaticData: FormDataProps;
	setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>;
	nextStep: () => void;
	prevStep: () => void;
}

const QrCodeStaticFormContext = createContext<
	MultiStepFormContextProps | undefined
>(undefined);

export function useQrCodeStaticStepForm() {
	const context = useContext(QrCodeStaticFormContext);
	if (!context) {
		throw new Error(
			"useMultiStepForm must be used within a MultiStepFormProvider",
		);
	}
	return context;
}

export const QrCodeStaticFormProvider = ({
	children,
}: { children: ReactNode }) => {
	const [formStaticStep, setFormStaticStep] = useState(1);
	const [formStaticData, setFormData] = useState<FormDataProps>({});

	const nextStep = () => setFormStaticStep((prev) => prev + 1);
	const prevStep = () => setFormStaticStep((prev) => prev - 1);

	return (
		<QrCodeStaticFormContext.Provider
			value={{
				formStaticStep,
				formStaticData,
				setFormData,
				nextStep,
				prevStep,
			}}
		>
			{children}
		</QrCodeStaticFormContext.Provider>
	);
};
