import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import qrcode from "src/assets/qr-code-image.png";
import { showAlertError } from "src/components/alertError";
import { ModalLoader } from "src/components/modalLoader";
import { useQrCodeStaticStepForm } from "src/context/qrcodeStaticContext";
import { useAuth } from "src/hooks/auth";
import {
	valueStepStaticSchema,
	type ValueStepDynamic,
} from "src/interface/qrCodeSchema";
import { api } from "src/services/api";
import { isAxiosError } from "src/utils/isAxiosError";
import { currencyMask } from "src/utils/maskInput";
import { setAuthToken } from "src/utils/setAuthToken";

export const SelectValueStatic = () => {
	const { nextStep, setFormData } = useQrCodeStaticStepForm();
	const { account } = useAuth();
	const [isSubmiting, setIsSubmiting] = useState(false);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<ValueStepDynamic>({
		resolver: zodResolver(valueStepStaticSchema),
	});

	const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue("value", currencyMask(event.target.value));
	};

	const onSubmit = async (data: ValueStepDynamic) => {
		setIsSubmiting(true);
		const splitAmount = data.value.split(",");
		const amount = Number(splitAmount[0] + "." + splitAmount[1]) * 100;

		try {
			setAuthToken();
			const response = await api.post("/pix/generate/static", {
				amount,
				description: "Pix Estatico",
				options: {
					show_qrcode_image: true,
				},
				virtual_account_id: account.virtual_account_id,
			});
			setFormData({
				hash: response.data.data.hash,
				amount: data.value,
				image: response.data.data.image,
			});
			nextStep();
		} catch (error) {
			if (isAxiosError(error) && error.response) {
				const errorMessage = (error.response.data as { code: string }).code;
				showAlertError(errorMessage);
			} else {
				showAlertError("Ocorreu um erro inesperado.");
			}
		} finally {
			setIsSubmiting(false);
		}
	};

	if (isSubmiting) {
		return <ModalLoader />;
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex flex-col items-center gap-5 px-4"
		>
			<img src={qrcode} alt="qrcode" />
			<h2 className="text-[30px] font-bold">
				Informe o valor que deseja cobrar
			</h2>
			<input
				className="border border-[#163172] rounded-md w-[70%] text-[#404040] py-2 px-4 bg-white"
				placeholder="R$0,00"
				{...register("value")}
				onChange={handleChangeAmount}
			/>
			{errors.value && (
				<p className="text-red-500 text-[15px]">{errors.value.message}</p>
			)}
			<button
				type="submit"
				className="bg-[#004A8A] rounded-md text-[15px] w-[70%] font-bold text-white py-2 px-4"
			>
				Gerar a QR code para cobrança
			</button>
		</form>
	);
};
