import { FC } from "react";
import { GradientButton } from "../newCreateAccountWallet/components";
import { useNavigate } from "react-router-dom";

import VilapayLogoGraySVG from "../../assets/logoVilapayInvest.png";
import GooglePlayAppButton from "../../assets/android.png";
import AppleAppButton from "../../assets/ios.png";

import "./styles.css";

export const NewCreateAccountType: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-[86vw] h-[90vh] flex items-center gap-60 upgrade-background max-2xl:gap-0">
      <div className="flex flex-col items-start justify-start h-[85%] ml-20 max-2xl:ml-8">
        <img src={VilapayLogoGraySVG} className="mb-20 w-[60%] max-2xl:mb-10" alt="" />

        <h1 className="font-extrabold text-white text-5xl max-2xl:text-3xl">
          Upgrade a <strong className="font-extrabold text-[#AA7339] text-5xl max-2xl:text-3xl">um</strong> <br />
          <strong className="font-extrabold text-[#AA7339] text-5xl max-2xl:text-3xl">click</strong> de distância
        </h1>

        <h2 className="font-extralight text-white text-3xl mt-7 mb-8 max-2xl:text-xl max-2xl:mb-2">
          Faça aqui seu upgrade e tenha <br />
          um conta corrente completa.
        </h2>

        <div className="flex items-center my-5">
          <button>
            <img className="w-[80%] h-[80%] max-2xl:w-[50%] max-2xl:h-[50%]" src={GooglePlayAppButton} alt="" />
          </button>
          <button>
            <img className="w-[80%] h-[80%] max-2xl:w-[50%] max-2xl:h-[50%]" src={AppleAppButton} alt="" />
          </button>
        </div>

        <p className="font-extralight text-white text-base mt-[40%] max-2xl:mt-[10%] max-2xl:text-xs">
          Copyright © 2024 - Vilapay - Todos os direitos reservados
        </p>
      </div>
      <div>
        <svg style={{ position: "relative" }} width="550" height="400">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#AA7339" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="540"
            height="390"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>
        <div className="absolute top-[40vh] ml-20 max-2xl:top-[28vh]">
          <h2 className="text-white font-extralight text-3xl mb-2">
            Ops... <br /> Estamos criando o nosso <br /> sistema de Upgrade
          </h2>
          {/* <h3 className="text-white font-extralight text-xl mb-7">Escolha uma opção:</h3> */}

          {/* <div className="flex flex-col gap-8">
            {/* <GradientButton
              tailwindStyles="bg-[#AA7339] w-[25rem] h-16 text-white text-lg"
              title="Conta Física"
              onClick={() => navigate('/confirm-upgrade', { state: { accountType: 'PF' } })}
            />
            <GradientButton
              tailwindStyles="bg-[#171717] w-[25rem] h-16 text-white text-lg"
              title="Conta Business"
              onClick={() => navigate('/confirm-upgrade', { state: { accountType: 'PJ' } })}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
