import { PixAreaContainer } from "./PixArea.styles";

import CopyIcon from "../../../assets/copyWhiteIcon.svg";
import DigitalMoneyIcon from "../../../assets/depositMoneyWhiteIcon.svg";
import digitalMoney from "../../../assets/digitalMoneyWhiteIcon.svg";
import MoneyTransfer from "../../../assets/moneyTransfer.svg";
import PixGreenIcon from "../../../assets/pixGreenIcon.svg";
import StarIcon from "../../../assets/starWhiteIcon.svg";

export function PixArea() {
	return (
		<PixAreaContainer className="p-4">
			<div className="flex items-center gap-4 4xl:mt-5">
				<img
					src={PixGreenIcon}
					alt=""
					className="1xl:w-[5rem] 1xl:h-[3rem] 2xl:w-[120px] 2xl:h-[75px] 3xl:w-[120px] 3xl:h-[60px] 4xl:w-[180px] 4xl:h-[160px] 5xl:w-[200px] 5xl:h-[180px]"
				/>
				<h5 className="font-bold text-2x4 leading-7 1xl:text-3xl 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl">
					Área Pix
				</h5>
				<p className="text-[#718EBF] 1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
					Envie e receba pagamentos!
				</p>
			</div>
			<div className="flex flex-1 items-center justify-center gap-5">
				<button
					type="button"
					className="transition hover:brightness-50 flex flex-col items-center gap-[10px] "
				>
					<div className="flex items-center justify-center p-4 w-[73px] h-[73px] 1xl:w-[100px] 1xl:h-[100px] 2xl:w-[120px] 2xl:h-[120px] 3xl:w-[136px] 3xl:h-[136px] 4xl:w-[200px] 4xl:h-[200px] rounded-full bg-[#1e1e1e]">
						<img
							src={MoneyTransfer}
							alt=""
							className="1xl:w-[100px] 1xl:h-[55px] 2xl:w-[100px] 2xl:h-[55px] 3xl:w-[120px] 3xl:h-[60px] 4xl:w-[100px] 4xl:h-[100px]"
						/>
					</div>
					<span className="text-[#718EBF] text-[12px] 1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
						Transferir
					</span>
				</button>

				<button
					type="button"
					className="transition hover:brightness-50 flex flex-col items-center gap-[10px] "
				>
					<div className="flex items-center justify-center p-4 w-[73px] h-[73px] 1xl:w-[100px] 1xl:h-[100px] 2xl:w-[120px] 2xl:h-[120px] 3xl:w-[136px] 3xl:h-[136px] 4xl:w-[200px] 4xl:h-[200px] rounded-full bg-[#1e1e1e]">
						<img
							src={StarIcon}
							alt=""
							className="1xl:w-[100px] 1xl:h-[55px] 2xl:w-[100px] 2xl:h-[55px] 3xl:w-[120px] 3xl:h-[60px] 4xl:w-[100px] 4xl:h-[100px]"
						/>
					</div>
					<span className="text-[#718EBF] text-[12px] 1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
						Favoritos
					</span>
				</button>

				<button
					type="button"
					className="transition hover:brightness-50 flex flex-col items-center gap-[10px] "
				>
					<div className="flex items-center justify-center p-4 w-[73px] h-[73px] 1xl:w-[100px] 1xl:h-[100px] 2xl:w-[120px] 2xl:h-[120px] 3xl:w-[136px] 3xl:h-[136px] 4xl:w-[200px] 4xl:h-[200px] rounded-full bg-[#1e1e1e]">
						<img
							src={CopyIcon}
							alt=""
							className="1xl:w-[100px] 1xl:h-[55px] 2xl:w-[100px] 2xl:h-[55px] 3xl:w-[120px] 3xl:h-[60px] 4xl:w-[100px] 4xl:h-[100px]"
						/>
					</div>
					<span className="text-[#718EBF] text-[12px] 1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
						Pix Copia e Cola
					</span>
				</button>

				<button
					type="button"
					className="transition hover:brightness-50 flex flex-col items-center gap-[10px] "
				>
					<div className="flex items-center justify-center p-4 w-[73px] h-[73px] 1xl:w-[100px] 1xl:h-[100px] 2xl:w-[120px] 2xl:h-[120px] 3xl:w-[136px] 3xl:h-[136px] 4xl:w-[200px] 4xl:h-[200px] rounded-full bg-[#1e1e1e]">
						<img
							src={digitalMoney}
							alt=""
							className="1xl:w-[100px] 1xl:h-[55px] 2xl:w-[100px] 2xl:h-[55px] 3xl:w-[120px] 3xl:h-[60px] 4xl:w-[100px] 4xl:h-[100px]"
						/>
					</div>
					<span className="text-[#718EBF] text-[12px] 1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
						Cobrar
					</span>
				</button>

				<button
					type="button"
					className="transition hover:brightness-50 flex flex-col items-center gap-[10px] "
				>
					<div className="flex items-center justify-center p-4 w-[73px] h-[73px] 1xl:w-[100px] 1xl:h-[100px] 2xl:w-[120px] 2xl:h-[120px] 3xl:w-[136px] 3xl:h-[136px] 4xl:w-[200px] 4xl:h-[200px] rounded-full bg-[#1e1e1e]">
						<img
							src={DigitalMoneyIcon}
							alt=""
							className="1xl:w-[100px] 1xl:h-[55px] 2xl:w-[100px] 2xl:h-[55px] 3xl:w-[120px] 3xl:h-[60px] 4xl:w-[100px] 4xl:h-[100px]"
						/>
					</div>
					<span className="text-[#718EBF] text-[12px] 1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
						Depositar
					</span>
				</button>
			</div>
		</PixAreaContainer>
	);
}
