import styled, { keyframes } from "styled-components";
import { ReactComponent as BillIconSVG } from "../../../assets/billSide.svg";
import { ReactComponent as TrashIconSVG } from "../../../assets/trash.svg";
import { ReactComponent as arrowRightDepositIconSVG } from "../../../assets/arrowRightDeposit.svg";
import ButtonSignInColor from "../../../assets/button-color.svg";

import DatePicker from 'react-datepicker';

import {ReactComponent as PixIconSVG} from '../../../assets/pix.svg'
import {ReactComponent as LockIconSVG} from '../../../assets/lock.svg'
import {ReactComponent as MailIconSVG} from '../../../assets/mail.svg'

interface props {
  color?: string;
}

export const ArrowRightDepositIcon = styled(arrowRightDepositIconSVG)``;

export const BillIcon = styled(BillIconSVG)`
  fill: var(--cian);
  width: 16px;
`;

export const TrashIcon = styled(TrashIconSVG)`
  fill: var(--cian);
  width: 16px;
`;

export const Container = styled.div`
  height: 100vh;
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
`;

export const ContentWrapper = styled.div`
  padding: 32px;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Search = styled.div`
  max-width: 592px;

  > span {
    font-size: 16px;
    font-family: var(--regular);
    color: var(--gray);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 32px 0 0 0;
 
  @media (min-width: 720px) {
    width: 242px;
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 37px;
  flex: 1;
  border: 1px solid var(--gray-light-order);
  border-radius: 8px;
  padding: 14px 16px;

  > input {
    background: none;
    border-style: none;
    width: 100%;
    outline: 0;
  }
`;

export const Table = styled.div`
  flex: 1;
  margin-top: 32px;
`;

export const TableWrapper = styled.div`
  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px) {
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px) {
    width: 100%;
  }

  > table {
    width: 100%;

    > tbody {
      border-radius: 8px;
      border: none;
      border-collapse: collapse;

      > tr {
        position: relative;

        &:first-child {
          &::after {
            content: "";
            height: 16px;
            width: 4px;
            background-color: var(--cian);
            position: absolute;
            bottom: 0;
            top: 0;
            margin-top: auto;
            margin-bottom: auto;
            left: 0;
          }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
          text-align: start;
          font-family: var(--semiBold);
          font-size: 14px;
          color: var(--gray);
          padding: 16px;

          @media (min-width: 720px) {
            font-size: 16px;
            padding: 21px 32px;
          }
        }

        > td {
          padding: 6px 16px;
          font-family: var(--regular);
          font-size: 10px;
          color: var(--gray-light-text);
          cursor: pointer;
          border-top: 1px solid var(--gray-light-line);

          @media (min-width: 720px) {
            font-size: 14px;
            padding: 12px 32px;
          }
        }
      }
    }
  }
`;

export const ButtonWrapperTable = styled.button`
  border-style: none;
  height: 32px;
  width: 32px;
  background-color: var(--gray-light-05);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const RedirectWrapperButton = styled.div`
  /* margin-left: 72px; */
  border-style: none;
  background-color: none;
  display: flex;
  align-items: center;

  > span {
    color: var(--cian);
    text-decoration: underline;
    margin-right: 18px;
    font-size: 16px;
    font-family: var(--regular);
  }

  > svg {
    color: var(--cian);

  }
`;

export const ButtonsTableWrapper = styled.div`
  display: flex;
`;

export const ButtonForm = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${ButtonSignInColor});
  background-repeat: no-repeat;
  background-size: cover;  text-decoration: none;

  font-family: var(--multi-bold);
  color: var(--white);
  font-size: 16px;
  font-weight: var(--extraBold);

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
  }
`;

//------------------------------------------------------------------------


export const PixIcon = styled(PixIconSVG)`
fill: var(--cian);
`

export const LockIcon = styled(LockIconSVG)`
fill: var(--cian);
`

export const MailIcon = styled(MailIconSVG)`
fill: var(--cian);
`

export const ContainerTwo = styled.div`

height: calc(100vh - 80px);
background-color: var(--background);


`

export const ContentTwo = styled.main`

grid-area: content;
display: flex;
flex-direction: column;


`

export const QrInput = styled.div`
  //border: 1px solid blue;
  display: flex;
  flex-direction: column;
`;

export const QrContainerHidden = styled.div`

gap: 5px;
display: flex;
flax-direction: column;
justify-content: center;
align-items: center;
background: white;
height: 5vh;
`

export const QrContainerStyled = styled.div`

gap: 5px;
display: flex;
flax-direction: column;
justify-content: center;
align-items: center;
background: white;
cursor: pointer;
`

export const TableTwo = styled.div`

flex: 1;
padding: 32px;
//border: 1px solid black;
background: #b6b6b6;
height: 100vh;
`


export const TableWrapperTwo = styled.div`
  border: 3px solid white;
  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px){
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px){
      width: 100%;
  }


> table {

    width: 100%;
    border-radius: 8px;
    border: none;
    border-collapse: collapse;

    tr {
        position: relative;

        &:first-child {

            &::after {
                content: '';
                height: 16px;
                width: 4px;
                background-color: var(--cian);
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
            }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
            text-align: start;
            font-family: var(--semiBold);
            font-size: 14px;
            color: var(--gray);
            padding: 16px;


            @media (min-width: 720px){
                font-size: 16px;
                padding: 21px 32px;
            }
        }

        > td {
            padding: 6px 16px;
            font-family: var(--regular);
            font-size: 10px;
            color: var(--gray-light-text);

            &:last-child {
                color: var(--red);
            }

                @media (min-width: 720px){
                    font-size: 14px;
                    padding: 12px 32px;

                }

            }
          }
        }
        display: flex;

`
export const LeftDiv = styled.div`
width: 35%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #00cbda;

input {
  margin: 8px 0;
}

select {
  margin: 8px 0;
}
`;


export const RightDiv = styled.div`
  flex: 1;
 // border: 1px solid red;
  gap: "5px";
display: flex;
flax-direction: column;
justify-content: center;
align-items: center;
border-radius: 6px;
background: white;
`;

export const DivDate = styled.div`
  height: 38px;
  text-align: center;
  color: black;
  //border: 1px solid #000;
 // border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -200px;

`;

export const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #b6b6b6;
  outline: none;
  border-radius: 8px;
  min-width: 300px !important;
  height: 38px;
  padding-bottom: 2px;
  text-align: center;
  color: black;
  align-items: center;
  margin-left: -200px;
`;



export const ContainerButton = styled.button<props>`

cursor: pointer;
width: 100%;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${ButtonSignInColor});
  background-repeat: no-repeat;
  background-size: cover;  text-decoration: none;
  border: 2px solid var(--cian);
  font-family: var(--multi-bold);
  color: var(--white);
  font-size: 16px;
  font-weight: var(--extraBold);
  gap: 8px;

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
}

`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const QrCodeLoader = styled.div`

  display:flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;

  svg{
    animation: ${rotate} 1s linear infinite;
  }

`