// Arquivo: LastTransactions.styles.ts

import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
 left: -1%;
transition: all 0.3s ease;
position: relative;
top: -6.8%; /* Mova para cima */ 
min-width: 100%;
display: flex;
flex-direction: column;
gap: 0.5rem;
min-height: 100%; /* Garante que o filho não ultrapasse o limite do contêiner */

/* Media Queries para dispositivos Apple */
@media (max-width: 3024px) {
    /* Para MacBook Pro 14" e resoluções menores */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2240px) {
    /* Para MacBook Air e Pro 13" */
    width: 50%;
    height: 50%;
    gap: 0.27rem;
    margin: 1%;
  }

  @media (max-width: 2560px) {
    /* Para MacBook Air e Pro 13" */
    width: 50%;
    height: 50%;
    gap: 0.3rem;
    margin: 1%;
  }

  @media (max-width: 2304px) {
    /* Para iMac 21.5" (4K Retina) */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2234px) {
    /* Para MacBook Pro 16" (M1) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2880px) {
    /* Para iMac 27" (5K Retina) */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 2520px) {
    /* Para iMac 24" (M1) */
    width: 47%;
    height: 47%;
    margin: 1.5%;
  }

  @media (max-width: 3384px) {
    /* Para Pro Display XDR (32") */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  /* Media Queries para dispositivos Windows */
  @media (max-width: 1366px) {
    /* Para laptops básicos (HD) */
    width: 100%; /* Divs embaixo uma da outra */
    height: 48%;
  }

  @media (max-width: 1920px) {
    /* Para monitores padrão (Full HD) */
    width: 48%;
    height: 48%;
    left: -1%;
  position: relative;
  top: -5.2%; /* Mova para cima */ 
    margin: 1%;
  }

  @media (max-width: 2560px) {
    /* Para monitores avançados (QHD/2K) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3200px) {
    /* Para laptops premium (QHD+) */
    width: 45%;
    height: 48%;
    margin: 1.5%;
  }

  @media (max-width: 3840px) {
    /* Para monitores 4K */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3440px) {
    /* Para monitores Ultrawide */
    width: 48%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 5120px) {
    /* Para monitores 5K */
    width: 46%;
    height: 48%;
    gap: 0;
  }

  @media (max-width: 7680px) {
    /* Para monitores 8K (futuro) */
    width: 45%;
    height: 50%;
    margin: 1%;
  }
`;

export const TransactionList = styled.div`
  left: -1%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #B1B1B1;
  background-color: #FAFAFA;
  border-radius: 25px;
  transition: all 0.3s ease;

  position: relative;
  top: -7.8%; /* Mova para cima */ 
  min-width: 100%;
  margin: 3.3% 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  //max-height: 100%; /* Garante que o filho não ultrapasse o limite do contêiner */
  min-height: 98%; /* Garante que o filho não ultrapasse o limite do contêiner */
 // overflow: hidden; /* Esconde qualquer conteúdo extra */

  @media (max-width: 1366px) {
    left: -1%;
  position: relative;
  top: -8.5%; /* Mova para cima */ 
  }

  @media (max-width: 1920px) {
    left: -1%;
  position: relative;
  top: -9.5%; /* Mova para cima */ 
  }

  @media (max-width: 3840px) {
    left: -1%;
  position: relative;
  top: -4.7%; /* Mova para cima */ 
  }

  @media (max-width: 5120px) {
    left: -1%;
  position: relative;
  top: -3.5%; /* Mova para cima */ 
  }


  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TransactionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  gap: 16px;
  padding: 12px 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e2e8f0;
    border-radius: 8px;
  }
`;

export const TransactionType = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 16px;
    font-weight: normal;
  }
`;

export const TransactionDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  flex: 1;

  span {
    font-size: 14px;
    font-weight: 300;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.received {
      color: #3d9c3d;
    }

    &.sent {
      color: #ff4b4a;
    }
  }
`;

export const ExtractLink = styled(Link)`
  text-decoration: underline;
  color: #8979ff;
  font-weight: 500;
  font-size: 13px;
  margin-left: auto;
  margin-top: 8px;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.75);
  }
`;
