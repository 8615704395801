import { ExchangeContainer, Container } from "./Exchange.styles";

import RevertIcon from "../../../assets/revertBlueIcon.svg";

export function Exchange() {
	return (
		<Container>
			<h3 className="font-bold ml-2 mb-2 xl:text-xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl">
				Câmbio
			</h3>
			<ExchangeContainer>
				<span className="text-[#FF9500] text-sm py-1 1xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl">
					Selecione a moeda
				</span>
				<select
					name="Dolar Americano"
					id=""
					className="text-sm 1xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl px-2 border border-[#D9D9D9]"
				>
					<option value="Dolar Americano">Dolar Americano</option>
				</select>
				<span className="text-[#FF9500] text-sm 1xl:text-xl 2xl:text-2xl 4xl:text-4xl">
					Conversão
				</span>
				<div className="flex items-center gap-4 text-xl 1xl:text-2xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl">
					<span>$ 1,00</span>
					<span>
						<img
							src={RevertIcon}
							alt=""
							className="1xl:w-[30px] 1xl:h-[30px] 2xl:w-[40px] 2xl:h-[40px] 3xl:w-[50px] 3xl:h-[50px]"
						/>
					</span>
					<span>$ 5,62</span>
				</div>
			</ExchangeContainer>
		</Container>
	);
}
