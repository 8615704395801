import styled from "styled-components";

export const Section = styled.section`
   width: 99vw;
  height: 93vh;
  position: relative;
  display: grid; /* Usar grid para organizar os elementos */
  grid-template-columns: repeat(2, 1fr); /* Duas colunas */
  grid-template-rows: repeat(2, 1fr); /* Duas linhas */
  gap: 2%; /* Espaço entre os itens */
  padding: 4%; /* Espaço nas margens laterais */
  box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
  max-height: 100%; /* Garante que o filho não ultrapasse o limite do contêiner */
  overflow: hidden; /* Esconde qualquer conteúdo extra */
  place-items: center; /* Centraliza horizontal e verticalmente */


  /* Media Queries para dispositivos Apple */
  @media (max-width: 3024px) {
    /* Para MacBook Pro 14" e resoluções menores */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 2560px) {
    /* Para MacBook Air e Pro 13" */ 
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 2304px) {
    /* Para iMac 21.5" (4K Retina) */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 2048px) {
    /* Para MacBook Pro 16" (M1) */
    grid-template-columns: repeat(2, 1fr);  
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 1920px) {
    /* Para iMac 27" (5K Retina) */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    height: 92vh;
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 1680px) {
    /* Para iMac 24" (M1) */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 1440px) {
    /* Para Pro Display XDR (32") */    
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */   
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  } 
  @media (max-width: 1366px) {
    /* Para Pro Display XDR (32") */    
    grid-template-columns: repeat(2, 1fr);                                        
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    height: 89vh;
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */   
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 1024px) {
    /* Para iMac 21.5" (4K Retina) */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 768px) {
    /* Para iMac 27" (5K Retina) */    
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 600px) {
    /* Para iMac 24" (M1) */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }

  @media (max-width: 480px) {   
    /* Para iMac 21.5" (4K Retina) */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 2%; /* Espaço entre os itens */
    padding: 4%; /* Espaço nas margens laterais */
    box-sizing: border-box; /* Inclui padding no cálculo de largura/altura */
    overflow: hidden; /* Evita que elementos transbordem do contêiner */
  }
`;

//////////////////////////////////////////////////////////////

export const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 2px;
  justify-content: center;
  border: 1px solid red;

  
`;

// Componente MagicSquare com a tipagem de squareSize
export const MagicSquare = styled.div`
  width: 300px;
  height: 300px;
  border: 2px solid black;

  
`;

export const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 29px; /* 1.8125rem */
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 24px; /* 1.875rem */
  flex-wrap: wrap;
  padding: 0 2px;
  justify-content: center;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: center;
`;
