import React from "react";

import {
  Container,
  ContentWrapper,
  Line,
  Subtitle,
  TextWrapper,
  Title,
  WrapperLeft,
  WrapperLink,
  WrapperRight,
} from "./styles";

import { ReactComponent as ArrowRight } from "../../assets/LParrowRight.svg";

const SectionCountWithStric: React.FC = () => {
  return (
    <Container>
      <ContentWrapper>
        <WrapperLeft>
          <Title>Conte com a VILAPAY-BANK</Title>
          <Subtitle>Fuja das taxas dos Bancos!</Subtitle>
        </WrapperLeft>
        <WrapperRight>
          <TextWrapper>
            <Line />

            <div>
              <Title>Abra sua conta agora mesmo</Title>
              <Subtitle>
                É rápido e fácil: cadastre-se em menos de 10 minutos!
              </Subtitle>
            </div>
          </TextWrapper>
          <WrapperLink href="/create-account-wallet">
            <strong>Abrir conta</strong>
            <ArrowRight />
          </WrapperLink>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
};

export default SectionCountWithStric;
