import type { ExtractItem } from "./extractDescription";

const types = [
	"PIX_KEY",
	"PIX_MANUAL",
	"PIX_RECEIVED",
	"PIX_QR_CODE_STATIC",
	"PIX_QR_CODE_DYNAMIC",
	"PIX_DYNAMIC",
	"PIX_STATIC",
	"P2P",
	"pix",
	"TED",
	"PAGBANK_PAYMENT",
	"PAGBANK_PAYMENT_DISCOUNT",
	"INTERNAL_TAX",
	"INTERNAL_WALLET_TRANSACTION",
];

export function separateTransactions(transactions: ExtractItem[]) {
	const orderedTransactions: ExtractItem[] = [];
	const specifiedTransactions: ExtractItem[] = [];
	const internalTaxTransactions: ExtractItem[] = [];

	transactions.forEach((transaction: ExtractItem) => {
		if (types.includes(transaction.type)) {
			specifiedTransactions.push(transaction);
		} else if (transaction.type === "INTERNAL_TAX") {
			internalTaxTransactions.push(transaction);
		}
	});

	for (const transaction of specifiedTransactions) {
		const matchingTax = internalTaxTransactions.find(
			(tax) => tax.number_of_transaction === transaction.number_of_transaction,
		);

		if (matchingTax) {
			orderedTransactions.push(matchingTax);
			orderedTransactions.push(transaction);
		} else {
			orderedTransactions.push(transaction);
		}
	}

	return {
		orderedTransactions,
		specifiedTransactions,
		internalTaxTransactions,
	};
}
