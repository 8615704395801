import {
	Children,
	FC,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import socket from "src/services/socket-io";
import { useAuth } from "./auth";
import Modal from "src/components/Modal";
import { AlertPix } from "src/components/alertPix";
import toLocaleAmount from "src/utils/toLocaleAmount";
import Lottie from "lottie-react";
import { checkAnimation } from "src/assets/animations";

type ProviderProps = {
	onChangeBalance?: (balance: number) => void;
};

const SocketContext = createContext({});

const audioMP3 = require("../assets/cash-in.mp3");

console.log("CASH IN", audioMP3);

export const SocketContextProvider: FC<ProviderProps> = ({
	children,
	onChangeBalance = () => {},
}) => {
	const { account, user, refreshAccount } = useAuth();

	const audioRef = useRef<HTMLAudioElement>(null);

	const [alertOpen, setAlertIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [amount, setAmount] = useState(0);

	const openModal = () => {
		const url = window.location.href;

		if (url.includes("qrcode") || url.includes("adds-founds-wallet")) {
			setModalIsOpen(true);
		}

		try {
			audioRef.current!.play();
		} catch (error) {
			console.log(error);
		}
	};

	const openAlert = () => {
		setAlertIsOpen(true);
	};

	useEffect(() => {
		if (socket.disconnected) {
			socket.connect();
		}

		if (socket.connected) {
			console.log("Conectado");
		}

		socket.on(
			`update-balance-${account?.id}`,
			(data: { balance: number; amount: number }) => {
				console.log("update-balance", data);
				onChangeBalance(data.balance);
				setAmount(data.amount);
				openModal();
				openAlert();
			},
		);

		socket.on(
			`update-balance-${user?.id}`,
			(data: { balance: number; amount: number }) => {
				console.log("update-balance", data);
				onChangeBalance(data.balance);
				setAmount(data.amount);
				openModal();
				openAlert();
			},
		);

		return () => {
			socket.off(`update-balance-${user?.id}`);
			socket.off(`update-balance-${account?.id}`);
		};
	}, []);

	return (
		<SocketContext.Provider value={{}}>
			<Modal open={modalIsOpen} onClickClose={() => setModalIsOpen(false)}>
				<div className="flex flex-col justify-center items-center gap-4 p-4 py-8">
					<Lottie
						animationData={checkAnimation}
						autoplay
						loop={false}
						className="w-[120px] h-[120px]"
					/>
					<span className="text-black text-xl font-medium uppercase">
						Saldo adicionado com sucesso!
					</span>
					<span className="text-green-500 font-extrabold text-4xl uppercase">
						R${toLocaleAmount(amount)}
					</span>
					<span className="text-sm text-zinc-500">
						Em caso de dúvidas, envie um e-mail para o nosso time de suporte:{" "}
						<a
							className="text-blue-500 underline"
							href="mailto:suporte@vilapaybank.com.br"
						>
							suporte@vilapaybank.com.br
						</a>
					</span>
				</div>
			</Modal>
			<AlertPix
				onClose={() => setAlertIsOpen(false)}
				open={alertOpen}
				severity="success"
				text={`Pix Recebido no valor de R$ ${toLocaleAmount(amount)}`}
			/>
			<audio ref={audioRef} src={audioMP3} />
			{children}
		</SocketContext.Provider>
	);
};

export const useSocketContext = () => {
	const context = useContext(SocketContext);
	return context;
};
