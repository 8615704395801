import React, { FC } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { ExtractItem } from "src/utils/extractDescription";
import {
  InternalResponse,
  InternalTaxResponse,
  PagbankPaymentResponse,
  PixResponse,
  SlipResponseTx,
  TedResponse,
} from "./ProofResponses";
import transactionLabel from "src/utils/transactionLabel";
import {
  ButtonWrapper,
  CloseIcon,
  DownloadIcon,
  InfoModal,
  Modal,
  ModalContent,
  ModalHeader,
  Section,
  SectionAccountinfo,
  SectionCPNJ,
  SectionItem,
  SectionPayerName,
  SectionTitle,
} from "../ModalConfirmOperation/styles";
import toLocaleAmount from "src/utils/toLocaleAmount";
import dateToLine from "src/utils/dateToLine";
import { documentFormatted } from "src/utils/documentFormatted";
import { Button } from "../button";
import { config } from "src/config";
import { dateFormat } from "src/utils/dateFormat";

export const PagbankPaymentDiscount: FC<{
  data: ExtractItem;
  closeModal: () => void;
}> = ({ data, closeModal }) => {
  const txType = data.type.toLowerCase();

  console.log(txType);

  const naviagate = useNavigate();

  if (!txType.includes("slip")) {
    naviagate("/extract");
  }

  function printPDF() {
    if (!data) return null;

    const receiptString = JSON.stringify(data);

    localStorage.setItem("temp_receipt_to_print", receiptString);

    window.open(`${config.FRONTEND_URL}/PDF`);
  }

  console.log(data.data);

  const response = data.data as PagbankPaymentResponse;

  const label = transactionLabel(data);

  console.log(response);

  return (
    <>
      <ModalHeader>
        <div>
          <span>{label}</span>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
        </div>
      </ModalHeader>
      <ModalContent>
        <InfoModal>
          <div>
            <p>VILAPAY BANK</p>
            <p>R$ {toLocaleAmount(data.amount)}</p>
          </div>
          <span>{dateToLine(data.created_at)}</span>
        </InfoModal>
        <Section>
          <SectionTitle>Pagador</SectionTitle>
          <SectionPayerName>
            <span>NOME</span>
            <p>{response.payer.name}</p>
          </SectionPayerName>
          <SectionCPNJ>
            <span>{response.payer.document.length > 11 ? "CNPJ" : "CPF"}</span>
            <p>{documentFormatted(response.payer.document)}</p>
          </SectionCPNJ>

          <SectionAccountinfo>
            {/* <SectionItem>
              <span>AG</span>
              <p>{response.payer.}</p>
            </SectionItem>
            <SectionItem>
              <span>CONTA</span>
              <p>{response.payer.number}</p>
            </SectionItem> */}
          </SectionAccountinfo>
        </Section>
        <Section>
          <SectionTitle>Favorecido</SectionTitle>
          <SectionItem>
            <span>NOME</span>
            <p>{response.beneficiary.name}</p>
          </SectionItem>
          <SectionItem>
            <span>
              {response.beneficiary.document.length > 11 ? "CNPJ" : "CPF"}
            </span>
            <p>{documentFormatted(response.beneficiary.document)}</p>
          </SectionItem>
          <SectionItem>
            <span>INSTITUIÇÃO</span>
            <p>VILAPAY BANK</p>
          </SectionItem>
          <SectionItem>
            <span>Descrição</span>
            <p>{data?.description}</p>
          </SectionItem>
          <SectionAccountinfo>
            {/* <SectionItem>
              <span>AG</span>
              <p>{response.beneficiary.branch}</p>
            </SectionItem>
            <SectionItem>
              <span>CONTA</span>
              <p>{response.beneficiary.number}</p>
            </SectionItem> */}
          </SectionAccountinfo>
        </Section>
        <ButtonWrapper>
          <Button
            handleFunction={printPDF}
            Icon={DownloadIcon}
            title="Baixar Comprovante"
          />
        </ButtonWrapper>
      </ModalContent>
    </>
  );
};
