import { MachineContainer, Container } from "./Machine.styles";

import { Link } from "react-router-dom";
import ExtractIcon from "../../../assets/extracBlackIcon.svg";
import TerminalAutomatic from "../../../assets/terminalAutomaticIcon.svg";

export function Machine() {
	return (
		<Container>
			<h3 className="font-bold ml-2 mb-2 xl:text-xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl">
				Maquininha
			</h3>
			<MachineContainer>
				<Link
					to={"/maquininha/my-terminals"}
					className="flex items-center gap-5 ml-6 3xl:gap-10"
				>
					<img
						src={TerminalAutomatic}
						alt=""
						className="1xl:w-[35px] 1xl:h-[35px] 2xl:w-[50px] 2xl:h-[50px] 3xl:w-[80px] 3xl:h-[80px]"
					/>
					<span className="-m-1 1xl:text-xl 2xl:text-2xl 3xl:text-4xl 4xl:text-5xl">
						Terminal
					</span>
				</Link>
				<hr className=" border border-[#E2E8F0] w-full " />
				<Link
					to={"/maquininha/extract"}
					className="flex items-center gap-5 ml-[22px] 3xl:gap-10"
				>
					<img
						src={ExtractIcon}
						alt=""
						className="1xl:w-[35px] 1xl:h-[35px] 2xl:w-[50px] 2xl:h-[50px] 3xl:w-[80px] 3xl:h-[80px]"
					/>
					<span className="1xl:text-xl 2xl:text-2xl 3xl:text-4xl 4xl:text-5xl">
						Extrato
					</span>
				</Link>
			</MachineContainer>
		</Container>
	);
}
