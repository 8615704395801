import { Link } from 'react-router-dom';
import copyAndPastePix from '../../assets/copyAndPastePix.svg';
import pixQrCode from '../../assets/pixQrCode.svg';
import { LinkOption } from "../../components/LinkOptions";

export function Maquininha() {

  return(
    <section className='flex items-start justify-start gap-7 flex-col px-7 py-11'>
    <div className='space-y-7 text-center'>
      <h4 className='text-3xl font-bold'>Ações</h4>
      <div className='flex items-center justify-start flex-wrap gap-7'>
        <Link to="/maquininha/my-terminals">
          <LinkOption title="Terminais" icon={copyAndPastePix}  />
        </Link>
        <Link to={"/maquininha/extract"}>
          <LinkOption title="Extrato" icon={pixQrCode}  />
        </Link>
      </div>
    </div>
  </section>
  )
}
