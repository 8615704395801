import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon } from "src/assets/icons/closeIcon";
import { DashboardIcon } from "src/assets/icons/dashboardIcon";
import { ExtractIcon } from "src/assets/icons/extractIcon";
import { ListIcon } from "src/assets/icons/listIcon";
import { NotificationsIcon } from "src/assets/icons/notificationIcon";
import { PaymentIcon } from "src/assets/icons/paymentIcon";
import { PixIcon } from "src/assets/icons/pixIcon";
import { WalletIcon } from "src/assets/icons/walletIcon";
import { api } from "src/services/api";
import circlePerson from "../../assets/circlePerson.png";
import lupa from "../../assets/lupa.png";
import logoVilapayInvest from "../../assets/vila-api.svg";
import { BellMessage } from "../bellMessage";
import {
	BellNotifications,
	BellWrapper,
	BoxNotifications,
	BoxWrapper,
	ButtonBell,
} from "../header/styles";
import { Settings } from "./components/Settings";
import {
	Box,
	HeaderContainer,
	Logo,
	NavIcons,
	UserIcons,
} from "./headerStyles";

export const DashboardHeader = () => {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const token = localStorage.getItem("@stricv2:token");

	const audioRef = useRef<HTMLAudioElement>(null);
	const [notifications, setNotifications] = useState<any[]>([]);
	const [quantity, setQuantity] = useState(0);

	const mobileNavigation = (path: string) => {
		navigate(path);
		setIsOpen(false);
	};

	const fetchNotifications = async () => {
		try {
			const url = "/notifications";
			const { data } = await api.get(url, {
				headers: { Authorization: `Bearer ${token}` },
			});
			console.log("notifications", data.notifications);
			setQuantity(data.quantity);
			if (data.quantity > 0) {
				try {
					audioRef.current!.play();
				} catch (error) {
					console.log(error);
				}
			}
			setNotifications(data.notifications);
		} catch (error) {}
	};

	useEffect(() => {
		fetchNotifications();
	}, []);

	const [BellBoxMessageIsActive, setBoxMessageIsActive] = useState(false);

	const HandleBoxMessageToggle = async () => {
		if (quantity > 0) {
			try {
				const url = "/notifications/read";
				const { data } = await api.get(url, {
					headers: { Authorization: `Bearer ${token}` },
				});
				console.log("notifications", data.notifications);
				setQuantity(data.quantity);
				setNotifications(data.notifications);
			} catch (error) {}
		}
		setBoxMessageIsActive((state) => !state);
	};

	return (
		<HeaderContainer>
			<Logo>
				<button type="button" onClick={() => navigate("/")}>
					<img
						src={logoVilapayInvest}
						className="object-contain 1xl:w-[150px] 1xl:h-[50px] 3xl:w-[250px] 3xl:h-[80px] 4xl:w-[300px] 5xl:w-[350px] 5xl:h-[120px]"
						alt="Vila Pay Logo"
					/>
				</button>
			</Logo>
			<NavIcons>
				<button
					type="button"
					onClick={() => navigate("/")}
					className={`h-[48px] w-[48px] 1xl:h-[58px] 1xl:w-[58px] 3xl:w-[85px] 3xl:h-[85px] 6xl:w-[110px] 6xl:h-[110px] flex justify-center rounded-full items-center ${location.pathname === "/" ? "bg-[#FF9500]" : ""}`}
				>
					<DashboardIcon className="w-[30px] h-[30px] 1xl:h-[40px] 1xl:w-[40px] 3xl:w-[55px] 3xl:h-[55px] 6xl:w-[70px] 6xl:h-[70px] " />
				</button>
				<button
					type="button"
					onClick={() => navigate("/extract")}
					className={`h-[48px] w-[48px] 1xl:h-[58px] 1xl:w-[58px] 3xl:w-[85px] 3xl:h-[85px] 6xl:w-[110px] 6xl:h-[110px] flex justify-center rounded-full items-center ${location.pathname.split("/")[1] === "extract" ? "bg-[#FF9500]" : ""}`}
				>
					<ExtractIcon className="w-[30px] h-[30px] 1xl:h-[40px] 1xl:w-[40px] 3xl:w-[55px] 3xl:h-[55px] 6xl:w-[70px] 6xl:h-[70px]" />
				</button>
				<button
					type="button"
					onClick={() => navigate("/pix")}
					className={`h-[48px] w-[48px] 1xl:h-[58px] 1xl:w-[58px] 3xl:w-[85px] 3xl:h-[85px] 6xl:w-[110px] 6xl:h-[110px] flex justify-center rounded-full items-center ${location.pathname.split("/")[1] === "pix" ? "bg-[#FF9500]" : ""}`}
				>
					<PixIcon className="w-[30px] h-[30px] 1xl:h-[40px] 1xl:w-[40px] 3xl:w-[55px] 3xl:h-[55px] 6xl:w-[70px] 6xl:h-[70px] " />
				</button>
				<button
					type="button"
					onClick={() => navigate("/wallet")}
					className={`h-[48px] w-[48px] 1xl:h-[58px] 1xl:w-[58px] 3xl:w-[85px] 3xl:h-[85px] 6xl:w-[110px] 6xl:h-[110px]  flex justify-center rounded-full items-center ${location.pathname.split("/")[1] === "wallet" ? "bg-[#FF9500]" : ""}`}
				>
					<WalletIcon className="w-[30px] h-[30px] 1xl:h-[40px] 1xl:w-[40px] 3xl:w-[55px] 3xl:h-[55px] 6xl:w-[70px] 6xl:h-[70px]" />
				</button>
				<button
					type="button"
					onClick={() => navigate("/maquininha")}
					className={`h-[48px] w-[48px] 1xl:h-[58px] 1xl:w-[58px] 3xl:w-[85px] 3xl:h-[85px] 6xl:w-[110px] 6xl:h-[110px]  flex justify-center rounded-full items-center ${location.pathname.split("/")[1] === "maquininha" ? "bg-[#FF9500]" : ""}`}
				>
					<PaymentIcon className="w-[30px] h-[30px] 1xl:h-[40px] 1xl:w-[40px] 3xl:w-[55px] 3xl:h-[55px] 6xl:w-[70px] 6xl:h-[70px]" />
				</button>
			</NavIcons>
			<div className="hidden xl:flex items-center 3xl:w-[400px] 6xl:w-[550px]  px-4 py-2 1xl:px-6 1xl:py-3 rounded-[25px] bg-[#f5f5f5] gap-3">
				<img
					src={lupa}
					alt="Search"
					className="1xl:h-[30px] 1xl:w-[30px] 3xl:w-[45px] 3xl:h-[45px] 6xl:w-[55px] 6xl:h-[55px]"
				/>
				<p className="bg-transparent w-full text-[#8BA3CB] 1xl:text-xl 3xl:text-2xl 4xl:text-3xl 6xl:text-4xl">
					Search for...
				</p>
			</div>
			<UserIcons>
				<Settings />

				<BellWrapper>
					<ButtonBell onClick={HandleBoxMessageToggle}>
						<NotificationsIcon className="w-[30px] h-[30px] 1xl:h-[40px] 1xl:w-[40px] 3xl:w-[55px] 3xl:h-[55px] 6xl:w-[70px] 6xl:h-[70px]" />
					</ButtonBell>
					<BellNotifications>
						<p>2</p>
					</BellNotifications>
					<AnimatePresence>
						{BellBoxMessageIsActive && (
							<BoxWrapper
								initial={{ opacity: 0, y: "-10%" }}
								animate={{ opacity: 1, y: "0%" }}
								exit={{ opacity: 0, y: "-10%" }}
								transition={{ duration: 0.2 }}
							>
								<Box>
									<span />
									<BoxNotifications>
										<BellMessage
											notification={{
												title: "Transferencia de dinheiro",
											}}
											// Você recebeu uma transferência de ROSANGELA ANTUNES FERREIRA no valor de R$ 1.245,23
										/>
									</BoxNotifications>
								</Box>
							</BoxWrapper>
						)}
					</AnimatePresence>
				</BellWrapper>

				<div className=" flex justify-center rounded-full items-center">
					<img
						src={circlePerson}
						alt="User Icon"
						className="1xl:h-[70px] 1xl:w-[70px] 3xl:h-[90px] 3xl:w-[90px] 6xl:h-[110px] 6xl:w-[110px]"
					/>
				</div>
			</UserIcons>
			<button
				type="button"
				onClick={() => setIsOpen(!isOpen)}
				className="flex md:hidden absolute top-7 right-5"
			>
				{isOpen ? (
					<CloseIcon className="w-[25] h-[25]" />
				) : (
					<ListIcon className="w-[25] h-[25]" />
				)}
			</button>
			{isOpen && (
				<div className="w-full flex flex-col items-center gap-2 bg-[#fff]">
					<button
						type="button"
						onClick={() => mobileNavigation("/")}
						className="h-12 w-12 flex justify-center rounded-full items-center"
					>
						<DashboardIcon className="w-[30] h-[30]" />
					</button>
					<button
						type="button"
						onClick={() => mobileNavigation("/extract")}
						className="h-12 w-12 flex justify-center rounded-full items-center"
					>
						<ExtractIcon className="w-[26] h-[25]" />
					</button>
					<button
						type="button"
						onClick={() => mobileNavigation("/pix")}
						className="h-12 w-12 flex justify-center rounded-full items-center"
					>
						<PixIcon className="w-[26] h-[25]" />
					</button>
					<button
						type="button"
						onClick={() => mobileNavigation("/wallet")}
						className="h-12 w-12 flex justify-center rounded-full items-center"
					>
						<WalletIcon className="w-[30] h-[30]" />
					</button>
					<button
						type="button"
						onClick={() => mobileNavigation("/my-terminals")}
						className="h-12 w-12 flex justify-center rounded-full items-center"
					>
						<PaymentIcon className="w-[23] h-[26]" />
					</button>
				</div>
			)}
		</HeaderContainer>
	);
};
