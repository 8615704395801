// import styled from 'styled-components';

// // Header container
// export const Header = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   height: 80px;
//   background-color: #fff;
//   padding: 0 20px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// `;

// // Logo container
// export const Logo = styled.div`
//   img {
//     width: 126px; /* Ajuste o tamanho conforme necessário */
//     height: auto;
//   }
// `;

// // Navigation icons container
// export const NavIcons = styled.div`
//   display: flex;
//   align-items: center;

//   img {
//     width: 50px;
//     height: 50px;
//     margin: 0 5px;
//   }
// `;

// // Search bar container
// export const SearchBar = styled.div`
//   display: flex;
//   align-items: center;
//   background-color: #f5f5f5;
//   border-radius: 25px;
//   padding: 10px 20px;

//   img {
//     width: 20px;
//     height: 20px;
//     margin-right: 10px;
//   }

//   span {
//     font-size: 15px;
//     color: #8ba3cb;
//   }
// `;

// // User icons container
// export const UserIcons = styled.div`
//   display: flex;
//   align-items: center;

//   img {
//     width: 50px;
//     height: 50px;
//     margin: 0 5px;
//   }
// `;

// Header Component
// export const HeaderComponent = () => {
//   return (
//     <Header>
//       <Logo>
//         <img src="src/assets/logoVilapayInvest.png" alt="Vila Pay Logo" />
//       </Logo>
//       <NavIcons>
//         <img src="src/assets/circleQrCode.png" alt="Icon 1" />
//         <img src="src/assets/circleExtract.png" alt="Icon 2" />
//         <img src="src/assets/pix.png" alt="Icon 3" />
//         <img src="src/assets/wallet.png" alt="Icon 4" />
//         <img src="src/assets/machine.png" alt="Icon 5" />
//       </NavIcons>
//       <SearchBar>
//         <img src="src/assets/lupa.png" alt="Search" />
//         <span>Search for something</span>
//       </SearchBar>
//       <UserIcons>
//         <img src="src/assets/config.png" alt="Settings" />
//         <img src="src/assets/bellNotification.png" alt="Notifications" />
//         <img src="src/assets/circlePerson.png" alt="User Icon" />
//       </UserIcons>
//     </Header>
//   );
// };

// export default HeaderComponent;

import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { ReactComponent as ArrowUpRightIconSVG } from "../../assets/arrow-up-right.svg";
import { ReactComponent as ArrowPageRightIconSVG } from "../../assets/arrowPageRight.svg";
import { ReactComponent as ArrowUpIconSVG } from "../../assets/arrowUp.svg";
import { ReactComponent as BankMoneySVG } from "../../assets/bankmoney.svg";
import { ReactComponent as BellIconSVG } from "../../assets/bell.svg";
import { ReactComponent as CloseIconSVG } from "../../assets/close.svg";
import { ReactComponent as HomeIconSVG } from "../../assets/home.svg";
import { ReactComponent as ReceiptIconSVG } from "../../assets/receipt.svg";
import { ReactComponent as SettingsIconSVG } from "../../assets/settings-2.svg";
import { ReactComponent as SidebarIconSVG } from "../../assets/sidebarIcon.svg";

// Header container
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 2240px) {
    padding: 20px 40px;
    height: 80px;
  }

  @media (min-width: 3200px) {
    padding: 20px 40px;
    height: 110px;
  }

  @media (min-width: 3840px) {
    padding: 20px 40px;
    height: 130px;
  }

  @media (min-width: 5120px) {
    padding: 20px 40px;
    height: 135px;
  }


  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

// Logo container
export const Logo = styled.div`
padding: 0 70px;
  
`;

// Navigation icons container
export const NavIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: 2240px) {
    gap: 15px;
  }

  @media (min-width: 3200px) {
    gap: 30px;
  }

  @media (min-width: 3840px) {
    gap: 50px;
  }

  @media (min-width: 5120px) {
    gap: 70px;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

// User icons container
export const UserIcons = styled.div`
  display: flex;
  align-items: center;
  padding: 0 70px;
  gap: 15px;

  @media (max-width: 769px) {
    display: none;
  }

  @media (min-width: 3200px) {
    gap: 50px;
  }

  @media (min-width: 3840px) {
    gap: 70px;
  }

  @media (min-width: 5120px) {
    gap: 90px;
  }
`;

type OtherAccountsProps = {
	isActive: boolean;
};

type BoxMessageProps = {
	isActive: boolean;
};

const iconStyle = css`
  fill: var(--white);
  width: 64px;
`;

const iconHeaderStyle = css`
  fill: var(--white);
`;

export const SettingsIcon = styled(SettingsIconSVG)`
  ${iconHeaderStyle}
`;

export const ArrowUpRightIcon = styled(ArrowUpRightIconSVG)`
  ${iconHeaderStyle}
`;

export const HomeIcon = styled(HomeIconSVG)`
  ${iconHeaderStyle}
`;

export const ReceiptIcon = styled(ReceiptIconSVG)`
  ${iconHeaderStyle}
`;

export const BankMoney = styled(BankMoneySVG)`
  ${iconHeaderStyle}
`;

export const ArrowPageRightIcon = styled(ArrowPageRightIconSVG)`
  ${iconHeaderStyle}
  margin-left: 24px;
  fill: var(--white);
`;

export const ArrowUpIcon = styled(ArrowUpIconSVG)`
  fill: var(--white) !important;
`;

export const SidebarIcon = styled(SidebarIconSVG)`
  ${iconStyle}
`;

export const CloseIcon = styled(CloseIconSVG)`
  ${iconStyle}
`;

export const BellIcon = styled(BellIconSVG)`
  ${iconStyle}
`;

export const BellWrapper = styled.div`
  position: relative;

  @media (min-width: 720px) {
    padding: 1px;
  }
`;

export const ButtonBell = styled.button`
  background: none;
  outline: 0;
  border-style: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
`;

export const BellNotifications = styled.div`
  background-color: var(--red);
  height: 13px;
  width: 13px;
  position: absolute;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 1000;

  p {
    font-size: 10px;
    color: var(--white);
    font-family: var(--regular);
  }
`;

export const Box = styled.div`
  span {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white);
    position: absolute;
    margin-left: 240px;
    top: -5px;

    @media (min-width: 720px) {
      margin-right: auto;
      margin-left: auto;
      left: 0;
      right: 0;
      top: -5px;
    }
  }
`;

export const BoxNotifications = styled.div`
  width: 280px;
  height: 280px;
  overflow: scroll;
  padding: 16px;

  ::-webkit-scrollbar {
    visibility: hidden;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const BoxWrapper = styled(motion.div)`
  background-color: var(--white);
  border-radius: 8px;
  position: absolute;
  left: -230px;
  top: 40px;

  @media (min-width: 720px) {
    left: -125px;
    top: 40px;
  }

  box-shadow: 0px 0px 10px -5px black;
  z-index: 2;
`;
