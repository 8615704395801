import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  top: -5.2%;
  width: 50%; 
  left: -1%;
  min-height: 20%;
  max-height: 40%;
    display: flex;
  flex-direction: column;

   /* Media Queries para dispositivos Apple */
   @media (max-width: 3024px) {
    /* Para MacBook Pro 14" e resoluções menores */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2560px) {
    /* Para MacBook Air e Pro 13" */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2304px) {
    /* Para iMac 21.5" (4K Retina) */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2234px) {
    /* Para MacBook Pro 16" (M1) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2880px) {
    /* Para iMac 27" (5K Retina) */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 2520px) {
    /* Para iMac 24" (M1) */
    width: 47%;
    height: 47%;
    margin: 1.5%;
  }

  @media (max-width: 3384px) {
    /* Para Pro Display XDR (32") */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  /* Media Queries para dispositivos Windows */
  @media (max-width: 1366px) {
    /* Para laptops básicos (HD) */
    width: 100%; /* Divs embaixo uma da outra */
    height: 48%;
    margin-bottom: 2%;
  }

  @media (max-width: 1920px) {
    /* Para monitores padrão (Full HD) */
    width: 48%;
    height: 48%;
    margin: 1%;
  }

  @media (max-width: 2560px) {
    /* Para monitores avançados (QHD/2K) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3200px) {
    /* Para laptops premium (QHD+) */
    width: 45%;
    height: 48%;
    margin: 1.5%;
  }

  @media (max-width: 3840px) {
    /* Para monitores 4K */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3440px) {
    /* Para monitores Ultrawide */
    width: 48%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 5120px) {
    /* Para monitores 5K */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 7680px) {
    /* Para monitores 8K (futuro) */
    width: 45%;
    height: 50%;
    margin: 1%;
  }
`;

export const ExchangeContainer = styled.div`
  
  top: -5.2%; /* Mova para cima */ 
  width: 100%; // height: 50%;
  left: -1%;
  //max-width: 55%;
  // height: 50%;
 //margin: 0 1%;
  min-height: 80%; /* Garante que o filho não ultrapasse o limite do contêiner */
  max-height: 95%; /* Garante que o filho não ultrapasse o limite do contêiner */
  

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #B1B1B1;
  background-color: #FAFAFA;
  border-radius: 25px;
  padding: 1.5rem 3.375rem;

  
    
    display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  h3 {
    position: relative;
    top: -3.5rem;
    left: -8rem;

    @media (max-width: 1024px) {
      top: -2rem;
      left: -6rem;
    }

    @media (max-width: 768px) {
      top: -1rem;
      left: -4rem;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  width: 293px;
  height: 169px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: #fbfafa;
  border: 0.5px solid #818181;
  border-radius: 25px;
  padding: 1.5rem 3.375rem;

  position: relative;
  top: -5.2%; /* Mova para cima */ 
  width: 100%; // height: 50%;
  //max-width: 55%;
  // height: 50%;
 //margin: 0 1%;
  
  
 // min-height: 20%; /* Garante que o filho não ultrapasse o limite do contêiner */
 // max-height: 30%; /* Garante que o filho não ultrapasse o limite do contêiner */
  overflow: hidden; /* Esconde qualquer conteúdo extra */

  span {
    font-size: 0.875rem;
    padding: 0.25rem 0;
  }

  .highlight {
    color: #ff9500;
  }

  select {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }

  .conversion {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.25rem;
  }



  /* Media Queries para dispositivos Apple */
  @media (max-width: 3024px) {
    /* Para MacBook Pro 14" e resoluções menores */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2560px) {
    /* Para MacBook Air e Pro 13" */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2304px) {
    /* Para iMac 21.5" (4K Retina) */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2234px) {
    /* Para MacBook Pro 16" (M1) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2880px) {
    /* Para iMac 27" (5K Retina) */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 2520px) {
    /* Para iMac 24" (M1) */
    width: 47%;
    height: 47%;
    margin: 1.5%;
  }

  @media (max-width: 3384px) {
    /* Para Pro Display XDR (32") */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  /* Media Queries para dispositivos Windows */
  @media (max-width: 1366px) {
    /* Para laptops básicos (HD) */
    width: 100%; /* Divs embaixo uma da outra */
    height: 48%;
    margin-bottom: 2%;
  }

  @media (max-width: 1920px) {
    /* Para monitores padrão (Full HD) */
    width: 48%;
    height: 48%;
    margin: 1%;
  }

  @media (max-width: 2560px) {
    /* Para monitores avançados (QHD/2K) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3200px) {
    /* Para laptops premium (QHD+) */
    width: 45%;
    height: 48%;
    margin: 1.5%;
  }

  @media (max-width: 3840px) {
    /* Para monitores 4K */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3440px) {
    /* Para monitores Ultrawide */
    width: 48%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 5120px) {
    /* Para monitores 5K */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 7680px) {
    /* Para monitores 8K (futuro) */
    width: 45%;
    height: 50%;
    margin: 1%;
  }
`;
