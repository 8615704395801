import { z } from "zod";

export const pixKeyStepSchema = z.object({
	key: z.string().min(1, "Selecione uma chave."),
});

export const pixValueStepSchema = z.object({
	value: z.string().min(1, "Selecione um valor."),
});

export type PixKeyStepSchema = z.infer<typeof pixKeyStepSchema>;
export type PixValueStepSchema = z.infer<typeof pixValueStepSchema>;
