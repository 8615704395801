import { useForm } from "react-hook-form";
import type { BeneficiaryDataProps, NewPixFormProps } from ".";
import PixKeyImage from "../../../../../assets/PixKeyForm.svg";
import {
	pixKeyStepSchema,
	type PixKeyStepSchema,
} from "src/interface/newPixSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { api } from "src/services/api";
import { useAuth } from "src/hooks/auth";
import { setAuthToken } from "src/utils/setAuthToken";
import React, { useState } from "react";
import { ModalLoader } from "src/components/modalLoader";
import { isAxiosError } from "src/utils/isAxiosError";
import { showAlertError } from "src/components/alertError";

interface PixKeyStepProps extends NewPixFormProps {
	setBeneficiaryData: React.Dispatch<
		React.SetStateAction<BeneficiaryDataProps>
	>;
}

export function PixKeyStep({ nextStep, setBeneficiaryData }: PixKeyStepProps) {
	const { user } = useAuth();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<PixKeyStepSchema>({
		resolver: zodResolver(pixKeyStepSchema),
	});
	const [isSubmiting, setIsSubmiting] = useState(false);
	// Função para fazer a requisição
	async function onSubmit(data: PixKeyStepSchema) {
		setIsSubmiting(true);
		try {
			setAuthToken();
			const response = await api.post("/pix/keys/check", {
				key: data.key,
				userId: user.id,
			});
			setBeneficiaryData({
				name: response.data.data.counterparty.name,
				document: response.data.data.counterparty.tax_number,
				key: response.data.data.key,
			});
			nextStep();
		} catch (error) {
			if (isAxiosError(error) && error.response) {
				const errorMessage = (error.response.data as { message: string })
					.message;
				showAlertError(errorMessage);
			} else {
				showAlertError("Ocorreu um erro inesperado.");
			}
		} finally {
			setIsSubmiting(false);
		}
	}

	if (isSubmiting) {
		return <ModalLoader />;
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex flex-col gap-[1.875rem] items-center justify-center px-12 py-20 drop-shadow-md rounded"
		>
			<img src={PixKeyImage} alt="" />
			<span className="text-[1.875rem] font-bold ">Realizar novo pix</span>
			<div className="flex flex-col gap-2 items-start justify-start">
				<label htmlFor="key" className="font-semibold">
					Digite a chave
				</label>
				<input
					id="key"
					{...register("key")}
					type="text"
					required
					placeholder="Celular, E-mail, CPF, CNPJ ou Chave Aleatória"
					className="w-[467px] placeholder:text-lg placeholder:text-[#D3D1D1] border border-[#A0A0A0] rounded-[10px] py-3 px-5 placeholder:leading-tight"
				/>
			</div>
			{errors.key && <span className="text-red-500">{errors.key.message}</span>}
			<button
				type="submit"
				className="text-[0.9375rem] font-bold bg-[#004A8A] w-[475px] text-white h-[75px] flex items-center justify-center rounded-[10px] hover:brightness-75"
			>
				Confirmar e continuar
			</button>
		</form>
	);
}
