import styled from "styled-components";

// Elementos filhos
export const Container = styled.div`
  position: relative;
  top: -30%; /* Mova para cima */  
  min-width: 100%;
 // height: 50%;
  margin: 0 1%;
  display: "flex";
	flex-direction: "column";
	gap: "8px";
  padding: 3.125rem 3.375rem 3.125rem 3.375rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  min-height: 60%; /* Garante que o filho não ultrapasse o limite do contêiner */
  max-height: 100%; /* Garante que o filho não ultrapasse o limite do contêiner */
  overflow: hidden; /* Esconde qualquer conteúdo extra */
border: 1px solid black;
height: 100%;
border: 1px solid #B1B1B1;
  background-color: #FAFAFA;

  /* Media Queries para dispositivos Apple */
  @media (max-width: 3024px) {
    /* Para MacBook Pro 14" e resoluções menores */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2560px) {
    /* Para MacBook Air e Pro 13" */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2304px) {
    /* Para iMac 21.5" (4K Retina) */
    width: 48%;
    height: 48%;
    margin: 0.5%;
  }

  @media (max-width: 2234px) {
    /* Para MacBook Pro 16" (M1) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 2880px) {
    /* Para iMac 27" (5K Retina) */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 2520px) {
    /* Para iMac 24" (M1) */
    width: 47%;
    height: 47%;
    margin: 1.5%;
  }

  @media (max-width: 3384px) {
    /* Para Pro Display XDR (32") */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  /* Media Queries para dispositivos Windows */
  @media (max-width: 1366px) {
    /* Para laptops básicos (HD) */
    width: 100%; /* Divs embaixo uma da outra */
    height: 48%;
    padding: 1.125rem 1.375rem 1.125rem 1.375rem;
    margin-bottom: 2%;
  }

  @media (max-width: 1920px) {
    /* Para monitores padrão (Full HD) */
    width: 48%;
    height: 48%;
    margin: 1%;
  }

  @media (max-width: 2560px) {
    /* Para monitores avançados (QHD/2K) */
    width: 50%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3200px) {
    /* Para laptops premium (QHD+) */
    width: 45%;
    height: 48%;
    margin: 1.5%;
  }

  @media (max-width: 3840px) {
    /* Para monitores 4K */
    width: 45%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 3440px) {
    /* Para monitores Ultrawide */
    width: 48%;
    height: 50%;
    margin: 1%;
  }

  @media (max-width: 5120px) {
    /* Para monitores 5K */
    width: 46%;
    height: 48%;
    margin: 2%;
  }

  @media (max-width: 7680px) {
    /* Para monitores 8K (futuro) */
    width: 45%;
    height: 50%;
    margin: 1%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BalanceSection = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Subtitle = styled.span`
  font-size: 15px;
  color: #718ebf;
`;

export const Transactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Transaction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const TransactionLabel = styled.span`
  color: #718ebf;
`;

export const TransactionValue = styled.span<{ color: string }>`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.color};
`;
////////////////////

export const BalanceHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h4 {
        font-weight: bold;
        font-size: 2rem;
    }

    .icon {
        width: 25px;
        height: 25px;
    }
`;

export const BalanceDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70%;
`;

export const BalanceInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    img {
        width: 50px;
        height: 50px;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
`;

export const BalanceLabel = styled.span`
    font-size: 1.25rem;
    color: #718ebf;
`;

export const BalanceValue = styled.span`
    font-weight: bold;
    font-size: 1.5rem;

    &.positive {
        color: #16dbaa;
    }

    &.negative {
        color: #ff4b4a;
    }
`;

export const Divider = styled.hr`
    width: 2px;
    height: 60px;
    border: none;
    background-color: #e2ebfd;
`;

// export const Divider = styled.hr`
//   height: 102px;
//   width: 2px;
//   background-color: #e2ebfd;
//   border: none;
// `;
