import { FC, useRef, useState } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { UploadSimple } from "phosphor-react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "src/services/firebase";
import { api } from "src/services/api";

export const SendIdentificationPhoto: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<any>(null);

  const navigate = useNavigate();

  const fileInputRef = useRef<any>(null);

  const handleImageUpload = () => {
    fileInputRef?.current?.click();
  };

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setImage(file);
    }
  };

  const handleSavePhoto = async () => {
    if (!image) {
      return;
    }

    setLoading(true);
    const storageRef = ref(storage, `documents/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        setLoading(false);
        console.log(error);
      },
      async () => {
        const documentUri = await getDownloadURL(uploadTask.snapshot.ref);
        const addressData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-03")));
        const planData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-02")));
        const userData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-01")));

        if (documentUri) {
          localStorage.setItem("@yntech:documentUri", documentUri);
          setLoading(false);
          navigate("/create-account-wallet/identification-alert", { state: { accountType } });
        }
      }
    );
  };

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background max-2xl:gap-0">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/identification", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="750" height="90vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#AA7339" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="700"
            height="85vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[10vh] ml-14">
          <p className="text-white text-3xl font-extralight max-2xl:text-base">
            Precisamos que nos envie uma Selfie <br />
            para concluirmos o seu cadastro
          </p>

          <h3 className="text-white text-lg font-extralight mt-4 max-2xl:text-base max-2xl:mt-2">
            Selecione a foto em seu computador:
          </h3>
          <h3 className="text-white text-lg font-extralight mt-3 max-2xl:text-base">Selfie</h3>

          <input
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <button
            onClick={handleImageUpload}
            className="w-[37rem] h-64 rounded-2xl bg-white flex items-center justify-center mt-3 mb-3 overflow-hidden max-2xl:h-40"
          >
            {image !== null ? (
              <img
                className="w-[37rem] h-64 rounded-2xl overflow-hidden object-contain"
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <UploadSimple color="#CFCFCF" size={32} />
            )}
          </button>

          <p className="text-white text-sm font-extralight mb-10 max-2xl:text-xs">
            O ambiente deve está bem iluminado de forma moderada. Dê <br />
            preferência á iluminação natural. Tome cuidado com os reflexos <br />
            que possam deixara sua foto com uma visualização ruim.{" "}
          </p>

          <div className="flex gap-4">
            <GradientButton
              title="Voltar"
              tailwindStyles="bg-[#171717] text-white w-[17.7rem] h-12"
              onClick={() => navigate("/create-account-wallet/identification", { state: { accountType } })}
            />
            <GradientButton
              isLoading={loading}
              title="Avançar"
              tailwindStyles="bg-[#AA7339] text-white w-[17.7rem] h-12"
              onClick={handleSavePhoto}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
