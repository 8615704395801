import { IconProps } from "src/interface/iconProps";

export const DashboardIcon = ({ className }: IconProps) => {
	return (
		<svg
			className={className}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.1213 4.4541H3.63647V15.3632H12.1213V4.4541Z"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M25.4546 4.4541H16.9697V10.5147H25.4546V4.4541Z"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M25.4546 15.3633H16.9697V26.2724H25.4546V15.3633Z"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.1213 20.2119H3.63647V26.2725H12.1213V20.2119Z"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
