import { Eye } from "lucide-react";
import type { BeneficiaryDataProps, NewPixFormProps } from ".";
import USDCoinSvg from "../../../../../assets/USDBlueCoin.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	type PixValueStepSchema,
	pixValueStepSchema,
} from "src/interface/newPixSchema";
import { useState } from "react";
import { setAuthToken } from "src/utils/setAuthToken";
import { api } from "src/services/api";
import { ModalLoader } from "src/components/modalLoader";
import { isAxiosError } from "src/utils/isAxiosError";
import { showAlertError } from "src/components/alertError";
import moment from "moment";
import { useAuth } from "src/hooks/auth";

interface BeneficiaryDataStepProps extends NewPixFormProps {
	beneficiaryData: BeneficiaryDataProps;
}

export function BeneficiaryDataStep({
	nextStep,
	beneficiaryData,
}: BeneficiaryDataStepProps) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<PixValueStepSchema>({
		resolver: zodResolver(pixValueStepSchema),
	});
	const [isSubmiting, setIsSubmiting] = useState(false);

	const maskCpfCnpj = (value: string | undefined): string => {
		if (!value) return "";

		const numericValue = value.replace(/\D/g, "");

		switch (value.length) {
			case 11:
				return `***.${numericValue.slice(3, 6)}.${numericValue.slice(6, 9)}-**`;
			case 14:
				return `**.${numericValue.slice(2, 5)}.${numericValue.slice(5, 8)}/****-**`;
			default:
				return value;
		}
	};

	const getCurrentDate = () => {
		return moment().format("DD/MM/YYYY");
	};

	const getCurrentTime = () => {
		return moment().format("HH:mm");
	};
	const onSubmit = async (data: PixValueStepSchema) => {
		const splitAmount = data.value.split(",");
		const amount = Number(splitAmount[0] + "." + splitAmount[1]) * 100;
		setIsSubmiting(true);
		nextStep();
		try {
			setAuthToken();
			const response = await api.post("/pix/payment", {
				key: beneficiaryData.key,
				amount,
			});
			console.log(response);
		} catch (error) {
			if (isAxiosError(error) && error.response) {
				const errorMessage = (error.response.data as { message: string })
					.message;
				showAlertError(errorMessage);
			} else {
				showAlertError("Ocorreu um erro inesperado.");
			}
		} finally {
			setIsSubmiting(false);
		}
	};

	if (isSubmiting) {
		return <ModalLoader />;
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex flex-col gap-[0.625rem] items-center justify-center px-12 py-20 drop-shadow-md rounded"
		>
			<div className="flex items-center justify-between py-2 px-6 bg-[#E0E0E0] rounded-[1.25rem] w-[389px]">
				<div className="flex flex-col items-start justify-center">
					<span className="text-[0.9375rem]">Valor disponível em conta </span>
					<span className="text-[0.9375rem] font-semibold">R$ 150,00</span>
				</div>
				<Eye size={20} />
			</div>
			<div className="flex flex-col items-center gap-2 justify-center text-[#004A8A]">
				<img src={USDCoinSvg} alt="" />
				<input
					type="text"
					{...register("value")}
					required
					placeholder="R$0,00"
					className="placeholder:text-lg placeholder:text-[#D3D1D1] border border-[#A0A0A0] rounded-[10px] py-3 px-5 placeholder:leading-tight"
				/>
				{errors.value && (
					<span className="text-red-500">{errors.value.message}</span>
				)}
			</div>
			<div className="w-[347px] space-y-[10px]">
				<h4>Quem vai receber</h4>
				<div className="flex flex-col gap-4 my-4 py-4">
					<div className="flex w-full items-center justify-between gap-4">
						<span className="text-xs font-light">Beneficiário</span>
						<span className="text-xs font-semibold">
							{beneficiaryData.name}
						</span>
					</div>
					<div className="flex w-full items-center justify-between gap-4">
						<span className="text-xs font-light">CPF</span>
						<span className="text-xs font-semibold">
							{maskCpfCnpj(beneficiaryData.document)}
						</span>
					</div>
					<div className="flex w-full items-center justify-between gap-4">
						<span className="text-xs font-light">Horário</span>
						<span className="text-xs font-semibold">{getCurrentTime()}</span>
					</div>
					<div className="flex w-full items-center justify-between gap-4">
						<span className="text-xs font-light">Instituição</span>
						<span className="text-xs font-semibold">VillaApiBank</span>
					</div>
					<div className="flex w-full items-center justify-between gap-4">
						<span className="text-xs font-light">Data de pagamento</span>
						<span className="text-xs font-semibold">{getCurrentDate()}</span>
					</div>
					<div className="flex w-full items-center justify-between gap-4">
						<span className="text-xs font-light">Chave</span>
						<span className="text-xs font-semibold">{beneficiaryData.key}</span>
					</div>
				</div>
			</div>
			<button
				type="submit"
				className="text-[0.9375rem] font-bold bg-[#004A8A] w-[475px] text-white h-[75px] flex items-center justify-center rounded-[10px] transition hover:brightness-75"
			>
				Confirmar e continuar
			</button>
		</form>
	);
}
