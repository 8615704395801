import { currencyFormat } from "./currencyFormat";
import { dateFormatDaily } from "./dateFormat";
import { jsPDF } from "jspdf";
import logoImage from "../../src/assets/clients/vilapaybank/logo/white.png";

function createNewPDF() {
  const pdf = new jsPDF("p", "mm", "a4");
  return pdf;
}

function getType(transaction: string): string {
  if (transaction === "pix") {
    return "Pix";
  }

  if (transaction === "ted") {
    return "Ted";
  }

  if (transaction === "P2P") {
    return "Transferência entre contas";
  }

  if (transaction === "bank_slips") {
    return "Boleto";
  }

  if (transaction === "payment") {
    return "Pagamento de conta";
  }

  if (transaction === "internal") {
    return "Transação interna";
  }

  if (transaction === "auto_deposit") {
    return "Wallet Recarregada";
  }

  if (transaction === "initial") {
    return "";
  }

  return transaction;
}

export const extractTransactionsPDF = (transaction: any) => {
  const pdf = createNewPDF(); // Crie um novo PDF a cada vez
  console.log(transaction);

  const marginLeft = 10;
  const marginTop = 10;
  const marginRight = 10;
  const marginBottom = 10;

  const usableWidth =
    pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
  const usableHeight =
    pdf.internal.pageSize.getHeight() - marginTop - marginBottom;

  pdf.setFont("helvetica", "bold");
  pdf.setTextColor(0, 0, 0);

  const imgWidth = 100;
  const imgHeight = 30;
  const imgY = marginTop;
  const imgX = (usableWidth - imgWidth) / 2 + marginLeft;

  pdf.addImage(logoImage, "PNG", imgX, imgY, imgWidth, imgHeight);

  if (transaction.direction === "out" && transaction.type === "pix") {
    const amount = transaction.amount;
    const taxa = 0.0025; // Taxa de 0,25%

    // Calcula o valor da taxa
    let valorTaxa = amount * taxa;

    // Calcula o novo valor total após a aplicação da taxa
    let novoValorTotal = amount + valorTaxa;

    // Arredonda para cima
    let novoValorTotalRedondo = Math.ceil(valorTaxa * 100) / 100; // Arredonda para 2 casas decimais

    // Agora, `novoValorTotal` é o valor total após a aplicação da taxa e arredondamento para cima.
    let valorFinal = novoValorTotalRedondo - amount;
    let novoAmount = amount >= 99.99 ? novoValorTotalRedondo : 0.25;

    let AmountMax = novoAmount + amount;

    const formattedDate = dateFormatDaily(transaction.created_at);
    const formattedAmount = currencyFormat(amount);
    const formattedAmountTax = currencyFormat(novoAmount);
    const formattedAmountMax = currencyFormat(AmountMax);
    const canceled =
      transaction.direction === "out" &&
      transaction.type === "pix" &&
      transaction.status === "denied"
        ? "Transação Cancelada"
        : "";

    pdf.setFontSize(16);
    pdf.setTextColor(255, 0, 0); // RGB para vermelho
    pdf.text(`${canceled}`, 10, 40, { align: "justify" });

    // Restaura a cor padrão para o restante do texto (outras partes do PDF)
    pdf.setTextColor(0); // Padrão preto
    pdf.setFontSize(12);
    pdf.text(`Data: ${formattedDate}`, 66, 50, { align: "justify" });
    pdf.text(`(Transação Tarifada)`, 140, 50, { align: "justify" });

    pdf.text(`Valor: `, 10, 50, { align: "justify" });
    pdf.text(`${formattedAmount}`, 40, 50, { align: "justify" });

    pdf.text(`Tarifa: `, 10, 60, { align: "justify" });
    pdf.text(`${formattedAmountTax}`, 40, 60, { align: "justify" });

    pdf.text(`Valor Total: `, 10, 70, { align: "justify" });
    pdf.text(`${formattedAmountMax}`, 40, 70, { align: "justify" });
  } else {
    const formattedDate = dateFormatDaily(transaction.created_at);
    const formattedAmount = currencyFormat(transaction.amount);

    pdf.setFontSize(12);
    pdf.text(`Data: ${formattedDate}`, 10, 50, { align: "justify" });
    pdf.text(`Valor:`, 10, 60, { align: "justify" });
    pdf.text(`${formattedAmount}`, 10, 70, { align: "justify" });
  }

  // pdf.text(formattedAmount, 21, 56, { align: "justify" });

  // const title = transaction.title ?? transaction.description;
  const title =
    transaction.direction === "in" && transaction.type === "pix"
      ? `Pix recebido de: ${transaction?.response?.payer?.name}` ||
        `${transaction?.title}`
      : transaction.direction === "out" && transaction.type === "pix"
      ? `Pix enviado para: ${transaction?.description}`
      : transaction.type === "auto_deposit"
      ? transaction.title
      : "";
  const titleTwo =
    transaction.title === "Transferência para P2P Ajiopay"
      ? "Taxa de transação Checkout (saída)"
      : getType(transaction.type);

  const tipoPagamentoTextWidth =
    pdf.getStringUnitWidth("Tipo de pagamento:") * 12;
  const pageWidthTypePayment = pdf.internal.pageSize.getWidth();
  const tipoPagamentoX = pageWidthTypePayment - tipoPagamentoTextWidth - 30;
  const tipoPagamentoZ = pageWidthTypePayment - tipoPagamentoTextWidth - -11;

  pdf.text("Tipo de pagamento:", tipoPagamentoX, 60, { align: "justify" });
  pdf.text(titleTwo, tipoPagamentoZ, 60, { align: "justify" });
  pdf.text(title, tipoPagamentoX, 70, { align: "justify" });

  extractTransactionsIn(pdf, transaction);

  pdf.setFontSize(12);
  pdf.text(`CÓDIGO DE AUTENTICAÇÃO`, 80, 190);
  pdf.text(transaction?.id, 70, 200);
};

export const extractTransactionsIn = (pdf: jsPDF, data: any) => {
  const isGraphic = data?.response === null ? "Control-Account: " : "";
  const BankBeneficiary =
    data.type === "internal" && data.title === "Retirada"
      ? "VILAPAY-BANK"
      : data?.data?.payer?.bank_account?.bank_name ||
        data?.response?.payer_payee?.bank_account?.bank_name;
  const BankDocument =
    data.type === "pix" && data.direction === "out"
      ? data?.response?.payer_payee?.bank_account?.document
      : data?.data?.payer?.document;
  const user = data?.GraphicAccount ? data?.GraphicAccount : data?.data?.payer;
  const document =
    data.direction === "in" && data.type === "pix"
      ? "Instituição do pagagador: "
      : "Documento: ";

  //-----------------pdf MODELO
  if ("a" === "a") {
    pdf.setFontSize(18);
    pdf.text("Dados da conta debitada", 10, 90);

    pdf.setFontSize(12);
    pdf.text("", 10, 100, { align: "justify" });
    pdf.text("", 10, 110, { align: "justify" });
    pdf.text("", 10, 120);

    pdf.setFontSize(18);
    pdf.text("Dados do favorecido", 10, 140);

    pdf.setFontSize(12);
    pdf.text("", 10, 150, { align: "justify" });
    pdf.text("", 10, 160, { align: "justify" });
    pdf.text("", 10, 170);

    pdf.setFontSize(18);

    //PAYMENT OUT
    if (data.type === "payment") {
      const pagPayer =
        data.type === "payment" && data.response !== null
          ? data?.response?.account?.holder?.legal_name
          : data.type === "payment" && data.GraphicAccount !== null
          ? data?.GraphicAccount.name || "Não referenciado"
          : "";
      const pagDocument =
        data.type === "payment" && data.response !== null
          ? data?.response?.account?.holder?.document
          : data.type === "payment" && data.GraphicAccount !== null
          ? data?.GraphicAccount.document || "Não referenciado"
          : "";
      const pagEmail =
        data.type === "payment" && data.response !== null
          ? `E-MAIL: ${data?.response?.account?.email}`
          : "";
      const pagDescription =
        data.type === "payment" && data.response !== null
          ? `DESCRIÇÃO: ${data?.response?.description}`
          : "";
      const descriptionBeneficiary =
        data.type === "payment" ? ` LINHA DIGITÁVEL: ${data.description}` : "";

      pdf.setFontSize(12);
      pdf.text("PAGADOR: " + pagPayer, 10, 100, { align: "justify" });
      pdf.text("DOCUMENTO: " + pagDocument, 10, 110, { align: "justify" });
      pdf.text(pagEmail, 10, 120, { align: "justify" });
      pdf.text(pagDescription, 10, 130, { align: "justify" });

      pdf.setFontSize(12);
      pdf.text(descriptionBeneficiary, 10, 150, { align: "justify" });
    }
    //-------------------------------------------------------------------------------------------------
    //PIX in/OUT Concluído
    const pixNameBeneficiary =
      data.direction === "in" && data.type === "pix"
        ? data?.user?.name || data?.data?.payer?.name
        : data.direction === "out" && data.type === "pix"
        ? data?.data?.payer?.name ||
          data.response.payer_payee.bank_account?.name
        : "";
    const pixDocumentBeneficiary =
      data.direction === "in" && data.type === "pix"
        ? data?.user?.document || "Não referenciado"
        : data.direction === "out" && data.type === "pix"
        ? data?.data?.payer?.document ||
          data.response.payer_payee.bank_account.document
        : "";
    const pixNamePayer =
      data.direction === "in" && data.type === "pix"
        ? data?.data?.payer?.name || ""
        : data.direction === "out" && data.type === "pix"
        ? data?.user?.name || "Não referenciado" || data?.GraphicAccount?.name
        : "";
    const pixDocumentPayer =
      data.direction === "in" && data.type === "pix"
        ? data?.data?.payer?.document ||
          data.response.payer.bank_account?.bank_name
        : data.direction === "out" && data.type === "pix"
        ? data?.user?.document ||
          "Não referenciado" ||
          data.GraphicAccount.document
        : "";
    const pixBankBeneficiary =
      data.direction === "in" && data.type === "pix"
        ? "VILAPAY-BANK"
        : data?.data?.payer?.bank_account?.bank_name ||
          data?.response?.payer_payee?.bank_account?.bank_name;
    if (data.type === "pix") {
      //debitado
      pdf.setFontSize(12);
      pdf.text("Nome: " + isGraphic + pixNamePayer, 10, 100, {
        align: "justify",
      });
      pdf.text(document + pixDocumentPayer, 10, 110, { align: "justify" });
      pdf.text("INTERMEDIADOR: VILAPAY-BANK", 10, 120);

      //creditado
      pdf.setFontSize(12);
      pdf.text("Nome: " + pixNameBeneficiary, 10, 150, { align: "justify" });
      pdf.text("Documento: " + pixDocumentBeneficiary, 10, 160, {
        align: "justify",
      });
      pdf.text("Instituição: " + pixBankBeneficiary, 10, 170);
    }
    //---------------- pix i/out c-a ---------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------
    //TED in/OUT concluido
    const tedNameBeneficiary =
      data.direction === "in" && data.type === "ted"
        ? data?.user?.name || data?.data?.payer?.name
        : data.direction === "out" && data.type === "ted"
        ? data?.data?.payer?.name || data?.data?.bank_account?.name
        : "";
    const tedDocumentBeneficiary =
      data.direction === "in" && data.type === "ted"
        ? data?.user?.document || "Não referenciado"
        : data.direction === "out" && data.type === "ted"
        ? data?.data?.payer?.document || data?.data?.bank_account?.document
        : "";
    const tedNamePayer =
      data.direction === "in" && data.type === "ted"
        ? data?.data?.payer?.name || ""
        : data.direction === "out" && data.type === "ted"
        ? data?.user?.name ||
          "Não Referenciado" ||
          data?.response?.account?.name
        : "";
    const tedDocumentPayer =
      data.direction === "in" && data.type === "ted"
        ? data?.data?.payer?.document || data.response.payer.document
        : data.direction === "out" && data.type === "ted"
        ? data?.user?.document ||
          "Não Referenciado" ||
          data?.response?.account?.document
        : "";
    const tedBankBeneficiary =
      data.direction === "in" && data.type === "ted"
        ? "VILAPAY-BANK"
        : data?.response?.bank_account?.bank_name ||
          data?.response?.payer_payee?.bank_account?.bank_name ||
          "Não referenciado";
    if (data.type === "ted") {
      //debitado
      pdf.setFontSize(12);
      pdf.text("Nome: " + isGraphic + tedNamePayer, 10, 100, {
        align: "justify",
      });
      pdf.text(document + tedDocumentPayer, 10, 110, { align: "justify" });
      pdf.text("INTERMEDIADOR: VILAPAY-BANK", 10, 120);

      //creditado
      pdf.setFontSize(12);
      pdf.text("Nome: " + tedNameBeneficiary, 10, 150, { align: "justify" });
      pdf.text("Documento: " + tedDocumentBeneficiary, 10, 160, {
        align: "justify",
      });
      pdf.text("Instituição: " + tedBankBeneficiary, 10, 170);
    }
    //-------------------------------------------------------

    //BANKSLIP IN/out
    const bankslipsNameBeneficiary =
      data.direction === "in" && data.type === "bank_slips"
        ? data?.user?.name || data?.data?.payer?.name
        : data.direction === "out" && data.type === "bank_slips"
        ? data?.data?.payer?.name
        : "";
    const bankslipsDocumentBeneficiary =
      data.direction === "in" && data.type === "bank_slips"
        ? data?.user?.document || data?.data?.payer?.document
        : data.direction === "out" && data.type === "bank_slips"
        ? data?.data?.payer?.document
        : "";
    const bankslipsNamePayer =
      data.direction === "in" && data.type === "bank_slips"
        ? data.description || ""
        : data.direction === "out" && data.type === "bank_slips"
        ? ""
        : "BOLINHA";
    const bankslipsDocumentPayer =
      data.direction === "in" && data.type === "bank_slips"
        ? "***********" || data.response.payer.document
        : data.direction === "out" && data.type === "bank_slips"
        ? data?.user?.document
        : "";
    const bankslipsBankBeneficiary =
      data.direction === "in" && data.type === "bank_slips"
        ? "VILAPAY-BANK"
        : data?.response?.bank_account?.bank_name ||
          data?.response?.payer_payee?.bank_account?.bank_name;
    const nameNoName =
      data.type === "bank_slips" &&
      data.direction === "in" &&
      data.data.payer.type === "charge"
        ? "Nome: "
        : "";
    if (data.type === "bank_slips") {
      //debitado

      pdf.setFontSize(12);
      pdf.text(nameNoName + bankslipsNamePayer, 10, 100, { align: "justify" });
      pdf.text(document + bankslipsDocumentPayer, 10, 110, {
        align: "justify",
      });
      pdf.text("INTERMEDIADOR: VILAPAY-BANK", 10, 120);

      //creditado
      pdf.setFontSize(12);
      pdf.text("Nome: " + isGraphic + bankslipsNameBeneficiary, 10, 150, {
        align: "justify",
      });
      pdf.text("Documento: " + bankslipsDocumentBeneficiary, 10, 160, {
        align: "justify",
      });
      pdf.text("Instituição: " + bankslipsBankBeneficiary, 10, 170);
    }
    //---------------------------------------------------------------------------------
    // TRANSAÇAO INTERNA IN/out OR IN/out C-A ok
    const isCreditada =
      data.description === "Deposito" && data.type === "internal"
        ? "Saldo creditado pela conta de Tutela"
        : "";
    const isRetirada =
      data.description === "Retirada" && data.type === "internal"
        ? "Saldo resgatado pela conta de Tutela"
        : "";
    const capixNameBeneficiary =
      data.direction === "in" && data.type === "internal"
        ? data?.data?.payer?.name || isGraphic + data?.GraphicAccount?.name
        : data.direction === "out" && data.type === "internal"
        ? isRetirada || data?.data?.payer?.name
        : data.GraphicAccount?.name;
    const capixDocumentBeneficiary =
      data.direction === "in" && data.type === "internal"
        ? data?.GraphicAccount?.document || "********" || ""
        : data.direction === "out" && data.type === "internal"
        ? isRetirada || data?.data?.payer?.document
        : data.direction === "out" &&
          data.type === "internal" &&
          data.description === "Retirada"
        ? "********"
        : "";
    const capixNamePayer =
      data.direction === "in" && data.type === "internal"
        ? isCreditada || data?.data?.payer?.name || ""
        : data.direction === "out" && data.type === "internal"
        ? data?.data?.payer?.name ||
          ` ${isGraphic} ${data.GraphicAccount?.name}`
        : "";
    const capixDocumentPayer =
      data.direction === "in" && data.type === "internal"
        ? isCreditada ||
          data?.data?.payer?.document ||
          data.response?.payer?.bank_account?.bank_name
        : data.direction === "out" && data.type === "internal"
        ? data?.GraphicAccount?.document || data?.data?.payer?.document
        : "";
    const capixBankBeneficiary =
      data.direction === "in" && data.type === "internal"
        ? "VILAPAY-BANK"
        : data?.data?.payer?.bank_account?.bank_name ||
          data?.response?.payer_payee?.bank_account?.bank_name ||
          "VILAPAY-BANK";

    if (data.type === "internal" && data.response === null) {
      //debitado
      pdf.setFontSize(12);
      pdf.text("Nome: " + capixNamePayer || isCreditada, 10, 100, {
        align: "justify",
      });
      pdf.text(document + capixDocumentPayer, 10, 110, { align: "justify" });
      pdf.text("INTERMEDIADOR: VILAPAY-BANK", 10, 120);

      //creditado
      pdf.setFontSize(12);
      pdf.text("Nome : " + capixNameBeneficiary, 10, 150, { align: "justify" });
      pdf.text("Documento: " + capixDocumentBeneficiary, 10, 160, {
        align: "justify",
      });
      pdf.text("Instituição: " + capixBankBeneficiary, 10, 170);
    }

    //-------------------------------------------------------------------------------------------------

    // TRANSAÇAO INTERNA  IN/ recarga de wallet ok

    if (data.type === "auto_deposit") {
      //debitado
      pdf.setFontSize(12);
      pdf.text("Nome: " + data?.user?.name, 10, 100, { align: "justify" });
      pdf.text(document + data?.user?.document, 10, 110, { align: "justify" });
      pdf.text("INTERMEDIADOR: VILAPAY-BANK", 10, 120);

      //creditado
      pdf.setFontSize(12);
      pdf.text("Nome : " + data?.data?.payer?.name, 10, 150, {
        align: "justify",
      });
      pdf.text("Documento: " + "*********-**", 10, 160, { align: "justify" });
      pdf.text("Instituição: " + capixBankBeneficiary, 10, 170);
    }

    //------------------------------------------------------------------------------------------------------------------------

    pdf.setFontSize(12);
    pdf.text(`CÓDIGO DE AUTENTICAÇÃO`, 80, 190);
    pdf.text(data.id, 70, 200);

    if (data.status === "waiting") {
      if (!data.GraphicAccount) {
        console.log("ehehe");
        pdf.setFontSize(12);
        pdf.text("Status:", 10, 145);

        pdf.setFontSize(12);
        pdf.text("Aguardando autorização", 10, 150);
      }
    }
  }

  pdf.output("dataurlnewwindow");
};

const extractTransactionsType = (pdf: jsPDF, data: any) => {
  switch (data?.type) {
    case "ted":
      break;
  }
};

{
  /*

mport { currencyFormat } from "./currencyFormat";
import { dateFormatDaily } from "./dateFormat";
import { jsPDF } from "jspdf";
import logoImage from "../../src/assets/clients/vilapaybank/logo/white.png";

function createNewPDF() {
  const pdf = new jsPDF("p", "mm", [50, 50]);
  pdf.roundedRect(10, 10, 30, 30, 5, 5, "S");
  // Adiciona um retângulo com cantos arredondados simulados
pdf.setDrawColor(0); // Define a cor do contorno como preto
pdf.setFillColor("0"); // Define a cor de preenchimento como preto
pdf.roundedRect(10, 10, 30, 30, 8, 8, "FD"); // Usa valores maiores nos últimos dois parâmetros para simular um border-radius


// Definindo as coordenadas e o tamanho do retângulo
const x = 10;
const y = 10;
const width = 30;
const height = 30;
const borderRadius = 8;

// Desenhando as linhas do retângulo com cantos arredondados
pdf.line(x + borderRadius, y, x + width - borderRadius, y); // Linha superior
pdf.line(x + width, y + borderRadius, x + width, y + height - borderRadius); // Linha direita
pdf.line(x + width - borderRadius, y + height, x + borderRadius, y + height); // Linha inferior
pdf.line(x, y + height - borderRadius, x, y + borderRadius); // Linha esquerda

// Desenhando os arcos para os cantos superiores
pdf.circle(x + borderRadius, y + borderRadius, borderRadius, "S");
pdf.circle(x + width - borderRadius, y + borderRadius, borderRadius, "S");

// Desenhando os arcos para os cantos inferiores
pdf.circle(x + borderRadius, y + height - borderRadius, borderRadius, "S");
pdf.circle(x + width - borderRadius, y + height - borderRadius, borderRadius, "S");

  return pdf;
}


function getType(transaction: string): string {
  if (transaction === "pix") {
    return "Pix";
  }

  if (transaction === "ted") {
    return "Ted";
  }

  if (transaction === "P2P") {
    return "Transferência entre contas";
  }

  if (transaction === "bank_slips") {
    return "Boleto";
  }

  if (transaction === "payment") {
    return "Pagamento de conta";
  }

  if (transaction === "internal") {
    return "Transação interna";
  }

  if (transaction === "initial") {
    return "";
  }

  return transaction;
}

export const extractTransactionsPDF = (transaction: any) => {
  const pdf = createNewPDF(); // Crie um novo PDF a cada vez
  console.log(transaction);

 

  extractTransactionsIn(pdf, transaction);

 
};

export const extractTransactionsIn = (pdf: jsPDF, data: any) => {
 
 
  pdf.output("dataurlnewwindow")




};

*/
}
