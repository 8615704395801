import { FC } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";

import IdentificationSVG from "../../assets/IdentificationSVG.svg";

export const Identification: FC = () => {
  const location = useLocation();

  const { accountType } = location.state

  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background max-2xl:gap-5">
      <PhoneFrame onPress={() => navigate('/create-account-wallet/adding-secure', { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="750" height="90vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#AA7339" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="700"
            height="85vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[15vh] ml-28 flex flex-col justify-center items-center max-2xl:top-[10vh]">
          <h1 className="text-white text-3xl font-bold mb-7 max-2xl:text-xl max-2xl:mb-3">Identifique-se</h1>
          <p className="text-white text-lg text-center font-extralight mb-7 max-2xl:mb-3 max-2xl:text-base">
            Hora da selfie, o ambiente deve está bem <br />
            iluminado de forma moderada. Dê <br />
            preferência á iluminação natural, centralize <br />o rosto e evite sombras em seu rosto.
          </p>
          <img className="mb-4 max-2xl:w-[45%] max-2xl:h-[45%]" src={IdentificationSVG} alt="" />

          <div className="flex gap-4">
            <GradientButton
              title="Voltar"
              tailwindStyles="bg-[#171717] text-white w-[15rem] h-12"
              onClick={() => navigate('/create-account-wallet/adding-secure', { state: { accountType } })}
            />
            <GradientButton
              title="Avançar"
              tailwindStyles="bg-[#AA7339] text-white w-[15rem] h-12"
              onClick={() => navigate("/create-account-wallet/send-identification-photo", { state: { accountType } })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
