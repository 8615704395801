import { IconProps } from "src/interface/iconProps";

export const PixIcon = ({ className }: IconProps) => {
	return (
		<svg
			viewBox="0 0 26 26"
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.8482 19.7442C19.3572 19.7453 18.8707 19.6491 18.417 19.4613C17.9633 19.2734 17.5512 18.9976 17.2046 18.6497L13.3864 14.8315C13.2513 14.7031 13.0719 14.6315 12.8855 14.6315C12.6991 14.6315 12.5198 14.7031 12.3846 14.8315L8.55188 18.6642C8.20541 19.0123 7.79339 19.2882 7.33962 19.4761C6.88586 19.664 6.39936 19.7601 5.90824 19.7588H5.15552L9.99188 24.5951C11.501 26.1042 13.9501 26.1042 15.4592 24.5951L20.3082 19.7442H19.8482ZM5.90824 6.23877C6.90824 6.23877 7.84643 6.62786 8.55188 7.33331L12.3846 11.166C12.4505 11.232 12.5287 11.2843 12.6148 11.3201C12.7009 11.3558 12.7932 11.3741 12.8864 11.3741C12.9796 11.3741 13.0719 11.3558 13.1581 11.3201C13.2442 11.2843 13.3224 11.232 13.3882 11.166L17.2064 7.34786C17.5527 7 17.9644 6.72418 18.4178 6.53632C18.8713 6.34845 19.3574 6.25227 19.8482 6.25331H20.3082L15.4592 1.40422C14.734 0.67954 13.7507 0.272461 12.7255 0.272461C11.7003 0.272461 10.7171 0.67954 9.99188 1.40422L5.15552 6.24058L5.90824 6.23877Z"
				fill="#1E1E1E"
			/>
			<path
				d="M24.3209 10.2644L21.39 7.33353C21.3242 7.36055 21.2539 7.37474 21.1827 7.37535H19.85C19.1609 7.37535 18.4864 7.65535 18.0009 8.14262L14.1827 11.9608C14.0129 12.1315 13.8111 12.2669 13.5887 12.3594C13.3664 12.4518 13.128 12.4994 12.8873 12.4994C12.6465 12.4994 12.4081 12.4518 12.1858 12.3594C11.9635 12.2669 11.7616 12.1315 11.5918 11.9608L7.75909 8.12626C7.26736 7.63655 6.60216 7.36079 5.90818 7.35898H4.27182C4.20398 7.35844 4.13682 7.3455 4.07364 7.3208L1.13182 10.2644C-0.377273 11.7735 -0.377273 14.2226 1.13182 15.7335L4.07364 18.6753C4.13612 18.6502 4.20266 18.6366 4.27 18.6353H5.90818C6.59909 18.6353 7.27182 18.3572 7.75909 17.8699L11.59 14.0353C11.9396 13.7025 12.4037 13.5169 12.8864 13.5169C13.369 13.5169 13.8332 13.7025 14.1827 14.0353L18.0009 17.8535C18.4864 18.3408 19.1609 18.619 19.85 18.619H21.1827C21.2555 18.619 21.3264 18.6372 21.39 18.6626L24.3209 15.7317C25.83 14.2226 25.83 11.7735 24.3209 10.2644Z"
				fill="#1E1E1E"
			/>
		</svg>
	);
};
