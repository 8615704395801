/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/solid";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { api } from "../../../services/api";
import { currencyFormat } from "../../../utils/currencyFormat";
import { documentFormatted } from "../../../utils/documentFormatted";
import ToggleSwitchGestor from "src/components/backoffices/toogle";
import ToggleSwitchBlocked from "src/components/backoffices/toogle-blocked-password";
import { reduceAccountsBalance } from "src/utils/reduceAccountsBalance";

export interface AccountProps {
  id: string;
  clientId: string;
  // name: string;
  document: string;
  email: string;
  monthlyIncome: number;
  publiclyExposedPerson: number;
  birthDate: string;
  type: string;
  accountKey?: string;
  bank: any;
  bankBranch: any;
  bankAccount: any;
  bankAccountDigit: any;
  status: string;
  balance: number;
  accountStatus: number;
  accountConditionType: number;
  originalBalance: number;
  feePixIn: number;
  feePixOut: number;
  feeTedOut: number;
  feeP2pOut: number;
  feeP2pIn: number;
  feeBillet: number;
  havePermission: boolean;
  phone: string;
  createdAt: string;
  updatedAt: string;
  account_number: number;
  gestor_graphic: boolean;
}

export interface MetaProps {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

export function AccountsWallet() {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState<any>([]);
  const [meta, setMeta] = useState<MetaProps>({} as MetaProps);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [balance, setBalance] = useState(0);

  const token = localStorage.getItem("@backoffice:token");

  const perPage = 20;
  console.log("RESPONSE DO 05:");

  const loadAccount = async () => {
    try {
      const response = await api.get(
        `/backoffice/accounts?page=${currentPage}&role=WALLET&perPage=${perPage}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      setAccounts(response.data.accounts.data);
      setMeta({
        total_items: response.data.accounts.total,
      } as any);

      const bl = response.data.accounts.data.reduce(
        (acc: number, item: any) => {
          acc += item.balance;
          return acc;
        },
        0
      );
      console.log(bl);

      setBalance(bl);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    (async () => {
      await loadAccount();
      setLoading(false);
    })();
  }, [currentPage]);

  const totalPages = Math.ceil(meta.total_items / perPage);

  function getStatus(status: string) {
    if (!status) {
      return { label: "Em análise", color: "green" };
    }
    const statusMapper = {
      active: { label: "ATIVO", color: "green" },
      ACTIVE: { label: "ATIVO", color: "green" },
      BLOCKED: { label: "Bloqueado", color: "red" },
      EXPIRED: { label: "EXPIRADO", color: "red" },
      WAITING_ANALYSIS: { label: "Aguardando análise", color: "blue" },
      UNDER_ANALYSIS: { label: "Em análise", color: "blue" },
      WAITING_DOCUMENTS: { label: "Aguardando documentos", color: "yellow" },
      WAITING_ADDRESS: { label: "Aguardando endereço", color: "gray" },
      DENIED: { label: "Recusado", color: "black" },
      default: { label: status, color: "green" },
    };

    return (
      (statusMapper as any)[status.toLocaleUpperCase()] ?? statusMapper.default
    );
  }

  async function handleFilter() {
    setLoading(true);
    await loadAccount();
    setLoading(false);
  }

  function handlePrevious() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function handleNext() {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  const totalBalance = reduceAccountsBalance(accounts);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Contas</h1>
          <h2 className="mt-2">Saldo total: {currencyFormat(totalBalance)}</h2>
        </div>

        <div className="sm:flex sm:items-center">
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <SearchIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              name="search"
              id="search"
              className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              placeholder="Pesquisar"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="mt-2 ml-2 sm:mt-0 sm-16 sm:flex-none">
            <button
              type="button"
              onClick={handleFilter}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <Skeleton isActive={loading} repeat={4} space={5} />
              {!loading && (
                <>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Nome
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Número identificador
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          E-mail
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Saldo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th> */}

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Senha Bloqueada
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {accounts.map((account: any) => (
                        <tr key={account.id}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              {/* <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                          </div> */}
                              <div className="ml-4 w-64">
                                <div className="font-medium text-gray-900 truncate">
                                  {account.name}
                                </div>
                                <div className="text-gray-500">
                                  {documentFormatted(account.document)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {account.number_identifier}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">{account.email}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {currencyFormat(account.balance)}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={`inline-flex rounded-full bg-${
                                getStatus(account.status).color
                              }-100 px-2 text-xs font-semibold leading-5 text-${
                                getStatus(account.status).color
                              }-800`}
                            >
                              {getStatus(account.status).label}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div>
                              <ToggleSwitchBlocked
                                userId={account.id}
                                blocked={account.blocked}
                              />
                            </div>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link
                              to={`/backoffice/accounts/wallet/${account.id}`}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Visualizar
                              <span className="sr-only">
                                , {account.name}
                                {console.log(
                                  "name do account user name",
                                  account.name
                                )}
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        {/* Mostrando de{' '}<span className="font-medium">1</span> a <span className="font-medium">{(meta.current_page + 1) * meta.total_items_page}</span> de{' '}
          <span className="font-medium">{meta.total_items}</span> resultados */}
                        Total de {meta.total_items} contas
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end gap-x-2">
                      <span
                        onClick={handlePrevious}
                        className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Anterior
                      </span>
                      <span className="mt-2">
                        {currentPage} / {totalPages}
                      </span>
                      <span
                        onClick={handleNext}
                        className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Próximo
                      </span>
                    </div>
                  </nav>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
