import { useEffect } from "react";
import type { NewPixFormProps } from ".";
import transactionSpinner from "../../../../../assets/transactionSpinner.svg";

interface ProcessingTransactionStepProps extends NewPixFormProps {}

export function ProcessingTransactionStep({
	nextStep,
}: ProcessingTransactionStepProps) {
	useEffect(() => {
		setTimeout(() => {
			nextStep();
		}, 3000);
	}, [nextStep]);

	return (
		<div className="flex flex-col gap-[1.875rem] w-[570px] h-[650px] items-center justify-center px-12 py-20 drop-shadow-md rounded-[1.875rem]">
			<p className="text-[1.875rem] w-64 text-center font-semibold">
				Aguarde, estamos processando a sua transação!
			</p>
			<img
				src={transactionSpinner}
				alt="spinner"
				className="animate-spin transition"
			/>
		</div>
	);
}
