import React, { FormEvent, Fragment, useEffect, useState, FC } from "react";
import { Skeleton } from "../../../components/skeleton";
import { api } from "../../../services/api";
import { Transition } from "@headlessui/react";
import {
  Container,
  Content,
  QrContainer,
  Table,
  TableWrapper,
  LeftDiv,
  RightDiv,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { service } from "../../../services/ajio";
import axios from "axios"; // Importe o axios se ainda não estiver importado
import { useAuth } from "../../../hooks/auth";
import { classNames } from "./base-limits-config-layout";
import IntlCurrencyInput from "react-intl-currency-input";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import bgLogoLeft from "../../../assets/clients/vilapaybank/bg-div-left.png";
import configLogoLimits from "../../../assets/settings-2.png";
import { GraphicAccount } from "../graphic-account.interface";

type AccountProps = {
  id: string;
  name: string;
  balance: number;
  document: string;
  email: string;
  monthly_invoicing: number;
  phone?: {
    ddd: string;
    number: string;
  };
  address?: {
    state: string; //only the UF code
    publicPlace: string;
    number: string;
    zipCode: string; //only numbers
    neighborhood: string;
    complement?: string;
    isConfirmed: boolean;
    type: string;
    cityId?: number;
    cityName?: string;
  };
};

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export interface Transaction {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  // type: string;
  credit: boolean;
  // amount: number;
  //

  createdAt: string;
  category: string;
  amount: string;
  type: string;
  transactionData: {
    clientNamePayer: string;
    clientName: string;
    keyTarget: string;
  };
}

interface IItems {
  [x: string]: any;
  id: string;
  transactionId: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  // type: string;
  credit: boolean;
  // amount: number;
  //

  category: string;
  amount: string;
  type: string;
  createdAt: string;
  transactionData: any;
}

export interface ExtractItem {
  id: string;
  direction: "in" | "out";
  description: string;
  amount: number;
  created_at: string;
  operation: string;
  title: string;
}

type AddressProps = {
  city: string;
  complement: string;
  date: string;
  id: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
};

type DocumentsProps = {
  id: string;
  originalName: string;
  name: string;
  status: string;
  type: string;
  date: string;
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const MyConfigLimits = () => {
  // const token = localStorage.getItem('@stricv2:token');
  const navigate = useNavigate();
  //const { setOpenNotification } = useNotification();
  const [key, setKey] = useState<KeysProps>({} as KeysProps);
  const [keys, setKeys] = useState<KeysProps[]>([]);
  const [amount, setAmount] = useState<number>(0.0);
  const [qrImage, setQrImage] = useState<string>("");
  const [isEmvData, setEmvData] = useState<string>("");

  const [maskedValue, setMaskedValue] = useState("");
  const [value, setValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [keyOptions, setKeyOptions] = useState<string[]>([]);
  const { signIn, user } = useAuth();

  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [, setAccount] = useState<AccountProps>({} as AccountProps);
  const [, setAddress] = useState<AddressProps>({} as AddressProps);
  const [, setDocuments] = useState<DocumentsProps[]>([] as DocumentsProps[]);

  const [graphicAccount, setGraphicAccount] = useState<GraphicAccount>();
  const [availableFeeBillet, setAvailableFeeBillet] = useState(1000);
  const [availableFeeP2pIn, setAvailableFeeP2pIn] = useState(1000);
  const [availableFeeP2pOut, setAvailableFeeP2pOut] = useState(1000);
  const [availableFeePixIn, setAvailableFeePixIn] = useState(1000);
  const [availableFeePixOut, setAvailableFeePixOut] = useState(1000);
  const [availableFeeTedOut, setAvailableFeeTedOut] = useState(1000);
  const [availableLimitDay, setAvailableLimitDay] = useState(1000);
  const [availableLimitNightly, setAvailableLimitNightly] = useState(1000);
  const [availableLimitMonth, setAvailableLimitMonth] = useState(1000);
  const [errorMessage, setErrorMessage] = useState(null);
  const [feeBillet, setFeeBillet] = useState(0);
  const [feeP2pIn, setFeeP2pIn] = useState(0);
  const [feeP2pOut, setFeeP2pOut] = useState(0);
  const [feePixIn, setFeePixIn] = useState(0);
  const [feePixOut, setFeePixOut] = useState(0);
  const [feeTedOut, setFeeTedOut] = useState(0);
  const [limitDay, setLimitDay] = useState(0);
  const [limitNightly, setLimitNightly] = useState(0);

  const [limitMonth, setLimitMonth] = useState(0);

  const [show, setShow] = useState(false);

  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);

  const token = window.localStorage.getItem("@stricv2:token");

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .get(`/graphic/fee-limits`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setGraphicAccount(data);
        console.log(graphicAccount);
        if (data && data.feeLimits.length > 0) {
          const feeLimitsData = data.feeLimits[0];
          setFeeBillet(feeLimitsData.feeBillet);
          setFeeP2pIn(feeLimitsData.feeP2pIn);
          setFeeP2pOut(feeLimitsData.feeP2pOut);
          setFeePixIn(feeLimitsData.feePixIn);
          setFeePixOut(feeLimitsData.feePixOut);
          setFeeTedOut(feeLimitsData.feeTedOut);
          setLimitDay(feeLimitsData.limitDay);
          setLimitNightly(feeLimitsData.limitNightly);
          setLimitMonth(feeLimitsData.limitMonth);

          setAvailableFeeBillet(feeLimitsData.availableFeeBillet);
          setAvailableFeeP2pIn(feeLimitsData.availableFeeP2pIn);
          setAvailableFeeP2pOut(feeLimitsData.availableFeeP2pOut);
          setAvailableFeePixIn(feeLimitsData.availableFeePixIn);
          setAvailableFeePixOut(feeLimitsData.availableFeePixOut);
          setAvailableFeeTedOut(feeLimitsData.availableFeeTedOut);
          setAvailableLimitDay(feeLimitsData.availableLimitDay);
          setAvailableLimitNightly(feeLimitsData.availableLimitNightly);
          setAvailableLimitMonth(feeLimitsData.availableLimitMonth);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id, token]);

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  const handleAmountChange = (newValue: number) => {
    if (!isNaN(newValue)) {
      setAmount(newValue);
    }
  };

  const handleOpenDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const handleCloseDownloadModal = () => {
    setErrorMessage(null);
    setIsDownloadModalOpen(false);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.value);
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const userId = user.id;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post(
        "/graphic/fee-limits/config/limit",
        {
          availableFeePixIn,
          availableFeePixOut,
          availableFeeTedOut,
          availableFeeP2pIn,
          availableFeeP2pOut,
          availableFeeBillet,
          availableLimitDay,
          availableLimitNightly,
          availableLimitMonth,
          status: "waiting",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      handleCloseDownloadModal();
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const inputWidth = `${Math.max(100, inputValue.length * 8)}px`;
  const width = inputValue === "" ? "300px" : inputWidth;

  function formatCurrency(value: any) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  return (
    <Container>
      <Content>
        <Table>
          <TableWrapper>
            <LeftDiv>
              <img src={bgLogoLeft} alt={"bank"} />
            </LeftDiv>

            <RightDiv>
              <QrContainer>
                <div
                  className={classNames(
                    "space-y-6 sm:px-6 lg:px-0 w-ful",
                    "lg:col-span-10"
                  )}
                >
                  <section aria-labelledby="payment-details-heading">
                    <>
                      <form>
                        <div className="sm:overflow-hidden mt-10">
                          <div className="bg-gray py-6 px-4 sm:p-6">
                            <Skeleton isActive={loading} repeat={3} />
                            {!loading && (
                              <>
                                <div>
                                  <h2
                                    id="payment-details-heading"
                                    className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                                  >
                                    Gerencie seus limites:
                                  </h2>
                                  <p className="mt-1 text-sm text-gray-500"></p>
                                </div>

                                <div className="mt-6 grid grid-cols-6 gap-6">
                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Boleto
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableFeeBillet}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableFeeBillet(value)}
                                    />
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: {formatCurrency(feeBillet)})
                                    </label>
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      TED
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableFeeTedOut}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableFeeTedOut(value)}
                                    />
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: {formatCurrency(feeTedOut)})
                                    </label>
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      P2P Entrada (Entre contas)
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableFeeP2pIn}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableFeeP2pIn(value)}
                                    />

                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: Ilimitado)
                                    </label>
                                  </div>
                                </div>

                                <div className="mt-6 grid grid-cols-6 gap-6">
                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      P2P Saída (Entre contas)
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableFeeP2pOut}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableFeeP2pOut(value)}
                                    />

                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: {formatCurrency(feeP2pOut)})
                                    </label>
                                  </div>

                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Pix (Entrada)
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableFeePixIn}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableFeePixIn(value)}
                                    />

                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: Ilimitado)
                                    </label>
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Pix (Saída)
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableFeePixOut}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableFeePixOut(value)}
                                    />

                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: {formatCurrency(feePixOut)})
                                    </label>
                                  </div>
                                </div>

                                <div className="mt-5">
                                  <h2
                                    id="payment-details-heading"
                                    className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                                  >
                                    Meus limites operacionais:
                                  </h2>
                                </div>

                                <div className="mt-6 grid grid-cols-6 gap-6">
                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Limite diurno
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableLimitDay}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableLimitDay(value)}
                                    />

                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: {formatCurrency(limitDay)})
                                    </label>
                                  </div>

                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Limite noturno
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableLimitNightly}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableLimitNightly(value)}
                                    />
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: {formatCurrency(limitNightly)})
                                    </label>
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Limite mensal
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={availableLimitMonth}
                                      onChange={(
                                        _event: FormEvent,
                                        value: number
                                      ) => setAvailableLimitMonth(value)}
                                    />
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      (max: {formatCurrency(limitMonth)})
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </form>

                      <div
                        aria-live="assertive"
                        className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
                      >
                        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                          <Transition
                            show={show}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="p-4">
                                <div className="flex items-start">
                                  <div className="flex-shrink-0">
                                    <CheckCircleIcon
                                      className="h-6 w-6 text-green-400"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">
                                      Configurações salvar
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                      As configurações de taxas foram alteradas.
                                    </p>
                                  </div>
                                  <div className="ml-4 flex-shrink-0 flex">
                                    <button
                                      type="button"
                                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      onClick={() => {
                                        setShow(false);
                                      }}
                                    >
                                      <span className="sr-only">Close</span>
                                      <XIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </div>
                    </>
                  </section>
                  <div
                    className={classNames(
                      "space-y-6 sm:px-6 lg:px-0 w-ful",
                      "lg:col-span-10"
                    )}
                  >
                    <div className="shadow sm:rounded-3xl sm:overflow-hidden mt-8">
                      <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70">
                        <img
                          src={configLogoLimits}
                          alt={"bank"}
                          className="mr-4 w-16 h-16"
                        />
                        <div>
                          <p className="mt-1 text-sm text-gray-500">
                            Aproveite a conveniência de gerenciar seus limites
                            de transação com facilidade. Priorize sua segurança.
                            <br />
                            Basta preencher os valores desejados no formulário
                            acima e clicar no botão de confirmação abaixo.
                            <br /> É rápido e fácil aumentar ou diminuir seu
                            limite por segurança.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray flex flex-col items-center py-2 px-4 sm:p-6 mx-auto w-96">
                      {!loading && (
                        <div className="flex justify-center">
                          {/*<button
        type="submit"
        className="bg-black text-white border border-transparent rounded-md shadow-sm py-3 px-8 inline-flex justify-center text-sm font-medium hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
      >
        Cancelar
  </button>*/}

                          <button
                            onClick={handleOpenDownloadModal}
                            type="submit"
                            className="bg-amber-700 border border-transparent rounded-md shadow-sm py-3 px-8 inline-flex justify-center text-sm font-medium text-white hover:bg-amber-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ml-4"
                          >
                            Alterar meu Limites
                          </button>
                          {isDownloadModalOpen && (
                            <div
                              className="modal"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <div
                                className="modal-content"
                                id="download-modal"
                                style={{
                                  backgroundColor: "#fefefe",
                                  padding: "20px",
                                  borderRadius: "10px",
                                  maxWidth: "400px",
                                }}
                              >
                                <span
                                  className="close"
                                  onClick={handleCloseDownloadModal}
                                  style={{ float: "right", cursor: "pointer" }}
                                >
                                  &times;
                                </span>
                                <p
                                  className="text-center mb-4 mx-4"
                                  style={{
                                    color: "#5D5D5D",
                                    height: "31.42px",
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: "26.18px",
                                  }}
                                >
                                  Deseja confirmar?
                                </p>
                                {errorMessage !== null && (
                                  <div
                                    className="bg-red-100 border border-red-400 m-3 text-red-700 px-4 py-3 rounded relative"
                                    role="alert"
                                  >
                                    <p>{errorMessage}</p>
                                  </div>
                                )}
                                <button
                                  style={{
                                    background: "#AA7339",
                                    borderRadius: "20px",
                                  }}
                                  className="w-full px-10 py-4 mb-4 text-white"
                                  onClick={handleSubmit}
                                >
                                  Sim
                                </button>

                                <button
                                  onClick={handleCloseDownloadModal}
                                  style={{ borderRadius: "20px" }}
                                  className="w-full px-10 py-4 bg-red-500 rounded text-white"
                                >
                                  Não
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </QrContainer>
            </RightDiv>
          </TableWrapper>
        </Table>
      </Content>
    </Container>
  );
};

const PropDetail: FC<{
  title: string;
  value: string | number;
  cols?: number;
}> = ({ title, value, cols = 4 }) => {
  return (
    <div
      style={{ gridColumn: `span ${cols} / span ${cols}` }}
      className="sm:col-span-2"
    >
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      <input
        type="text"
        name="name"
        id="name"
        disabled
        value={value}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
      />
    </div>
  );
};
