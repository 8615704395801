// ! Mexer neste Arquivo

import { Outlet } from "react-router-dom";
import { DashboardHeader } from "src/components/headerr/header";
import { SocketContextProvider } from "src/hooks/SocketContext";

export function Layout() {
	return (
		<SocketContextProvider>
			<section className="grid grid-rows-[80px_1fr] min-h-screen w-full bg-white">
				<DashboardHeader />
				<section className="flex items-start justify-center">
					<Outlet />
				</section>
			</section>
		</SocketContextProvider>
	);
}
