import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

import { CalendarSearch, Eye } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalConfirmOperation from "../../components/ModalConfirmOperation";
import { Skeleton } from "../../components/skeleton";
import { service as api } from "../../services/ajio";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormatDaily } from "../../utils/dateFormat";
import type { ExtractItem } from "../../utils/extractDescription";
import {
  Container,
  Content,
  PrintButtonIcon,
  Table,
  TableWrapper,
} from "./styles";
import "./style.css";
import { useAuth } from "src/hooks/auth";
import handleExportExcelMember from "src/utils/excel/member";
import handleExportExcelWallet from "src/utils/excel/wallet";
import { ExportPDF_V2 } from "../backoffice/Export/pdf";

import type { PaginationProps } from "antd";
import { Button, Flex, Pagination } from "antd";
import DatePicker from "react-datepicker";
import { formatTransactionType } from "src/utils/formatTransactionType";
import "react-datepicker/dist/react-datepicker.css";
import { separateTransactions } from "src/utils/separateTransactions";

export interface Transaction {
  id: string;
  description: string;
  balanceAfter: number;
  created_at: string;
  status: string;
  credit: boolean;
  direction: string;
  data: any;
  GraphicAccount: any;
  createdAt: string;
  category: string;
  amount: number;
  type: string;
  transactionData: {
    clientNamePayer: string;
    clientName: string;
    keyTarget: string;
  };
}

export interface Detail {
  id: string;
  credit: boolean;
  amount: number;
  description: string;
  rateValue: number;
  consignorName: string;
  consignorDocument: string;
  consignorBankNumber: string;
  consignorBankBranch: string;
  consignorBankAccount: string;
  consignorBankAccountDigit: string;
  beneficiaryName: string;
  beneficiaryDocument: string;
  beneficiaryBankNumber: string;
  beneficiaryBankBranch: string;
  beneficiaryBankAccount: string;
  beneficiaryBankAccountDigit: string;
  status: string;
  date: string;
}

export interface ShowTransaction {
  category: string;
  transactionData: any;
  detail: any;
  transaction: any;
  createdAt: string;
  amount: string;
}

type TransactionData = {
  id: string;
  type: string;
  grossAmount: number;
  netAmount: number;
  taxTotal: number;
  isAwaitingTransfer: boolean;
  installments: number;
  machineId: string;
  machineSerial: string;
  transactionId: string;
  created_at: string;
  updated_at: string;
  status: number;
};

export const Receipt = ({ itemsPerPage = 10 }) => {
  const navigate = useNavigate();
  const { account } = useAuth();
  const token = localStorage.getItem("@stricv2:token") as string;
  const user = JSON.parse(localStorage.getItem("@stricv2:user") as string);

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ExtractItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<ExtractItem>();

  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);

  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [isExportExcel, setIsExportExcel] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [endDate, setEndDate] = useState<any>(new Date());
  const [startDate, setStartDate] = useState<any>(new Date());

  const handleRowClick = (transaction: TransactionData) => {
    navigate(`/transactions/${transaction.transactionId}`, {
      state: { transactionData: transaction },
    });
  };

  useEffect(() => {
    fetchCustomDateTransactions();
  }, []);

  const fetchCustomDateTransactions = () => {
    setLoading(true);

    api
      .get(`/transactions?start=${startDate}&end=${endDate}`)
      .then((res) => {
        const transactions = res.data.transactions.data as ExtractItem[];
        const { orderedTransactions } = separateTransactions(transactions);

        console.log(orderedTransactions);
        setItems(orderedTransactions);
        setTotal(res.data.transactions.total);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar transações:", error);
        setLoading(false);
      });
  };
  const [modalIsOpen, setIsOpen] = useState(false);

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClickExportPDF = () => {
    handleExport("pdf");
  };

  const handleClickExportExcel = () => {
    handleExport("excel");
  };

  const fetchExportTransactions = async () => {
    try {
      const response = await api.get(
        `/transactions?start=${startDate}&end=${endDate}`,
      );
      return response.data.transactions.data;
    } catch (err) {
      console.log(err);
    }
  };

  async function handleExport(type: string) {
    if (type === "pdf") {
      setIsExportingPdf(true);
      const params = {
        title: "Financeiro",
        startDate: startDate,
        endDate: endDate,
        currentBalance: account?.balance || 0,
        date: `${new Date().toLocaleDateString()}, ${new Date().toLocaleTimeString()}`,
      };
      const transaction: ExtractItem[] = await fetchExportTransactions();
      ExportPDF_V2(transaction, params).finally(() => {
        setIsExportingPdf(false);
      });
    } else if (type === "excel") {
      setIsExportExcel(true);

      const type =
        user.role == "WALLET" || user.role == "GRAPHIC" ? "GRAPHIC" : "MEMBER";

      const role = user.role;

      if (role == "WALLET") {
        const transaction: ExtractItem[] = await fetchExportTransactions();
        handleExportExcelWallet(
          transaction,
          {
            balance: user?.balance || account?.balance,
            document: user?.document,
            name: user.name,
            number: account.number_identifier,
          },
          true,
        ).finally(() => {
          setIsExportExcel(false);
        });
      } else {
        handleExportExcelMember(
          items,
          {
            number: account.account_number,
            branch: account.branch_number,
            document: user?.document,
            name: user?.name,
            balance: account?.balance,
          },
          true,
        ).finally(() => {
          setIsExportExcel(false);
        });
      }
    }
  }

  function handleClickRow(item: ExtractItem) {
    setSelectedItem(item);
  }

  const handleCloseDownloadModal = () => {
    setIsDownloadModalOpen(false);
    fetchCustomDateTransactions();
  };

  useEffect(() => {
    if (process.env.NODE_ENV == "development") {
      console.log("Transacao selecionada", selectedItem);
    }
  }, [selectedItem]);

  const href = window.location.href;

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize,
  ) => {
    setPerPage(pageSize);
  };

  const startIdx = (page - 1) * perPage;
  const endIdx = startIdx + perPage;
  const paginatedItems = items.slice(startIdx, endIdx);

  return (
    <>
      <ModalConfirmOperation
        user={user}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
        data={selectedItem}
      />
      <Container style={{ overflow: "hidden" }}>
        <div
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            paddingTop: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Flex align="center" gap={10} style={{ width: "max-content" }}>
            <DatePicker
              clearButtonTitle="Limpar"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              maxDate={endDate}
              onChange={(date) => setStartDate(date)}
            />
            <span>Até</span>
            <DatePicker
              selected={endDate}
              clearButtonTitle="Limpar"
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              onChange={(date) => setEndDate(date)}
            />
            <Button
              style={{
                color: "white",
                background: "#AA7339",
                width: "100%",
              }}
              onClick={fetchCustomDateTransactions}
            >
              Filtrar
            </Button>
          </Flex>
          <Flex align="center" gap={5} style={{ width: "max-content" }}>
            <Button
              onClick={handleClickExportPDF}
              style={{
                color: "white",
                background: "#AA7339",
                width: "100%",
                opacity: items.length === 0 ? 0.7 : 1,
                cursor: items.length === 0 ? "not-allowed" : "pointer",
              }}
              disabled={items.length === 0}
            >
              {isExportingPdf ? "Exportando..." : "Exportar extrato PDF"}
            </Button>

            <Button
              onClick={handleClickExportExcel}
              style={{
                color: "white",
                background: "#AA7339",
                width: "100%",
                opacity: items.length === 0 ? 0.7 : 1,
                cursor: items.length === 0 ? "not-allowed" : "pointer",
              }}
              disabled={items.length === 0}
            >
              {isExportExcel ? "Exportando..." : "Exportar extrato Excel"}
            </Button>
          </Flex>
        </div>
        <Content>
          <Table>
            <TableWrapper>
              <Skeleton space={4} isActive={loading} repeat={5} />
              <table>
                <tbody>
                  {!loading && (
                    <tr className="text-left font-semibold rounded-t-lg">
                      <th>Data</th>
                      <th>Tipo</th>
                      <th>Transação</th>
                      <th>Beneficiário/Pagador</th>
                      <th style={{ paddingLeft: "40px" }}>Valor</th>
                      <th style={{ paddingLeft: "15px" }}>Comprovante</th>
                    </tr>
                  )}
                  {items &&
                    paginatedItems.map((item) => {
                      return (
                        <tr key={item.id} onClick={() => handleClickRow(item)}>
                          <td>{dateFormatDaily(item.created_at)}</td>

                          <td>
                            <span>
                              {item.direction === "in" ? "Credito" : "Debito"}
                            </span>
                          </td>

                          <td>
                            {item.GraphicAccount &&
                              item.GraphicAccount?.name ? (
                              <>
                                <span className="extract-graphic-account">
                                  Control Account - {item.GraphicAccount.name}
                                </span>
                              </>
                            ) : (
                              <span className="extract-graphic-account">
                                {item.user?.name}
                              </span>
                            )}
                            <br />
                            <span
                              style={{
                                color:
                                  item.type === "withdraw" &&
                                    user?.id === item?.data?.payer?.id
                                    ? "#ff0013"
                                    : item.direction === "in" &&
                                      item.type !== "internal"
                                      ? "var(--green)"
                                      : item.direction === "out" &&
                                        item.status === "denied"
                                        ? "#ff0013"
                                        : "black",
                              }}
                            >
                              {formatTransactionType(item, user)}
                            </span>{" "}
                            <br />
                            {item.type === "withdraw"
                              ? "Transação interna"
                              : item.description}
                          </td>
                          {item.response === null &&
                            item.title === "Retirada" ? (
                            <>
                              <span style={{ color: "darkgray" }}>C-A</span>
                              <br />
                              {item?.data?.payer?.name}
                              <span>
                                <br />
                                {item.description === "Retirada"
                                  ? "Saldo devolvido para a conta principal."
                                  : item.GraphicAccount}
                              </span>
                            </>
                          ) : (
                            <>
                              <br />
                              <span className="extract-graphic-account">
                                {item.direction == "in"
                                  ? `De: ${item?.response?.payer?.holder?.name ||
                                  item?.response?.proof?.payer?.holder
                                    ?.name ||
                                  item?.data?.payer?.name ||
                                  item?.GraphicAccount?.name ||
                                  item?.response?.payer?.name ||
                                  item?.data?.payer?.holder.name ||
                                  item?.GraphicAccount?.name ||
                                  item?.response?.payer?.name ||
                                  item?.beneficiary.holder.name ||
                                  item?.beneficiary.name ||
                                  item?.data.beneficiary.holder.name ||
                                  item?.data.proof.beneficiary.holder
                                    .name ||

                                  "*sem nome*"
                                  }`
                                  : item.type == "INTERNAL_TAX" || "INTERNAL_WALLET_TRANSACTION"
                                    ? item.type !== "INTERNAL_TAX"
                                      ? `Para: ${item.response?.receiver?.name}` ?? `Para: ${item.response?.beneficiary?.name}`
                                      : "Para: VilaPay Bank"
                                    : //@ts-ignore
                                    `Para: ${item?.response?.beneficiary?.holder
                                      ?.name ||
                                    item?.response?.proof?.beneficiary
                                      ?.holder?.name ||
                                    item?.response?.beneficiary?.name ||
                                    item?.data?.beneficiary?.name ||
                                    "*sem nome*"
                                    }`}
                              </span>
                            </>
                          )}
                          <td>
                            <span
                              style={{
                                color:
                                  item.type === "withdraw" &&
                                    user?.id === item?.data?.payer?.id
                                    ? "var(--red)"
                                    : item.type == "internal"
                                      ? item?.data?.payer?.id == user?.id
                                        ? "var(--red)"
                                        : "var(--green)"
                                      : item.direction === "in"
                                        ? "var(--green)"
                                        : item.direction === "out" &&
                                          item.status === "denied"
                                          ? "#606060"
                                          : "var(--red)",
                                marginLeft: "8px",
                              }}
                            >
                              {item.type === "withdraw" &&
                                user?.id === item?.data?.payer?.id ? (
                                "-"
                              ) : item.direction === "out" &&
                                item.status === "denied" ? (
                                <>&nbsp;&nbsp;</>
                              ) : item.type == "internal" ? (
                                user?.id == item?.data?.payer?.id ? (
                                  "-"
                                ) : (
                                  "+"
                                )
                              ) : item.direction === "in" &&
                                item.status !== "denied" ? (
                                "+"
                              ) : (
                                "-"
                              )}
                              {currencyFormat(item.amount)}
                            </span>

                            <span
                              style={{
                                marginLeft: "20px",
                                display:
                                  item.direction === "in" ? "none" : "inline",
                              }}
                            ></span>
                          </td>

                          <td>
                            <PrintButtonIcon>
                              <Eye
                                size={20}
                                color="rgb(170, 170, 170)"
                                onClick={() => setIsOpen(true)}
                                // color="var(--boxText)"
                                className="h-4 w-4 inline"
                              />
                            </PrintButtonIcon>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {items.length === 0 && !loading && (
                  <td className="text-center py-4 w-full">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        Nenhum lançamento para o período selecionado
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">
                        Selecione outro período ou faça uma transferência e
                        comece a usar agora mesmo
                      </p>
                    </div>
                  </td>
                )}
              </table>
            </TableWrapper>
          </Table>
          {!loading && (
            <div
              className="flex flex-col w-full items-center"
              style={{ marginBottom: 20 }}
            >
              <Pagination
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={(e) => setPage(e)}
                defaultCurrent={page}
                total={total}
              />
            </div>
          )}
        </Content>
      </Container>
    </>
  );
};
