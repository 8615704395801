import { type VariantProps, cva } from "class-variance-authority";
import { motion } from "framer-motion";
import { createContext, forwardRef, useContext, useState } from "react";
import { CloseIcon } from "src/assets/icons/closeIcon";
import { cn } from "src/lib/utils";

interface ModalContextProps {
	isOpen: boolean;
	handleState: () => void;
}

const ModalContext = createContext<ModalContextProps>({
	isOpen: false,
	handleState() {},
});

export const ModalProvider = ({
	children,
	open,
	setIsOpen,
}: {
	children: React.ReactNode;
	open?: boolean;
	setIsOpen?: (value: boolean) => void;
}) => {
	const [isOpen, setIsOpenInternal] = useState<boolean>(false);

	const isControlled = open !== undefined && setIsOpen !== undefined;

	const handleState = () => {
		if (isControlled) {
			setIsOpen?.(!open);
		} else {
			setIsOpenInternal((prev) => !prev);
		}
	};

	return (
		<ModalContext.Provider
			value={{
				isOpen: isControlled ? open : isOpen,
				handleState,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

// _______________________root________________________________

const rootVariants = cva(
	"absolute z-[100] inset-0 flex flex-col gap-6 items-center justify-center",
	{
		variants: {
			variant: {
				default: "bg-transparent",
				overlay: "bg-black/50",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

interface ModalRootProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof rootVariants> {
	children?: React.ReactNode;
	open?: boolean;
	setIsOpen?: (value: React.SetStateAction<boolean>) => void;
	asChild?: boolean;
}

const ModalRoot = forwardRef<HTMLDivElement, ModalRootProps>(
	({ children, open, setIsOpen }) => {
		return (
			<ModalProvider open={open} setIsOpen={setIsOpen}>
				{children}
			</ModalProvider>
		);
	},
);

ModalRoot.displayName = "ModalRoot";

// _______________________trigger________________________________

const triggerVariants = cva("flex items-center gap-1 px-2 py-1", {
	variants: {
		variant: {
			default: "",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

interface ModalTriggerProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof triggerVariants> {
	asChild?: boolean;
}

const ModalTrigger = forwardRef<HTMLDivElement, ModalTriggerProps>(
	({ className, variant, ...props }, ref) => {
		const { handleState } = useContext(ModalContext);

		return (
			<div
				role="button"
				ref={ref}
				onClick={() => handleState()}
				className={cn(triggerVariants({ variant, className }))}
			>
				<div ref={ref} {...props} className="flex items-center gap-1 flex-1" />
			</div>
		);
	},
);

ModalTrigger.displayName = "ModalTrigger";

// _______________________container________________________________

const containerVariants = cva(
	"absolute z-[100] inset-0 flex flex-col gap-6 items-center justify-center",
	{
		variants: {
			variant: {
				default: "bg-transparent",
				overlay: "modal-overlay-background",
			},
		},
		defaultVariants: {
			variant: "overlay",
		},
	},
);

interface ModalContainerProps extends VariantProps<typeof containerVariants> {
	className?: string;
	children?: React.ReactNode;
	asChild?: boolean;
}

const ModalContainer = forwardRef<HTMLDivElement, ModalContainerProps>(
	({ className, children, variant, ...props }, ref) => {
		const { isOpen, handleState } = useContext(ModalContext);

		const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
			if (event.target === event.currentTarget) {
				handleState();
			}
		};

		if (isOpen) {
			return (
				<motion.div
					ref={ref}
					className={cn(containerVariants({ variant, className }))}
					onClick={handleClickOutside}
					{...props}
					initial={{ opacity: 0, scale: 0.9 }}
					animate={{ opacity: 1, scale: 1 }}
					exit={{ opacity: 0, scale: 0.9 }}
					transition={{ duration: 0.3 }}
				>
					{children}
				</motion.div>
			);
		}
		return null;
	},
);

ModalContainer.displayName = "ModalContainer";

// _______________________content________________________________

const contentVariants = cva(
	"relative z-[101] flex flex-col rounded-md items-start px-6 py-3 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]",
	{
		variants: {
			variant: {
				default: "bg-white",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

interface ModalContentProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof contentVariants> {
	asChild?: boolean;
}

const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
	({ className, variant, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn(contentVariants({ variant, className }))}
				{...props}
			/>
		);
	},
);

ModalContent.displayName = "ModalContent";

const closeVariants = cva("absolute right-1 top-1 cursor-pointer", {
	variants: {
		variant: {
			default: "",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

interface ModalCloseProps
	extends React.HTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof closeVariants> {}

const ModalClose = forwardRef<HTMLButtonElement, ModalCloseProps>(
	({ className, variant, ...props }, ref) => {
		const { handleState } = useContext(ModalContext);

		return (
			<button
				type="button"
				ref={ref}
				{...props}
				className={cn(closeVariants({ variant, className }))}
				onClick={() => handleState()}
			>
				<CloseIcon className="w-[15px] h-[15px]" />
			</button>
		);
	},
);

ModalClose.displayName = "ModalClose";

export { ModalRoot, ModalClose, ModalContainer, ModalContent, ModalTrigger };
