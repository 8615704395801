import styled from "styled-components";

import { ReactComponent as ArrowRight } from "../../assets/arrowRight.svg";

const breakpoints = {
	mobile: "480px",
	tablet: "768px",
	desktop: "1024px",
	hd: "1366px",
	fullHd: "1920px",
	fullHDPlus: "2000px",
	qhd: "2500px",
	qhdPlus: "3500px",
	k4: "3840px",
	k5: "5120px",
};

export const Container = styled.div`

width: 80%;
grid-area: orders;
border-radius: 8px;

`;

export const Header = styled.div`


display: flex;
justify-content: space-between;
padding: 24px 32px;
border-bottom: 1px solid var(--gray-light-line);

> span {
    color: var(--gray);
    font-size: 16px;
    font-family: var(--semiBold);
}

> a > p {
    color: var(--gray);
    font-size: 14px;
    font-family: var(--regular);

}

`;

export const OrdersWrapper = styled.div`

padding: 32px;
display: flex;
flex-direction: column;
`;

export const Order = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
position: relative;

& + div {
    margin-top: 16px;

    &::before {
    content: "";
    background: var(--gray-light-line);
    height: 18px;
    width: 1px;
    position: absolute;
    left: 20px;
    top: -16px;
    }
}

`;

export const IconWrapper = styled.div`

height: 40px;
width: 40px;
border-radius: 8px;
border: 1px solid var(--gray-light-line);
display: flex;
justify-content: center;
align-items: center;
margin-right: 18px;
position: relative;

@media (min-width: ${breakpoints.fullHDPlus}) {
  height: 60px;
  width: 60px;
  }

@media (min-width: ${breakpoints.qhd}) {
  height: 80px;
  width: 80px;
  }

  @media (min-width: ${breakpoints.qhdPlus}) {
    height: 100px;
  width: 100px;
  }

  @media (min-width: ${breakpoints.k4}) {
    height: 120px;
  width: 120px;
  }

`;

export const OrderDetails = styled.div`

display: flex;
align-items: center;

@media (min-width: ${breakpoints.qhd}) {
  gap: 16px;
}

`;

export const BalanceDetails = styled.div`

display: flex;
align-items: center;



`;

export const Type = styled.span`

font-size: 12px;
color: var(--gray-light-order);
font-family: var(--regular);

@media (min-width: ${breakpoints.fullHDPlus}) {
    font-size: 20px
  }

@media (min-width: ${breakpoints.qhd}) {
    font-size: 28px
  }

  @media (min-width: ${breakpoints.qhdPlus}) {
    font-size: 32px
  }

  @media (min-width: ${breakpoints.k4}) {
    font-size: 40px
  }
`;

export const DateUltimosLancamentos = styled.span`

font-size: 14px;
color: var(--black);
font-family: var(--regular);

@media (min-width: ${breakpoints.fullHDPlus}) {
    font-size: 20px
  }

@media (min-width: ${breakpoints.qhd}) {
    font-size: 28px
  }

  @media (min-width: ${breakpoints.qhdPlus}) {
    font-size: 32px
  }

  @media (min-width: ${breakpoints.k4}) {
    font-size: 40px
  }
`;

export const Name = styled.p`
max-width: 340px;
font-size: 15px;
color: var(--gray);
font-family: var(--regular);

@media (min-width: ${breakpoints.fullHDPlus}) {
    font-size: 20px
  }

@media (min-width: ${breakpoints.qhd}) {
    font-size: 28px
  }

  @media (min-width: ${breakpoints.qhdPlus}) {
    font-size: 32px
  }

  @media (min-width: ${breakpoints.k4}) {
    font-size: 40px
  }
`;

export const Icon = styled.img``;

export const InfoOrder = styled.div``;

interface BalanceProps {
	credit: boolean | string;
}

export const Balance = styled.p<BalanceProps>`
color: ${({ credit }) => (credit ? "var(--green)" : "var(--red)")};

font-family: var(--regular);
font-size: 14px;

@media (min-width: ${breakpoints.fullHDPlus}) {
    font-size: 20px
  }

@media (min-width: ${breakpoints.qhd}) {
    font-size: 28px
  }

  @media (min-width: ${breakpoints.qhdPlus}) {
    font-size: 32px
  }

  @media (min-width: ${breakpoints.k4}) {
    font-size: 40px
  }
`;

export const ArrowIcon = styled(ArrowRight)<BalanceProps>`
fill: ${({ credit }) => (credit && credit !== "false" ? "var(--green)" : "var(--boxText)")};
color: ${({ credit }) => (credit && credit !== "false" ? "var(--green)" : "var(--boxText)")};
margin-left: 12px;

path {
    color: ${({ credit }) => (credit && credit !== "false" ? "var(--green)" : "var(--boxText)")};
    fill: currentcolor;
}

@media (min-width: ${breakpoints.qhd}) {
    width: 24px;
  }

  @media (min-width: ${breakpoints.qhdPlus}) {
    width: 32px
  }

  @media (min-width: ${breakpoints.k4}) {
    width: 240px
  }

`;
export const WrapperMobile = styled.div`
  width: 30px;
  position: fixed;
  bottom: 20px;
  right: 50px;
  transition: width 0.3s ease-in-out;
  z-index: 1000;

&:hover {
  width: 40px;
}
`;

export const DivMobile = styled.div`
  position: relative;
`;

export const Tooltip = styled.div<{ show: boolean }>`
display: ${(props) => (props.show ? "block" : "none")};
position: absolute;
top: 0px;
left: -80px;
width: 150px;
transform: translateX(-50%);
background-color: #f5f5f5;
padding: 8px;
border-radius: 4px;
animation: fade-in 0.3s ease-in-out;
z-index: 100000;
border: 2px solid var(--gray);
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
`;
