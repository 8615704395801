import React from "react";

import {
  BadgeWrapper,
  Container,
  ContentWrapper,
  InfoWrapper,
  Line,
  Separator,
  Subtitle,
  SubtitleWrapper,
  Text,
  Title,
  TitleWrapper,
} from "./styles";

import { ReactComponent as SeparatorIconSVG } from "../../assets/LPseparatoricon.svg";
import circleSeparator from "../../assets/LPcircleseparator.svg";

const SectionAccountBussiness: React.FC = () => {
  return (
    <Container>
      <BadgeWrapper>
        <img src={circleSeparator} alt={"separador de seção"} />
      </BadgeWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title>
            A conta <span>VILAPAY-BANK</span>&nbsp; que oferece{" "}
            <span>vantagens</span> para
            <span>você</span> e para o <span>seu negócio</span>.
          </Title>
        </TitleWrapper>
        <Separator>
          <SeparatorIconSVG />
        </Separator>
        <InfoWrapper>
          <SubtitleWrapper>
            <Line />
            <Subtitle>CONHEÇA NOSSA CONTA PJ & PF</Subtitle>
          </SubtitleWrapper>
          <Text>
            Aproveite o melhor das soluções da sua Conta Digital e conte com a
            parceria que só a VILAPAY-BANK oferece. Abra a sua Conta gratuita
            agora.
          </Text>
        </InfoWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default SectionAccountBussiness;
