import React, { useEffect, useState } from "react";
import { KeyboardButton, KeyboardContainer } from "./styles";

interface KeyboardProps {
  onKeyPress: (value: string) => void;
  onConfirm: (passwords: string[]) => void;
  updateClickedValues: (values: string[]) => void; // Adicione essa linha
  updateParentInput: (value: string) => void;
}



const KeyboardPressPasswordEletronic: React.FC<KeyboardProps> = ({
  onKeyPress,
  onConfirm,
  updateParentInput,
  updateClickedValues,
}) => {
  const backspaceValue = "⬅";

  const generateUniqueCombination = (): string[] => {
    const numbers = Array.from({ length: 10 }, (_, index) => index);
    const shuffledNumbers = [...numbers].sort(() => Math.random() - 0.5);
    return shuffledNumbers.slice(0, 2).map((number) => number.toString());
  };

  const generateUniqueButtons = (): string[][] => {
    let uniqueButtonValues: string[][];
    let isRepetition: boolean;

    do {
      uniqueButtonValues = Array.from({ length: 5 }, () =>
        generateUniqueCombination()
      );

      // Check for any repetition across all buttons
      const flatValues = uniqueButtonValues.flat();
      isRepetition = new Set(flatValues).size !== flatValues.length;
    } while (isRepetition);

    // Exibir os pares de valores gerados
    console.log("Pares de valores gerados:");
    uniqueButtonValues.forEach((pair) => {
      console.log(pair.join(" ou "));
    });

    return uniqueButtonValues;
  };

  const [clickedPairs, setClickedPairs] = useState<string[]>([]);
  const [passwords, setPasswords] = useState<string[]>([]);
  const [shuffledButtonValues, setShuffledButtonValues] = useState<string[][]>(
    []
  );
  const [tempClicks, setTempClicks] = useState<string[]>([]); // Novo array temporário
  const [tempClickIndex, setTempClickIndex] = useState<number>(0); // Novo estado para rastrear o índice do array temporário
  const [clickedValues, setClickedValues] = useState<string[]>([]);
  console.log("oi eu sou o clickedValues: ", clickedValues);
  const clickedValuesCompair: string[] = clickedValues;
  console.log("oi eu sou o abc: ", clickedValuesCompair);

  const updatedTempClicks = [...tempClicks];

  console.log("Array Temporário fora do if: ", updatedTempClicks);

  useEffect(() => {
    shuffleButtons();
  }, [passwords]);

  const shuffleButtons = () => {
    const newShuffledButtonValues = generateUniqueButtons();
    setShuffledButtonValues(newShuffledButtonValues);
  };

  const handleBackspace = () => {
    // Verifica se há algo para apagar antes de atualizar a senha
    if (tempClicks.length > 0) {
      const updatedTempClicks = [...tempClicks];
      updatedTempClicks.splice(tempClickIndex - 1, 1); // Remove o último dígito do array temporário

      setTempClickIndex(tempClickIndex - 1);
      setTempClicks(updatedTempClicks);

      // Remover o último elemento de clickedValues
      setClickedValues((prevValues) => prevValues.slice(0, -1));
    } else if (passwords.length > 0) {
      const updatedPasswords = passwords.slice(0, -1);
      setPasswords(updatedPasswords);
    }
  };

  const handleKeyPress = (value: string) => {
    console.log(`Tecla pressionada: ${value}`);

    if (value === backspaceValue) {
      handleBackspace();
    } else {
      handleDigitPress(value);

      updateParentInput(updatedTempClicks.join(""));
      // Use a função de atualização no estado para obter o valor mais recente
      setClickedValues((prevValues) => {
        const updatedValues = [...prevValues];

        // Chame a função de atualização no componente pai apenas se o array tiver 8 posições
        if (updatedValues.length === 8) {
          // Certifique-se de que você está chamando a propriedade passada corretamente
          updateClickedValues(updatedValues);
        }

        return updatedValues;
      });
    }
  };

  useEffect(() => {
    // Certifique-se de que o array tem 8 posições antes de chamar a função de atualização
    if (clickedValues.length === 8) {
      // Chame a função de atualização no componente pai
      updateClickedValues(clickedValues);
    }
  }, [clickedValues]); // Adicione clickedValues como uma dependência para o useEffect

  useEffect(() => {
    // Use useEffect para observar mudanças em updatedTempClicks e chamar a função de atualização do componente pai
    updateParentInput(updatedTempClicks.join(""));
  }, [updatedTempClicks, updateParentInput]);

  const handleDigitPress = (value: string) => {
    const updatedTempClicks = [...tempClicks];
    updatedTempClicks[tempClickIndex] = value;

    console.log("Array Temporário Atualizado:", updatedTempClicks);

    if (updatedTempClicks.length === 9) {
      const clickedPair = [
        updatedTempClicks.slice(0, 2).join(" ou "),
        updatedTempClicks.slice(2, 4).join(" ou "),
        updatedTempClicks.slice(4, 6).join(" ou "),
        updatedTempClicks.slice(6, 8).join(" ou "),
      ];

      // Adiciona o par de valores clicados à variável clickedValues
      setClickedValues((prevValues) => {
        const lastPair = prevValues[prevValues.length - 1];
        const formattedPair = clickedPair
          .map((value) => value.trim())
          .join(" ou ");

        if (lastPair !== formattedPair) {
          const newValues = [...prevValues];
          console.log("clickedValues Atualizado:", newValues);
          return newValues;
        }
        console.log("clickedValues Não Atualizado:", prevValues);
        return prevValues;
      });

      // Limpar array temporário após o processamento
      //setTempClicks([]);
      //setTempClickIndex(0);

      console.log("Array Temporário Limpo:", tempClicks);

      onKeyPress(value);
      shuffleButtons();
    } else {
      setTempClicks(updatedTempClicks);
      setTempClickIndex(tempClickIndex + 1);
      onKeyPress(value);
      shuffleButtons();
    }
  };

  return (
    <KeyboardContainer>
      {shuffledButtonValues.map((subarray, index) => (
        <KeyboardButton
          key={index}
          onClick={() => {
            console.log(`Botão clicado: ${subarray.join(" ou ")}`);
            handleKeyPress(subarray[0]);
            // Adiciona o valor do botão clicado à variável clickedValues
            setClickedValues((prevValues) => [
              ...prevValues,
              subarray.join(" ou "),
            ]);
          }}
        >
          {subarray.join(" ou ")}
        </KeyboardButton>
      ))}
      <KeyboardButton
        onClick={() => {
          console.log(`Botão de backspace clicado`);
          handleKeyPress(backspaceValue);
        }}
      >
        {backspaceValue}
      </KeyboardButton>
    </KeyboardContainer>
  );
};

export default KeyboardPressPasswordEletronic;
