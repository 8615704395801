import { FC, useEffect, useState } from "react";
import { FormInput, FormSelectInput, GradientButton, PhoneFrame } from "./components";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { useLocation, useNavigate } from "react-router-dom";
import { Plan } from "../createAccountWalletInitials/account_wallet_create";
import { api } from "src/services/api";

const FormSchemaRG = z.object({
  documentNumber: z.string({
    required_error: "Insira o número do RG.",
  }),

  shippingDate: z.string({
    required_error: "Insira a Data de Expedição do RG",
  }),
});

const FormSchemaCNH = z.object({
  documentNumber: z.string({
    required_error: "Insira o número da CNH.",
  }),
  shippingDate: z.string({
    required_error: "Insira a Data de Expedição da CNH",
  }),
  expirationDate: z.string({
    required_error: "Insira a Data de Vencimento da CNH",
  }),
});

type FormInputsRG = z.infer<typeof FormSchemaRG>;
type FormInputsCNH = z.infer<typeof FormSchemaCNH>;

export const ChoosePlan: FC = () => {
  const location = useLocation();
  const { accountType } = location.state;

  const [plans, setPlans] = useState<Plan[]>([]);

  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<FormInputsRG>({
    resolver: zodResolver(FormSchemaRG),
  });

  const { control: controlCNH, handleSubmit: handleSubmitCNH } = useForm<FormInputsCNH>({
    resolver: zodResolver(FormSchemaCNH),
  });

  const [currentDocumentSelected, setCurrentDocumentSelected] = useState("");

  const onSubmitRG = async (data: FormInputsRG) => {
    const planData = {
      ...data,
      planId: plans[0]?.id,
    };
    try {
      localStorage.setItem("@yntech:signup-data-02", JSON.stringify(planData));
      navigate("/create-account-wallet/insert-account-address", { state: { accountType } });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitCNH = async (data: FormInputsCNH) => {
    const planData = {
      ...data,
      planId: plans[0]?.id,
    };
    try {
      localStorage.setItem("@yntech:signup-data-02", JSON.stringify(planData));
      navigate("/create-account-wallet/insert-account-address", { state: { accountType } });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await api.get("/graphic/plans", {
        params: {
          role: "WALLET",
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame
        onPress={() => {
          if (accountType === "PF") {
            navigate("/create-account-wallet/insert-data", { state: { accountType } });
          } else {
            navigate("/create-account-wallet/insert-data-pj", { state: { accountType } });
          }
        }}
      />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="700" height="96vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#AA7339" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="670"
            height="94vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[8vh] ml-20">
          <div className="w-full flex items-start gap-10">
            <p className="text-white font-medium text-2xl mr-16 max-2xl:text-sm">
              Você está a poucos passos de uma <br />
              nova <strong>experiência financeira</strong>
            </p>

            <GradientButton
              title={accountType === "PF" ? "2/5" : "2/7"}
              tailwindStyles="bg-black text-white w-14"
              onClick={() => console.log("")}
            />
          </div>

          <p className="text-white text-lg font-light mt-3 mb-4 max-2xl:text-xs">
            Todos os dados a seguir devem ser <br />
            obrigatoriamente do titular.
          </p>

          <div className="ml-36 my-5 outer-container">
            <div className="bg-black w-60 h-40 flex flex-col justify-center items-start p-5 inner-container max-2xl:h-32">
              <GradientButton
                title="Plano Smart"
                tailwindStyles="bg-[#171717] text-white font-extrabold text-2xl h-9 w-48 max-2xl:text-base max-2xl:h-7"
                onClick={() => console.log("")}
              />
              <button className="flex items-center gap-2 mt-3">
                <div className="w-4 h-4 rounded-full bg-[#AA7339]"></div>
                <h2 className="font-extrabold text-[#AA7339] text-xl max-2xl:text-base">Conta Wallet</h2>
              </button>

              <button className="flex items-center gap-2 mt-3">
                <div className="w-4 h-4 rounded-full border-2 border-[#AA7339] flex items-center justify-center">
                  <div className="w-2 h-2 rounded-full bg-[#AA7339]"></div>
                </div>
                <h2 className="font-extrabold text-[#AA7339] text-xl max-2xl:text-base">R$0,10/mês</h2>
              </button>
            </div>
          </div>

          <FormSelectInput
            placeholder="Tipo de documento"
            options={documentDropdownData}
            onChanged={(e) => {
              setCurrentDocumentSelected(e.target.value);
            }}
          />

          {currentDocumentSelected === "CNH" && (
            <form onSubmit={handleSubmitCNH(onSubmitCNH)}>
              <div>
                <Controller
                  name="documentNumber"
                  control={controlCNH}
                  render={({ field, fieldState }) => (
                    <FormInput
                      onChangeText={(e) => field.onChange(e)}
                      placeholder="Número da CNH"
                      required
                      {...field}
                      mask="99999999999"
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="shippingDate"
                  control={controlCNH}
                  render={({ field, fieldState }) => (
                    <FormInput
                      onChangeText={(e) => field.onChange(e)}
                      placeholder="Data de Expedição da CNH"
                      required
                      {...field}
                      type="number"
                      mask="99/99/9999"
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="expirationDate"
                  control={controlCNH}
                  render={({ field, fieldState }) => (
                    <FormInput
                      onChangeText={(e) => field.onChange(e)}
                      placeholder="Data de Vencimento da CNH"
                      required
                      {...field}
                      type="number"
                      mask="99/99/9999"
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />
              </div>

              <div className="flex w-full gap-5 items-center justify-between mt-7">
                <GradientButton
                  title="Voltar"
                  tailwindStyles="bg-[#171717] text-white w-[16rem] h-12"
                  onClick={() => navigate("/create-account-wallet/insert-data")}
                />
                <GradientButton
                  title="Avançar"
                  tailwindStyles="bg-[#AA7339] text-white w-[16rem] h-12"
                  onClick={handleSubmitCNH(onSubmitCNH)}
                />
              </div>
            </form>
          )}

          {currentDocumentSelected === "RG" && (
            <form onSubmit={handleSubmit(onSubmitRG)}>
              <div>
                <Controller
                  name="documentNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormInput
                      onChangeText={(e) => field.onChange(e)}
                      placeholder="Número do RG"
                      required
                      {...field}
                      mask="99.999.999-9"
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="shippingDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormInput
                      onChangeText={(e) => field.onChange(e)}
                      placeholder="Data de Expedição do RG"
                      required
                      {...field}
                      type="number"
                      mask="99/99/9999"
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />
              </div>

              <div className="w-full gap-5 flex items-center justify-between mt-7">
                <GradientButton
                  title="Voltar"
                  tailwindStyles="bg-[#171717] text-white w-[16rem] h-12"
                  onClick={() => navigate("/create-account-wallet/insert-data")}
                />
                <GradientButton
                  title="Avançar"
                  tailwindStyles="bg-[#AA7339] text-white w-[16rem] h-12"
                  onClick={handleSubmit(onSubmitRG)}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const documentDropdownData = ["RG", "CNH"];
