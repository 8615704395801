import styled from "styled-components";

import Background from "../../assets/logo-first.png";

export const Container = styled.div`
  min-height: 592px;
  width: 100%;
  position: relative;

  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;

  padding: 0 20px;
`;

export const WrapperContent = styled.div`
  height: 100%;
  max-width: 1216px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--multi-bold);
  color: var(--white);

  position: relative;
  text-align: center;

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin: 0px 0px 14px 0px;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 12px 0px 0px;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 28px 0px 28px 0px;
  text-align: center;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 14px 0px;
    text-align: unset;
  }
`;

export const Title = styled.h1`
  font-size: 37px;
  font-weight: var(--light);
  color: var(--white);
  margin-bottom: 25px;
  text-align: center;
  max-width: 488px;

  > strong {
    font-weight: var(--bold);
  }

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: var(--regular);
  color: var(--white);
  line-height: 28px;
  margin-bottom: 39px;
  text-align: center;

  @media (min-width: 840px) {
    text-align: unset;
    max-width: 488px;
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  margin-bottom: -45px;
  @media (min-width: 1120px) {
    width: 280px;
  }
`;
