import { yupResolver } from "@hookform/resolvers/yup";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import type React from "react";
import { type FormEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import FormSelectButton from "../../components/FormSelectButton";
import TextInput from "../../components/TextInput";
import { Button } from "../../components/button";
import { InputSelect, SelectObject } from "../../components/select";
// import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";

import { banks } from "../../utils/banks";

import { useClient } from "../../hooks/client";
import { service, serviceError } from "../../services/ajio";
import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Form,
  FormTitle,
  FormWrapper,
  SelectButtonWrapper,
  WrapperHeader,
} from "./styles";

type FormData = {
  document: string;
  name: string;
  agency: string;
  account: string;
  number_identifier: string;
  bank: string;
  role: string;
};

const schema = yup
  .object({
    name: yup.string().required("Nome é obrigatório"),
    document: yup.string().required("Documento é obrigatório"),
    number_identifier: yup
      .string()
      .required("O Nº Identificador do Usuário é obrigatório"),
  })
  .required();

export const TransferListWallet: React.FC = () => {
  const { client } = useClient();
  const [number_identifier, setNumberIdentifier] = useState("");
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);

  // const handlePersonalAccount = () => {
  //   setButtonSelected("personal");
  // };
  // const handleBusinnessAccount = () => {
  //   setButtonSelected("businness");
  // };

  // const [selectedAccountType, setSelectedAccountType] =
  //   useState("Tipo de conta");

  // const handleAccountTypeSelect = (option: string) => {
  //   setSelectedAccountType(option);
  // };

  // const [selectedBank, setSelectedBank] = useState("Banco");
  // const handleBankSelect = (option: string) => {
  //   setSelectedBank(option);
  // };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log("Formulário enviado!");

    const formattedValue = formatIdentifier(number_identifier);
    console.log("Número Identificador formatado:", formattedValue);

    setLoading(true);
    try {
      const {
        data: { user }
      } = await service.get(
        `/graphic/wallet-account/info?number_identifier=${encodeURIComponent(formattedValue)}`,
      );

      console.log("Resposta do serviço:", user);
      setLoading(false);
      navigate("/transfer-wallet/wallet-confirm", {
        state: {
          name: user.name,
          document: user.document,
          number_identifier: user.number_identifier,
          agency: "1002",
          account: user.balance,
          role: user.role,
          id: user.id,
          bank: {
            id: 0,
            name: client.name,
            hidden: client.name,
          },
        },
      });
      return;
    } catch (err) {
      setLoading(false);
      console.log("Erro na requisição:", err);

      if (err instanceof Error) {
        console.log("Mensagem do erro:", err.message);
      } else if (err && typeof err === "object" && "response" in err) {
        const axiosError = err as any; // Type assertion for axios error
        console.log("Resposta do erro:", axiosError.response?.data);
        console.log("Status do erro:", axiosError.response?.status);
        console.log("Cabeçalhos do erro:", axiosError.response?.headers);
      } else if (err && typeof err === "object" && "request" in err) {
        const axiosError = err as any; // Type assertion for axios error
        console.log("Erro na requisição:", axiosError.request);
      } else {
        console.log("Erro desconhecido:", err);
      }
    }
  };

  const navigate = useNavigate();

  const handleNextScreen = () => {
    navigate("/transfer-wallet/wallet-confirm");
  };

  const handleGoBack = () => {
    navigate("/");
  };

  function formatIdentifier(value: string) {
    // Remove tudo que não é dígito
    value = value.replace(/\D/g, "");

    // Formata no padrão 99999999-99
    if (value.length <= 8) {
      return value;
    }
    return value.slice(0, 8) + "-" + value.slice(8, 10);

  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatIdentifier(event.target.value);
    setNumberIdentifier(formattedValue);
    console.log("Número Identificador formatado:", formattedValue);
  };

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
            <StepHeader
              handleGoBackFunction={handleGoBack}
              title="Iniciando Transação entre Wallets"
            />
          </WrapperHeader>
          <Form onSubmit={handleFormSubmit}>
            {/* <SelectButtonWrapper>
              <FormSelectButton
                isSelected={buttonSelected === "personal"}
                onClick={handlePersonalAccount}
                buttonName={"Transferir para Pessoa Física"}
              />
              <FormSelectButton
                isSelected={buttonSelected === "businness"}
                onClick={handleBusinnessAccount}
                buttonName={"Transferir para Pessoa Jurídica"}
              />
            </SelectButtonWrapper> */}

            <FormTitle>Informe o Nº Identificador do Usuário</FormTitle>
            <FormWrapper>
              <div className="mb-5">
                <TextInput
                  type="text"
                  placeholder={"Nº do Identificador"}
                  value={number_identifier}
                  onChange={handleChange}
                />
              </div>

              {/* <SelectInput
                optionSelected={selectedAccountType}
                handleFunction={handleAccountTypeSelect}
                options={["Conta Corrente", "Conta Poupança"]}
              /> */}
            </FormWrapper>
            <ButtonWrapper>
              <Button isLoading={loading} type="submit" title="Continuar" />
            </ButtonWrapper>
          </Form>
        </ContentWrapper>
      </Content>
    </Container>
  );
};
