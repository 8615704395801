import MainLeftSection from "./components/mainSection/MainLeftSection";
import MainRightSection from "./components/mainSection/MainRightSection";
import { PixArea } from "./components/PixArea";
import { UserBalance } from "./components/UserBalance";

import { Section } from "./styles";

export function Dashboard() {
	return (
		<Section>
			<UserBalance />
			<PixArea />
			<MainLeftSection />
			<MainRightSection />
		</Section>
	);
}
