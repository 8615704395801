import React, { FormEvent, Fragment, useEffect, useState, FC } from "react";
import { Skeleton } from "../../../../components/skeleton";
import { api } from "../../../../services/api";
import { Transition } from "@headlessui/react";
import {
  Container,
  Content,
  QrContainer,
  Table,
  TableWrapper,
  LeftDiv,
  RightDiv,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { service } from "../../../../services/ajio";
import axios from "axios";
import { useAuth } from "../../../../hooks/auth";
import { classNames } from "./base-limits-layout";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import bgLogoLeft from "../../../../assets/clients/vilapaybank/bg-div-left.png";
import successLogoLimits from "../../../../assets/success_up_limits.png";
import analisysLogoLimits from "../../../../assets/analisys_status_limits.png";
import upLogoLimits from "../../../../assets/arrow-up-right.svg";
import { GraphicAccount } from "../../graphic-account.interface";
import IntlCurrencyInput from "react-intl-currency-input";

type AccountProps = {
  id: string;
  name: string;
  balance: number;
  document: string;
  email: string;
  monthly_invoicing: number;
  phone?: {
    ddd: string;
    number: string;
  };
  address?: {
    state: string; //only the UF code
    publicPlace: string;
    number: string;
    zipCode: string; //only numbers
    neighborhood: string;
    complement?: string;
    isConfirmed: boolean;
    type: string;
    cityId?: number;
    cityName?: string;
  };
};

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export interface FeeLimits {
  serviceType: ServiceType;
  limits: Limit[];
}

export interface ServiceType {
  name: string;
  description: string;
}

export interface Limit {
  limitType: LimitType;
  maximunAmount: number;
  definedAmount: number;
}

export interface LimitType {
  name: string;
  description: string;
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const IncreasingLimitsMaster = () => {
  // const token = localStorage.getItem('@stricv2:token');
  const navigate = useNavigate();
  //const { setOpenNotification } = useNotification();
  const [key, setKey] = useState<KeysProps>({} as KeysProps);
  const [keys, setKeys] = useState<KeysProps[]>([]);
  const [amount, setAmount] = useState<number>(0.0);

  const [maskedValue, setMaskedValue] = useState("");
  const [value, setValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [keyOptions, setKeyOptions] = useState<string[]>([]);
  const { signIn, user } = useAuth();

  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [, setAccount] = useState<AccountProps>({} as AccountProps);
  const [graphicAccount, setGraphicAccount] = useState<GraphicAccount>();
  const [feeLimits, setFeeLimits] = useState<FeeLimits[]>([]);

  const [feeBillet, setFeeBillet] = useState(0);
  const [feeP2pIn, setFeeP2pIn] = useState(0);
  const [feeP2pOut, setFeeP2pOut] = useState(0);
  const [feePixIn, setFeePixIn] = useState(0);
  const [feePixOut, setFeePixOut] = useState(0);
  const [feeTedOut, setFeeTedOut] = useState(0);
  const [limitDay, setLimitDay] = useState(0);
  const [limitNightly, setLimitNightly] = useState(0);
  const [limitRequestStatus, setLimitRequestStatus] = useState("");
  const [limitValues, setLimitValues] = useState<FeeLimits[]>([]);

  const [limitMonth, setLimitMonth] = useState(0);

  const [show, setShow] = useState(false);

  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);

  const token = window.localStorage.getItem("@stricv2:token");

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .get(`/graphic/fee-limits/master`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setFeeLimits(data);
        setLoading(false);

        const initialLimitValues = data.map((feeLimit: FeeLimits) => ({
          serviceType: feeLimit.serviceType,
          limits: feeLimit.limits.map((limit) => ({
            limitType: limit.limitType,
            maximunAmount: limit.maximunAmount,
            definedAmount: limit.definedAmount, // Inicialize com o valor atual do limite
          })),
        }));
        setLimitValues(initialLimitValues);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id, token]);

  async function handleForm(e: FormEvent) {
    e.preventDefault();
    setLoading(true);

    try {
      await api.patch(`/backoffice/accounts/${id}`, {
        id: id,
        feePixIn,
        feePixOut,
        feeTedOut,
        feeP2pOut,
        feeBillet,
        limitDay,
        limitNightly,
        limitMonth,
      });

      setLoading(false);
      setShow(true);
    } catch {
      setLoading(false);
      //
    }
  }
  console.log("RESPONSE DO 04:");

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  const handleAmountChange = (newValue: number) => {
    if (!isNaN(newValue)) {
      setAmount(newValue);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post(
        "/graphic/fee-limits/change/request",
        limitValues,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      handleOpenDownloadModal();
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    }
  };

  async function getKeys() {
    service
      .get("/pix/keys")
      .then(({ data }) => {
        setKeys(data.keys);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.value);
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const userId = user.id;
  console.log(userId, "eu sou o id:");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleLimitChange = (
    index: number,
    limitIndex: number,
    value: number
  ) => {
    // Verifique se o índice 'index' está dentro dos limites do array 'feeLimits'
    if (index < 0 || index >= feeLimits.length) {
      console.error("Índice 'index' fora do intervalo válido");
      return; // Saia da função se o índice estiver fora do intervalo
    }

    // Crie uma cópia do array de valores
    const newLimitValues = [...limitValues];

    // Verifique se o índice 'limitIndex' está dentro dos limites do array de limites
    if (limitIndex < 0 || limitIndex >= feeLimits[index].limits.length) {
      console.error("Índice 'limitIndex' fora do intervalo válido");
      return; // Saia da função se o índice estiver fora do intervalo
    }

    // Atualize o valor do campo especificado
    newLimitValues[index].limits[limitIndex].definedAmount = value;

    // Atualize o estado com os novos valores
    setLimitValues(newLimitValues);
  };

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOpenDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const handleCloseDownloadModal = () => {
    setIsDownloadModalOpen(false);
    navigate("/myLimits");
  };

  const inputWidth = `${Math.max(100, inputValue.length * 8)}px`; // Adjust the multiplier as needed
  const width = inputValue === "" ? "300px" : inputWidth;

  let teste = "a";

  return (
    <Container>
      <Content>
        <Table>
          <TableWrapper>
            <LeftDiv>
              <img src={bgLogoLeft} alt={"bank"} />
            </LeftDiv>

            <RightDiv>
              <QrContainer className="py-8">
                {" "}
                {/* Adicionando espaçamento vertical */}
                <div className="space-y-6 sm:px-6 lg:px-0 w-full">
                  <section aria-labelledby="payment-details-heading">
                    <>
                      <div>
                        <h2
                          id="payment-details-heading"
                          className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                        >
                          Meus limites:
                        </h2>
                        <p className="mt-1 text-sm text-gray-500"></p>
                      </div>

                      <div className="mt-6 grid grid-cols-6 gap-6">
                        {feeLimits.map((feeLimit, index) => (
                          <div key={index} className="col-span-2 sm:col-span-2">
                            <label
                              htmlFor={`service-type-${index}`}
                              className="block text-sm font-medium text-gray-700"
                            >
                              {feeLimit.serviceType.description}
                            </label>
                            {feeLimit.limits.map((limit, limitIndex) => (
                              <div key={limitIndex}>
                                <label
                                  htmlFor={`service-type-${index}-limit-${limitIndex}`}
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {limit.limitType.description}
                                </label>
                                <IntlCurrencyInput
                                  id={`service-type-${index}-limit-${limitIndex}`}
                                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                  currency="BRL"
                                  config={currencyConfig}
                                  value={limit.definedAmount}
                                  onChange={(
                                    event: FormEvent,
                                    value: number
                                  ) => {
                                    handleLimitChange(index, limitIndex, value);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </>
                  </section>

                  <div
                    className={classNames(
                      "space-y-6 sm:px-6 lg:px-0 w-ful",
                      "lg:col-span-10"
                    )}
                  >
                    <div className="shadow sm:rounded-3xl sm:overflow-hidden mt-8">
                      {limitRequestStatus === "approved" && (
                        <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70">
                          <img
                            src={upLogoLimits}
                            alt={"bank"}
                            className="mr-4 w-16 h-16"
                          />

                          <div>
                            <p className="mt-1 text-sm text-gray-500">
                              Parabéns! Seu pedido de aumento de limite foi
                              aprovado sucesso para análise. <br /> Estamos
                              ansiosos para oferecer a você limites ainda
                              maiores para aproveitar ao máximo os nossos
                              serviços!
                              <br />
                              Obrigado por confiar em nós.
                            </p>
                          </div>
                        </div>
                      )}

                      {limitRequestStatus === "rejected" && (
                        <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70">
                          <img
                            src={upLogoLimits}
                            alt={"bank"}
                            className="mr-4 w-16 h-16"
                          />
                          <div>
                            <p className="mt-1 text-sm text-gray-500">
                              Lamentamos informar que, após analisar sua
                              solicitação, não podemos conceder o aumento de
                              limite neste momento.
                              <br /> Nossas políticas e procedimentos atuais não
                              nos permitem atender sua solicitação.
                              <br /> Agradecemos sua compreensão e permanecemos
                              à disposição para qualquer outra questão ou
                              solicitação que possa ter.
                            </p>
                          </div>
                        </div>
                      )}

                      {limitRequestStatus === "" && (
                        <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70">
                          <img
                            src={upLogoLimits}
                            alt={"bank"}
                            className="mr-4 w-16 h-16"
                          />

                          <div>
                            <p className="mt-1 text-sm text-gray-500">
                              Está pronto para dar um passo adiante? Solicite o
                              aumento do seu limite agora mesmo! <br /> Preencha
                              conforme desejado, escolha os limites e clique em
                              enviar para iniciar o processo.
                            </p>
                          </div>
                        </div>
                      )}

                      {limitRequestStatus === "waiting" && (
                        <div className="shadow sm:rounded-3xl sm:overflow-hidden mt-8">
                          <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70 space-x-4">
                            <div>
                              <p className="mt-1 text-sm text-gray-500">
                                <strong>
                                  Seu pedido foi enviado para análise.
                                </strong>
                                <br />
                                Aguarde, em até 48 horas você terá a<br />
                                Resposta sobre o aumento do seu limite!
                              </p>
                            </div>
                            <img src={analisysLogoLimits} alt={"bank"} />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="bg-gray flex flex-col items-center py-2 px-4 sm:p-6 mx-auto w-96">
                      {!loading && (
                        <div className="flex justify-center">
                          {/*<button
        type="submit"
        className="bg-black text-white border border-transparent rounded-md shadow-sm py-3 px-8 inline-flex justify-center text-sm font-medium hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
      >
        Cancelar
  </button>*/}
                          {limitRequestStatus !== "waiting" && (
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="bg-amber-700 border border-transparent rounded-md shadow-sm py-3 px-8 inline-flex justify-center text-sm font-medium text-white hover:bg-amber-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ml-4"
                            >
                              Solicitar aumento
                            </button>
                          )}
                          {isDownloadModalOpen && (
                            <div
                              className="modal"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <div
                                className="modal-content"
                                id="download-modal"
                                style={{
                                  backgroundColor: "#fefefe",
                                  padding: "20px",
                                  borderRadius: "10px",
                                  maxWidth: "400px",
                                  maxHeight: "100%",
                                }}
                              >
                                <img src={successLogoLimits} alt={"bank"} />

                                <p
                                  className="text-center mb-4 mx-4"
                                  style={{
                                    color: "#5D5D5D",
                                    height: "61.42px",
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: "16.18px",
                                  }}
                                >
                                  Seu pedido foi enviado para análise. Aguarde,
                                  em até 48 horas você terá a resposta sobre o
                                  aumento do seu limite!{" "}
                                </p>

                                <button
                                  onClick={handleCloseDownloadModal}
                                  style={{
                                    background: "#AA7339",
                                    borderRadius: "20px",
                                  }}
                                  className="w-full px-10 py-4 mb-4  text-white"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </QrContainer>
            </RightDiv>
          </TableWrapper>
        </Table>
      </Content>
    </Container>
  );
};

const PropDetail: FC<{
  title: string;
  value: string | number;
  cols?: number;
}> = ({ title, value, cols = 4 }) => {
  return (
    <div
      style={{ gridColumn: `span ${cols} / span ${cols}` }}
      className="sm:col-span-2"
    >
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      <input
        type="text"
        name="name"
        id="name"
        disabled
        value={value}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
      />
    </div>
  );
};
