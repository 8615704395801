import transactionSpinner from "src/assets/transactionSpinner.svg";

export const ModalLoader = () => {
	return (
		<div className="flex flex-col gap-[1.875rem] w-[570px] h-[650px] items-center justify-center px-12 py-20 drop-shadow-md rounded-[1.875rem]">
			<p className="text-[1.875rem] w-64 text-center font-semibold">
				Aguarde, estamos processando a sua transação!
			</p>
			<img
				src={transactionSpinner}
				alt="spinner"
				className="animate-spin transition"
			/>
		</div>
	);
};
