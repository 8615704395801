import React from "react";
import { QrCodeDynamicFormProvider } from "src/context/qrcodeDynamicContext";
import { QrCodeStaticFormProvider } from "src/context/qrcodeStaticContext";
import { AuthProvider } from "./auth";
import { AuthBackofficeProvider } from "./authBackoffice";
import { ClientProvider } from "./client";
import { ModalInvoiceContextProvider } from "./modalInvoice";
import { ModalPixContextProvider } from "./modalPix";
import { ModalTransferContextProvider } from "./modalTransfer";
import { NotificationProvider } from "./notification";

export const Hooks: React.FC = ({ children }) => {
	return (
		<ClientProvider>
			<AuthBackofficeProvider>
				<AuthProvider>
					<QrCodeStaticFormProvider>
						<QrCodeDynamicFormProvider>
							<NotificationProvider>
								<ModalTransferContextProvider>
									<ModalInvoiceContextProvider>
										<ModalPixContextProvider>
											{children}
										</ModalPixContextProvider>
									</ModalInvoiceContextProvider>
								</ModalTransferContextProvider>
							</NotificationProvider>
						</QrCodeDynamicFormProvider>
					</QrCodeStaticFormProvider>
				</AuthProvider>
			</AuthBackofficeProvider>
		</ClientProvider>
	);
};
