import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNotification } from "src/hooks/notification";
import { service } from "src/services/ajio";
import { Loading } from "src/components/loading";
import Dropzone from "src/components/Dropzone";
import { DocumentIcon } from "@heroicons/react/outline";
import { convertToBase64 } from "src/utils/convertToBase64";
import { api } from "src/services/api";
import { Alert } from "antd";

interface ButtonProps {
  title?: string;
  cols?: number;
  type?: "button" | "submit" | "reset";
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

export function UploadDocumentsDelbank() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { setOpenNotification } = useNotification();
  const token = localStorage.getItem("@backoffice:token");
  const [userId, setUserId] = useState("");

  const [documents, setDocuments] = useState([
    {
      name: "Selfie",
      type: "SELFIE",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "CNH frontal",
      type: "CNH_FRONT",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "CNH verso",
      type: "CNH_BACK",
      base64: "",
      isImage: false,
      fileName: "",
    },

    {
      name: "RG fontal",
      type: "RG_FRONT",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "RG verso",
      type: "RG_BACK",
      base64: "",
      isImage: false,
      fileName: "",
    },
  ]);

  const loadAccount = async () => {
    try {
      const response = await api.get(`/backoffice/accounts/${id}`, {
        headers: { Authorization: "Bearer " + token },
      });
      const document = response.data.account.user.document;
      if (document && document.length > 11) {
        setDocuments((prev) => [
          ...prev,
          {
            name: "Contrato social / CCMEI",
            type: "PERSON_LEGAL_SOCIAL_CONTRACT",
            base64: "",
            isImage: false,
            fileName: "",
          },
          {
            name: "Rendimentos mensais",
            type: "PERSON_LEGAL_REVENUES",
            base64: "",
            isImage: false,
            fileName: "",
          },
        ]);
      }
      setUserId(response.data.account.user.id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadAccount();
  }, [id]);

  const handleDocuments = async () => {
    setLoading(true);
    documents.map(async (document) => {
      try {
        if (document.base64) {
          const response = await service.post(
            "backoffice/accounts/document",
            {
              userId,
              documentBase64: document.base64,
              documentType: document.type,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      } catch (error) {
        console.log("erro ao alterar documento", document, error);
      }
    });

    setTimeout(() => {
      setLoading(false);

      setOpenNotification({
        notification: `Documentos salvos`,
        title: `Sucesso`,
        type: "success",
      });
    }, 3000);
  };

  return (
    <div className="flex w-full items-center justify-center">
      <div className="py-6 px-4 sm:p-6 bg-white rounded-xl shadow-sm w-full flex flex-wrap">
        <>
          <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            {documents.length > 5 ? (
              <Alert
                message="Por favor, sinta-se à vontade para completar o seu cadastro anexando uma selfie sua e uma cópia frente e verso de um documento de identificação, como RG ou CNH. Estamos aqui para garantir sua segurança e privacidade. *OBS: Caso você se enquadre como MEI no campo de envio contrato social envie o seu CCMEI*"
                type="warning"
                style={{ marginBottom: 20 }}
              />
            ) : (
              <Alert
                message="Por favor, sinta-se à vontade para completar o seu cadastro anexando uma selfie sua e uma cópia frente e verso de um documento de identificação, como RG ou CNH. Estamos aqui para garantir sua segurança e privacidade."
                type="warning"
                style={{ marginBottom: 20 }}
              />
            )}
          </div>
          <div className="grid w-full grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
            {documents.map((doc, i) => (
              <DocumentDetail
                cols={1}
                key={i}
                onPickFile={async (f) => {
                  const fileBase64 = await convertToBase64(f);
                  const isImage = f.name.includes("png") || f.name.includes("jpeg") || f.name.includes("jpg");

                  setDocuments((documents) =>
                    documents.map((_doc) =>
                      _doc.type == doc.type
                        ? {
                            ...doc,
                            base64: fileBase64,
                            isImage,
                            fileName: f.name,
                          }
                        : _doc
                    )
                  );
                }}
                base64={doc.base64}
                name={doc.name}
                isImage={doc.isImage}
                fileName={doc.fileName}
              />
            ))}
          </div>
          <button className="flex w-full justify-end mt-2" onClick={handleDocuments}>
            <Button className="gap-2">
              Salvar documentos
              <Loading isLoading={loading} />
            </Button>
          </button>
        </>
      </div>
    </div>
  );
}

const DocumentDetail: FC<{
  name: string;
  base64: string;
  cols: number;
  isImage: boolean;
  fileName?: string;
  onPickFile: (file: File) => void;
}> = ({ base64, name, onPickFile, cols, isImage }) => {
  return (
    <div className={`flex flex-col gap-2 grid-cols-[${cols}]`}>
      {base64 ? (
        <div className="flex items-center justify-center w-full h-64 bg-zinc-100 rounded-lg overflow-hidden">
          {isImage ? (
            <img className="w-full h-64 bg-red-400 object-cover" src={`data:image/png;base64, ${base64}`} />
          ) : (
            <div className="flex flex-col items-center justify-center">
              <DocumentIcon width={42} color="#888" />
              <span className="text-zinc-400 text-sm">{name}</span>
            </div>
          )}
        </div>
      ) : (
        <Dropzone
          onDrop={(files) => {
            onPickFile(files[0]);
            console.log(files);
          }}
        />
      )}

      <div className="flex items-center justify-center w-full h-12 text-white rounded-lg bg-[#AA7339]">
        <span>{name}</span>
      </div>
    </div>
  );
};

const Button: FC<ButtonProps> = ({
  title,
  cols = 4,
  type = "button",
  className = "",
  isLoading = false,
  onClick,
  children,
}) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700 mb-1">
        {title}
      </label>
      <button
        type={type}
        onClick={onClick}
        className={`mt-1 w-full inline-flex justify-center rounded-md bg-green-600 border border-green-300  px-4 py-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`}
        disabled={isLoading}
      >
        {isLoading && <span className="animate-spin mr-2">Loading...</span>}
        {children}
      </button>
    </div>
  );
};
