import { useState } from "react";
import icon from "src/assets/qrcodeicon.png";
import {
	ModalClose,
	ModalContainer,
	ModalContent,
	ModalRoot,
} from "src/components/ui/modal";
import { useQrCodeDynamicStepForm } from "src/context/qrcodeDynamicContext";
import { useQrCodeStaticStepForm } from "src/context/qrcodeStaticContext";
import { SelectValueDynamic } from "./components/selectValueDynamic";
import { SelectValueStatic } from "./components/selectValueStatic";
import { SuccessQrCode } from "./components/success";

export const NewQrCode = () => {
	const { formStaticStep, formStaticData } = useQrCodeStaticStepForm();
	const { formDynamicStep, formDynamicData } = useQrCodeDynamicStepForm();
	const [isOpenDynamic, setIsOpenDynamic] = useState(false);
	const [isOpenStatic, setIsOpenStatic] = useState(false);

	return (
		<section className="w-[80%] flex flex-col px-8">
			<h1 className="text-[20px] font-bold mt-8">Ações</h1>
			<div className="flex gap-7">
				<button
					type="button"
					onClick={() => setIsOpenStatic(true)}
					className="bg-[#FFFFFF] rounded-lg text-[25px] font-bold flex justify-items-start relative w-[300px] py-8 pl-2 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
				>
					Gerar QR
					<br /> Estatico
					<img
						src={icon}
						alt="icon"
						className="absolute  object-cover right-0 bottom-0"
					/>
				</button>
				<button
					type="button"
					onClick={() => setIsOpenDynamic(true)}
					className="bg-[#FFFFFF] rounded-lg text-[25px] font-bold flex justify-items-start relative w-[300px] py-8 pl-2 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
				>
					Gerar QR <br /> Dinâmico
					<img
						src={icon}
						alt="icon"
						className="absolute  object-cover right-0 bottom-0"
					/>
				</button>
			</div>
			<ModalRoot open={isOpenDynamic} setIsOpen={setIsOpenDynamic}>
				<ModalContainer>
					<ModalContent>
						<ModalClose />
						{formDynamicStep === 1 && <SelectValueDynamic />}
						{formDynamicStep === 2 && (
							<SuccessQrCode
								setIsOpen={setIsOpenDynamic}
								amount={formDynamicData.amount}
								hash={formDynamicData.hash}
								image={formDynamicData.image}
							/>
						)}
					</ModalContent>
				</ModalContainer>
			</ModalRoot>
			<ModalRoot open={isOpenStatic} setIsOpen={setIsOpenStatic}>
				<ModalContainer>
					<ModalContent>
						<ModalClose />
						{formStaticStep === 1 && <SelectValueStatic />}
						{formStaticStep === 2 && (
							<SuccessQrCode
								setIsOpen={setIsOpenStatic}
								amount={formStaticData.amount}
								hash={formStaticData.hash}
							/>
						)}
					</ModalContent>
				</ModalContainer>
			</ModalRoot>
		</section>
	);
};
