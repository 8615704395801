export const config = {
  FRONTEND_URL:
    process.env.NODE_ENV == "development"
      ? "http://localhost:3000"
      : "http://vilapaybank.com.br",
  VERIFF_CALLBACK:
    process.env.NODE_ENV == "development"
      ? "http://vilapaybank.com.br"
      : "http://vilapaybank.com.br",
  VERIFF_APIKEY:
    process.env.NODE_ENV == "development"
      ? "" //"bfcd7541-cc31-4685-83c0-c9b5775a440d"
      : "", //"bfcd7541-cc31-4685-83c0-c9b5775a440d",
};
