import { useEffect, useRef } from "react";
import QRCode from "qrcode";
import { showAlertError } from "src/components/alertError";
import { showAlertSuccess } from "src/components/alertSuccess";

interface SuccessProps {
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	amount?: string;
	hash?: string;
	image?: string;
}

export const SuccessQrCode = ({
	setIsOpen,
	amount,
	hash,
	image,
}: SuccessProps) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	const downloadImage = () => {
		if (!canvasRef.current) {
			showAlertError("QR Code não disponível para download.");
			return;
		}

		const imageURL = canvasRef.current.toDataURL("image/png");

		const link = document.createElement("a");
		link.href = imageURL;
		link.download = "qrcode.png";
		link.click();

		showAlertSuccess("QR Code baixado com sucesso!");
	};

	useEffect(() => {
		if (!hash || !canvasRef.current) return;

		try {
			QRCode.toCanvas(canvasRef.current, hash, {
				errorCorrectionLevel: "L",
			});
		} catch (error) {
			console.error("Erro ao gerar QR Code:", error);
		}
	}, [hash]);
	const handleCopyQRCode = async () => {
		if (!hash) return;

		try {
			await navigator.clipboard.writeText(hash);
			showAlertSuccess("Código QR copiado com sucesso!");
		} catch (error) {
			showAlertError("Erro ao copiar o código QR. Tente novamente.");
		}
	};

	return (
		<div className="flex flex-col items-center gap-5 px-4">
			{hash ? (
				<canvas ref={canvasRef} />
			) : (
				<p className="text-gray-500">QR Code não disponível</p>
			)}
			<div className="border border-[#4AD858] text-[#4AD858] text-[15px] flex justify-center items-center rounded-md py-2 px-4">
				<p>Valor: {amount}</p>
			</div>
			<h2 className="text-[30px] font-bold">QR Code Gerado com sucesso!</h2>
			<button
				type="button"
				onClick={downloadImage}
				className="bg-[#004A8A] rounded-md text-[15px] w-[70%] font-bold text-white py-2 px-4"
			>
				Salvar para compartilhar
			</button>
			<button
				type="button"
				onClick={handleCopyQRCode}
				className="border border-[#004A8A] rounded-md text-[15px] w-[70%] font-bold text-[#004A8A] py-2 px-4"
			>
				Copiar Código QR
			</button>
		</div>
	);
};
