import type { NewPixFormProps } from ".";

interface PasswordStepProps extends NewPixFormProps {}

export function PasswordStep({ nextStep }: PasswordStepProps) {
	return (
		<div className="flex flex-col">
			<p className="text-[1.875rem] font-semibold text-center">
				Insira a sua senha eletrônica de 8 dígitos para confirmar a ação:
			</p>
			TECLADO AQUI
			<button type="button" onClick={nextStep}>
				continua
			</button>
		</div>
	);
}
