import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const UserWallet = () => {
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("@stricv2:user") as string);

	useEffect(() => {
		if (user.role === "WALLET") {
			navigate("/wallet/adds-founds-wallet");
		}
	}, [user, navigate]);

	return (
		<section className="w-[70%] flex flex-col items-center">
			<div className="w-[90%] mt-8">
				<h2 className="text-4xl ">BALANCETE</h2>
			</div>
			<div className="w-[90%] mt-8 border border-[#B6B6B694] flex justify-between items-center">
				<div className="p-14">
					<p className="text-3xl text-[#8A8A8A]">SALDO LIQUIDO</p>
					<p className="text-3xl font-bold">R$1.275</p>
				</div>
				<div className="bg-[#D0D0D0] w-[1px] h-[150px]" />
				<div className="p-14">
					<p className="text-3xl text-[#8A8A8A]">SALDO BRUTO</p>
					<p className="text-3xl font-bold">R$2.275</p>
				</div>
				<div className="bg-[#D0D0D0] w-[1px] h-[150px]" />
				<div className="p-14">
					<p className="text-3xl text-[#8A8A8A]">SALDO A REPASSAR</p>
					<p className="text-3xl font-bold">R$1.500</p>
				</div>
			</div>
		</section>
	);
};
