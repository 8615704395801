import { IconProps } from "src/interface/iconProps";

export const ExtractIcon = ({ className }: IconProps) => {
	return (
		<svg
			viewBox="0 0 26 25"
			fill="none"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.71338 16.5595V1.45459L9.3917 2.29375L11.07 1.45459L12.7452 2.29375L14.4429 1.45459L16.105 2.29375L17.7723 1.45459L19.4469 2.29375L21.14 1.45459L22.8188 2.29375L24.4966 1.45459V13.2028"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linejoin="round"
			/>
			<path
				d="M24.4965 13.2026V19.0768C24.4965 20.1896 24.0545 21.2568 23.2676 22.0436C22.4807 22.8305 21.4135 23.2726 20.3007 23.2726M20.3007 23.2726C19.1879 23.2726 18.1207 22.8305 17.3338 22.0436C16.547 21.2568 16.1049 20.1896 16.1049 19.0768V16.5593H1.83919C1.72872 16.5583 1.61916 16.5793 1.51691 16.6212C1.41465 16.663 1.32175 16.7248 1.24363 16.8029C1.16551 16.881 1.10374 16.9739 1.06191 17.0762C1.02009 17.1784 0.999053 17.288 1.00003 17.3984C1.00003 20.7551 1.35353 23.2726 5.19584 23.2726H20.3007Z"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linejoin="round"
			/>
			<path
				d="M11.0701 6.48975H21.14"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M14.4265 10.6855H21.1398"
				stroke="#1E1E1E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
