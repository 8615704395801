import { FC, useState } from "react";
import { FormInput, GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { service } from "../../services/ajio";
import { useNotification } from "src/hooks/notification";

import "./styles.css";

const FormSchema = z.object({
  password: z.string({
    required_error: "Insira uma Senha.",
  })
  .min(8, { message: "A senha deve ter no mínimo 8 caracteres." })
  .regex(/[A-Z]/, { message: "deve ter uma letra maiúscula." })
  .regex(/[a-z]/, { message: "deve ter uma letra minúscula." })
  .regex(/[0-9]/, { message: "deve ter um número." })
  .regex(/[^A-Za-z0-9]/, { message: "deve ter um caractere especial." }),

  confirmPassword: z.string({
    required_error: "Confirme sua Senha.",
  }),

  pin: z.string({
    required_error: "Insira o PIN.",
  })
  .regex(/^\d{4}$/, { message: "O PIN deve ter 4 dígitos numéricos." })
  .refine(pin => !/(\d)\1{3}/.test(pin) && !/0123|1234|2345|3456|4567|5678|6789|7890/.test(pin), {
    message: "Não deve ser sequencial.",
  }),

  confirmPin: z.string({
    required_error: "Confirme o PIN.",
  }),

  electronicSignature: z.string({
    required_error: "Insira Senha Eletrônica.",
  })
  .min(8, { message: "A senha eletrônica deve ter no mínimo 8 caracteres." })
  .refine(sig => !/(\d)\1{7}/.test(sig) && !/01234567|12345678|23456789|34567890/.test(sig), {
    message: "A senha eletrônica não deve ser sequencial ou repetitiva.",
  }),

  confirmElectronicSignature: z.string({
    required_error: "Confirme Senha Eletrônica.",
  }),
}).refine(data => data.password === data.confirmPassword, {
  message: "As senhas devem ser iguais.",
  path: ["confirmPassword"], // path of error
}).refine(data => data.pin === data.confirmPin, {
  message: "Os PINs devem ser iguais.",
  path: ["confirmPin"],
}).refine(data => data.electronicSignature === data.confirmElectronicSignature, {
  message: "As senhas eletrônicas devem ser iguais.",
  path: ["confirmElectronicSignature"],
});

export default FormSchema;


type FormInputs = z.infer<typeof FormSchema>;

const FRANCHISE_ID = "8b532d66-0053-41dd-92ce-6cb1385f34c2";

export const CreatePassword: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const [loading, setLoading] = useState(false);

  const { setOpenNotification } = useNotification();

  const { control, handleSubmit, setError } = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
  });

  const navigate = useNavigate();

  function transformCurrency(currency: string) {
    const result = Number(currency?.replace(/[/,R$ .,-\s]/g, "")) / 100;

    return result;
  }

  async function handlePasswords(data: FormInputs) {
    setLoading(true);
    const addressData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-03")));
    const planData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-02")));
    const userData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-01")));
    const partnerData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-partner")));
    const partnerAddressData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-partner-address")));

    const pass = data.password;
    const pass2 = data.confirmPassword;

    if (pass.length < 8) {
      setError("password", {
        message: "A senha deve ter pelo menos 8 dígitos.",
      });
      setLoading(false);
      return;
    }

    if (!/[A-Z]/.test(pass) || !/\d/.test(pass)) {
      setError("password", {
        message: "A senha deve ter pelo uma letra maiúscula e um número.",
      });
      setLoading(false);
      return;
    }

    if (pass !== pass2) {
      setError("confirmPassword", { message: "As senhas não estão iguais." });
      setLoading(false);
      return;
    }

    if (data.pin.length !== 4) {
      setError("pin", { message: "O PIN deve ter 4 dígitos." });
      setLoading(false);
      return;
    }

    if (["1234", "2345", "3456", "4567", "5678", "6789"].includes(data.pin)) {
      setError("pin", {
        message: "O PIN não pode ser composto de números consecutivos.",
      });
      setLoading(false);
      return;
    }

    if (data.electronicSignature.length !== 8) {
      setError("electronicSignature", {
        message: "A Senha Eletrônica deve ter 8 dígitos.",
      });
      setLoading(false);
      return;
    }

    if (["1234", "2345", "3456", "4567", "5678", "6789"].includes(data.electronicSignature)) {
      setError("electronicSignature", {
        message: "O PIN não pode ser composto de números consecutivos.",
      });
      setLoading(false);
      return;
    }

    try {
      const ddd = userData.phone.split(" ")[0].replace("(", "").replace(")", "");
      const number = userData.phone.split(" ")[1].replace("-", "");

      if (accountType === "PF") {
        const raw = {
          type: "PF",
          franchise_id: FRANCHISE_ID,
          document: userData.document.replace(/[/,.,-\s]/g, ""),
          name: userData.name,
          email: userData.email,
          birthDate: userData.birthDate.split("/").reverse().join("-"),
          gender: userData.gender,
          password: data.password,
          pin: data.pin,
          passwordEletronic: data.electronicSignature,
          rg_cnh: planData.documentNumber.replace(/[/,.,-\s]/g, ""),
          isRole: "WALLET",
          planId: planData.planId,
          phone: { ddd, number, type: "CELL" },
          address: {
            isConfirmed: true,
            publicPlace: addressData.address,
            number: addressData.number,
            complement: addressData.complement || "",
            neighborhood: addressData.neighborhood,
            cityName: addressData.city,
            zipCode: addressData.postalCode.replace(/-/g, ""),
            type: addressData.type,
            state: addressData.state,
          },
        };

        await service.post("/graphic/create", raw);

        setOpenNotification({
          title: "Sucesso",
          notification: "Conta criada com sucesso.",
          type: "success",
        });

        setLoading(false);
        navigate("/create-account-wallet/adding-secure", { state: { accountType } });
      }

      if (accountType === "PJ") {
        const raw = {
          type: "PJ",
          password: data.password,
          pin: data.pin,
          email: userData.email,
          passwordEletronic: data.electronicSignature,
          document: userData.cnpj.replace(/[/,.,-\s]/g, ""),
          corporateName: userData.companyName,
          fantasyName: userData.fantasyName,
          mother_name: partnerData.motherName,
          activityCnae: userData.CNAE,
          rg_cnh: planData.documentNumber.replace(/[/,.,-\s]/g, ""),
          openingDate: userData.foundationDate.split("/").reverse().join("-"),
          isPoliticallyExposedPerson: false,
          amountMonthlyInvoicing: transformCurrency(userData.monthlyBilling),
          amountShareCapital: transformCurrency(userData.shareCapital),
          amountPatrimony: transformCurrency(userData.patrimony),
          planId: planData.planId,
          isRole: "WALLET",
          address: {
            isConfirmed: true,
            publicPlace: addressData.address,
            number: addressData.number,
            complement: addressData.complement || "",
            neighborhood: addressData.neighborhood,
            cityName: addressData.city,
            zipCode: addressData.postalCode.replace(/-/g, ""),
            type: addressData.type,
            state: addressData.state,
          },
          phone: { ddd, number, type: "CELL" },
          partners: {
            is_pep: partnerData.isPep,
            name: partnerData.name,
            document: partnerData.cpf.replace(/[/,.,-\s]/g, ""),
            birthDate: partnerData.bornDate.split("/").reverse().join("-"),
            mother_name: partnerData.motherName,
            phone: { ddd, number, type: "CELL" },
            address: {
              isConfirmed: true,
              publicPlace: partnerAddressData.address,
              number: partnerAddressData.number,
              complement: partnerAddressData.complement || "",
              neighborhood: partnerAddressData.neighborhood,
              cityName: partnerAddressData.city,
              zipCode: partnerAddressData.postalCode.replace(/-/g, ""),
              type: partnerAddressData.type,
              state: partnerAddressData.state,
            },
          },
        };

        await service.post("/graphic/create", raw);

        setOpenNotification({
          title: "Sucesso",
          notification: "Conta criada com sucesso.",
          type: "success",
        });

        setLoading(false);
        navigate("/create-account-wallet/adding-secure", { state: { accountType } });
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame
        onPress={() =>
          navigate(
            accountType === "PF"
              ? "/create-account-wallet/insert-account-address"
              : "/create-account-wallet/insert-partner-account-address",
            { state: { accountType } }
          )
        }
      />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="700" height="90vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#AA7339" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="670"
            height="88vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[12vh] ml-20">
          <div className="w-full flex items-start gap-10">
            <p className="text-white font-medium text-lg mr-40">
              Falta pouco, já estamos finalizando <br />o seu cadastro.
            </p>

            <GradientButton
              title={accountType === "PF" ? "4/5" : "6/7"}
              tailwindStyles="bg-black text-white w-14"
              onClick={() => console.log("")}
            />
          </div>

          <p className="text-white text-base font-light mt-3 mb-4">Defina as suas credênciais</p>
          <form onSubmit={handleSubmit(handlePasswords)}>
            <p className="font-light text-sm mb-3 text-[#AA7339]">
              Senha de no mínimo 8 dígitos devendo conter 1 Letra Maiúscula, <br />1 Letra Minúscula, 1 Caractere
              especial e 1 Número:
            </p>
            <div className="flex gap-4 mb-5">
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <FormInput
                    isPassword
                    style={{
                      width: 265,
                    }}
                    placeholder="Senha"
                    onChangeText={(e) => field.onChange(e)}
                    required
                    {...field}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <FormInput
                    style={{
                      width: 265,
                    }}
                    isPassword
                    placeholder="Repetir Senha"
                    required
                    onChangeText={(e) => field.onChange(e)}
                    {...field}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <p className="font-light text-sm mb-3 text-[#AA7339]">PIN de 4 Dígitos numéricos não sequenciais:</p>

            <div className="flex gap-4 mb-5">
              <Controller
                name="pin"
                control={control}
                render={({ field, fieldState }) => (
                  <FormInput
                    style={{
                      width: 265,
                    }}
                    isPassword
                    maxLength={4}
                    onChangeText={(e) => field.onChange(e)}
                    placeholder="PIN"
                    required
                    {...field}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                name="confirmPin"
                control={control}
                render={({ field, fieldState }) => (
                  <FormInput
                    style={{
                      width: 265,
                    }}
                    isPassword
                    maxLength={4}
                    onChangeText={(e) => field.onChange(e)}
                    placeholder="Repita o seu PIN"
                    required
                    {...field}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <p className="font-light text-sm mb-3 text-[#AA7339]">
              Senha eletrônica de no mínimo 8 dígitos não sequenciais:
            </p>

            <div className="flex gap-4">
              <Controller
                name="electronicSignature"
                control={control}
                render={({ field, fieldState }) => (
                  <FormInput
                    style={{
                      width: 265,
                    }}
                    isPassword
                    maxLength={8}
                    onChangeText={(e) => field.onChange(e)}
                    placeholder="Senha eletrônica"
                    required
                    {...field}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                name="confirmElectronicSignature"
                control={control}
                render={({ field, fieldState }) => (
                  <FormInput
                    style={{
                      width: 265,
                    }}
                    isPassword
                    maxLength={8}
                    onChangeText={(e) => field.onChange(e)}
                    placeholder="Repetir Senha eletrônica"
                    required
                    {...field}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <div className="flex items-center justify-between mt-7">
              <GradientButton
                title="Voltar"
                tailwindStyles="bg-[#171717] text-white w-[16rem] h-12"
                onClick={() =>
                  navigate(
                    accountType === "PF"
                      ? "/create-account-wallet/insert-account-address"
                      : "/create-account-wallet/insert-partner-account-address",
                    { state: { accountType } }
                  )
                }
              />
              <GradientButton
                isLoading={loading}
                title="Avançar"
                tailwindStyles="bg-[#AA7339] text-white w-[16rem] h-12"
                onClick={handleSubmit(handlePasswords)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
