import React, { CSSProperties, useEffect, useState } from "react";

import { Container } from "./styles";

interface Props {
  buttonText?: string;
  linkTo?: string;
  onClick?: () => void;
  style?: CSSProperties; 

}

const ButtonOpenAccount: React.FC<Props> = ({
  buttonText = "Abrir minha conta",
  linkTo = "/create-account-wallet",
  onClick,
  style 
}) => {

  const [currentLink, setCurrentLink] = useState(linkTo);

  useEffect(() => {
    const updateLinkForMobile = () => {
      if (window.matchMedia("(max-width: 720px)").matches) {
        setCurrentLink("/signin");
      } else {
        setCurrentLink(linkTo);
      }
    };

    updateLinkForMobile(); // Atualiza o link ao carregar a página
    window.addEventListener("resize", updateLinkForMobile); // Atualiza o link ao redimensionar a janela

    return () => {
      window.removeEventListener("resize", updateLinkForMobile);
    };
  }, [linkTo]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Container href={currentLink}  onClick={handleClick} style={style}>
        <span>{buttonText}</span>
      </Container>
    </>
  );
};

export default ButtonOpenAccount;
