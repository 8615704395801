import { useState } from "react";
import icon from "src/assets/copy.png";
import {
	ModalClose,
	ModalContainer,
	ModalContent,
	ModalRoot,
} from "src/components/ui/modal";

export const CopyAndPaste = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<section className="w-[80%] flex flex-col px-8">
			<h1 className="text-[20px] font-bold mt-8">Ações</h1>
			<button
				type="button"
				onClick={() => setIsOpen(true)}
				className="bg-[#FFFFFF] rounded-lg text-[25px] font-bold flex justify-items-start relative w-[300px] py-8 pl-2 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
			>
				Realizar pagamento
				<br />
				copia e cola
				<img
					src={icon}
					alt="icon"
					className="absolute h-20 object-cover right-0 bottom-0"
				/>
			</button>
			<ModalRoot open={isOpen} setIsOpen={setIsOpen}>
				<ModalContainer>
					<ModalContent>
						<ModalClose />
						<h1>teste</h1>
					</ModalContent>
				</ModalContainer>
			</ModalRoot>
		</section>
	);
};
