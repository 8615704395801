import React from "react";
import SectionBenefits from "../../components/LPSectionBenefits";
import SectionFAQ from "../../components/LPSectionFAQ";
import Footer from "../../components/LPfooter";
import Header from "../../components/LPheader";
import SectionAccount from "../../components/LPsectionAccount";
import SectionAccountBussiness from "../../components/LPsectionAccountBussiness";
import SectionCountWithStric from "../../components/LPsectionCountWithStric";
import SectionDayaDayAccount from "../../components/LPsectionDayaDayAccount";
import SectionProduct from "../../components/LPsectionProduct";
import SectionStart from "../../components/LPsectionStart";

import { Container } from "./styles";

import Helmet from "react-helmet";
// import { useTheme } from "styled-components";

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>VILAPAY-BANK - A conta digital perfeita</title>
        <meta
          name="description"
          content="VILAPAY-BANK - A conta digital perfeita"
        />
        <meta name="theme-color" content="#fff" />
      </Helmet>

      <Container>
        <Header />
        <SectionStart />
        <SectionProduct />
        <SectionAccount />
        <SectionDayaDayAccount />
        <SectionAccountBussiness />
        <SectionBenefits />
        <SectionFAQ />
        <SectionCountWithStric />
        <Footer />
      </Container>
    </>
  );
};

export default Home;
