import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SettingsIcon } from "src/assets/icons/settingsIcons";
import { Dropdown } from "src/components/Dropdown";
import { useAuth } from "src/hooks/auth";

type OptionItem = {
	onSelect: () => void;
	selected: boolean;
	title: string;
};

export function Settings() {
	const navigate = useNavigate();

	const { user } = useAuth();
	const [show, setShow] = useState(false);
	const href = window.location.href;

	const options: OptionItem[] = [
		{
			onSelect: () => {
				navigate("/configkeypasswordeletronic");
			},
			selected: window.location.href.includes("/configkeypasswordeletronic"),
			title: "Senha eletrônica",
		},
		{
			onSelect: () => {
				navigate("/tax");
			},
			selected: window.location.href.includes("/tax"),
			title: "Tarifa PF",
		},
		{
			onSelect: () => {
				navigate("/support");
			},
			selected: window.location.href.includes("/support"),
			title: "Suporte",
		},
		{
			onSelect: () => {
				navigate("/signout");
			},
			selected: false,
			title: "Sair",
		},
	];

	const updatedOptions: OptionItem[] = options
		.filter((item) => {
			if (user && user.role !== "MASTER") {
				return item.title !== "Get Transactions";
			} else {
				return item;
			}
		})
		.reduce((acc: OptionItem[], item: OptionItem) => {
			if (item.title === "Taxa manual" && show) {
				const newItems: OptionItem[] = [
					{
						onSelect: () => {
							navigate("/backoffice/signup-pf");
						},
						selected: href.endsWith("/backoffice/signup-pf"),
						title: "Conta PF",
					},
					{
						onSelect: () => {
							navigate("/backoffice/signup-pj");
						},
						selected: href.endsWith("/backoffice/signup-pj"),
						title: "Contas PJ",
					},
					{
						onSelect: () => {
							alert("Em construção");
						},
						selected: href.endsWith("/backoffice/signup-pj"),
						title: "Cadastro em massa",
					},
				];
				acc.push(...newItems);
			}
			acc.push(item);
			return acc;
		}, []);

	return (
		<Dropdown
			title={
				<SettingsIcon className="w-[25px] h-[25px] 1xl:w-[35px] 1xl:h-[35px] 3xl:w-[55px] 3xl:h-[55px] 6xl:w-[70px] 6xl:h-[70px]" />
			}
			min
			listWidth={200}
			width={50}
			options={updatedOptions}
		/>
	);
}
