import { ReactComponent as ArrowPageLeftIconSVG } from "../../assets/arrowPageLeft.svg";
import { ReactComponent as ArrowPageRightIconSVG } from "../../assets/arrowPageRight.svg";
import { ReactComponent as CloseIconSVG } from "../../assets/close.svg";
import { ReactComponent as DownloadIconSVG } from "../../assets/download.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import { ReactComponent as ReceiptIconSVG } from "../../assets/receipt.svg";
import styled from "styled-components";

export const ReceiptIcon = styled(ReceiptIconSVG)`
  fill: var(--cian);
`;

export const ArrowPageRightIcon = styled(ArrowPageRightIconSVG)`
  fill: var(--gray);
  cursor: pointer;
`;

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`
  fill: var(--gray);
  cursor: pointer;
`;

export const CloseIcon = styled(CloseIconSVG)`
  fill: var(--white);
`;

export const DownloadIcon = styled(DownloadIconSVG)`
  fill: var(--white);
`;

export const Modal = styled(ReactModal).attrs({
  style: {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: "3",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      height: "552px",
      maxWidth: "448px",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      margin: "auto",
      background: "#fff",
      // overflow: 'auto',
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
    },
  },
})``;

export const ModalHeader = styled.header`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
  height: 72px;
  background: var(--gray);
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 24px;

    > span {
      color: var(--white);
      font-family: var(--semiBold);
      font-size: 16px;
    }

    > button {
      cursor: pointer;
      background: none;
      border-style: none;
    }
  }

  &::before {
    content: "";
    height: 16px;
    width: 4px;
    background: var(--cian);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const InfoModal = styled.div`
  display: flex;
  width: 100%;

  > div {
    flex: 1;
    > span {
      font-size: 16px;
      font-family: var(--regular);
      color: var(--gray-light-text);
      margin-bottom: 11px;
    }
    > p {
      font-size: 29px;
      font-family: var(--semiBold);
      color: var(--gray);
    }
  }

  > span {
    font-size: 12px;
    font-family: var(--regular);
    color: var(--cian);
  }
`;

export const ModalContent = styled.div`
  padding: 32px;
`;
export const Section = styled.div`
  margin: 32px 0;
`;

export const SectionTitle = styled.h2`
  color: var(--modalTitle);
  font-size: 16px;
  font-family: var(--bold);
  margin-bottom: 15px;
`;

export const SectionPayerName = styled.div`
  > span {
    color: var(--cian);
    font-size: 12px;
    font-family: var(--regular);
  }

  > p {
    color: var(--gray-light-text);
    font-size: 12px;
    font-family: var(--regular);
  }
`;

export const SectionItem = styled.div`
  display: flex;

  > span {
    color: var(--cian);
    font-size: 12px;
    font-family: var(--regular);
    margin-right: 9px;
  }

  > p {
    color: var(--gray-light-text);
    font-size: 12px;
    font-family: var(--regular);
  }
`;

export const SectionCPNJ = styled.div`
  display: flex;

  > span {
    color: var(--cian);
    font-size: 12px;
    font-family: var(--regular);
    margin-right: 9px;
  }

  > p {
    color: var(--gray-light-text);
    font-size: 12px;
  }
`;

export const SectionAccountinfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
`;

export const Container = styled.div`
  height: calc(100vh - 80px);
  background-color: var(--background);
`;

export const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
`;

export const Table = styled.div`
  flex: 1;
  padding: 32px;
`;

export const TableWrapper = styled.div`
  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px) {
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px) {
    width: 100%;
  }

  > table {
    width: 100%;

    > tbody {
      border-radius: 8px;
      border: none;
      border-collapse: collapse;

      > tr {
        position: relative;

        &:first-child {
          &::after {
            content: "";
            height: 16px;
            width: 4px;
            background-color: var(--cian);
            position: absolute;
            bottom: 0;
            top: 0;
            margin-top: auto;
            margin-bottom: auto;
            left: 0;
          }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
          text-align: start;
          font-family: var(--semiBold);
          font-size: 14px;
          color: var(--gray);
          padding: 16px;

          @media (min-width: 720px) {
            font-size: 16px;
            padding: 21px 32px;
          }
        }

        > td {
          padding: 6px 16px;
          font-family: var(--regular);
          font-size: 12px;
          color: var(--gray-light-text);

          &:last-child {
            color: var(--red);
          }

          @media (min-width: 720px) {
            font-size: 15px;
            padding: 14px 32px;
          }
        }
      }
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
  padding: 32px;
  align-self: center;

  > span {
    font-family: var(--regular);
    color: var(--gray-light-text);
    font-size: 16px;
  }

  .active {
    height: 32px;
    width: 32px;
    background-color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Icon = styled(FontAwesomeIcon)`
  color: #fff;
`;

export const PrintButtonIcon = styled.button`
  align-items: center; /* Alinha verticalmente os elementos ao centro */
  background-color: transparent;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  width: 50px;
  color: #fff;
  &:hover {
    background-color: transparent; /* cor vermelha quando o mouse passa por cima */
    transform: scale(1.05); /* destaque um pouco */
    border: none;
  }

  & > svg {
    margin-right: 18px; /* Espaçamento entre o ícone e o texto */
    width: 30px;
    height: 30px;
    border: none;
  }
`;

export const ButtonIcon = styled.button`
  display: flex; /* Adiciona o display flex para alinhar os elementos lado a lado */
  align-items: center; /* Alinha verticalmente os elementos ao centro */
  background-color: #ff0013;
  border: 1px solid var(--white); /* cor padrão da borda */
  padding: 6px 12px; /* Ajuste o valor do padding para reduzir a altura */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  width: 120px;
  color: #fff;
  &:hover {
    background-color: red; /* cor vermelha quando o mouse passa por cima */
    transform: scale(1.05); /* destaque um pouco */
  }

  & > svg {
    margin-right: 8px; /* Espaçamento entre o ícone e o texto */
  }
`;
