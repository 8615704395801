import { useNavigate } from "react-router-dom";
import successNewPixTransaction from "../../../../../assets/successNewPixTransaction.svg";

export function SuccessOnTransactionStep() {
	const navigate = useNavigate();

	function handleClick() {
		navigate("/");
	}

	return (
		<div className="flex flex-col gap-[1.875rem] w-[570px] h-[517px] items-center justify-center px-12 py-20 drop-shadow-md rounded-[1.875rem]">
			<img src={successNewPixTransaction} alt="check icon" />
			<p className="text-[1.875rem] font-semibold text-center w-80">
				Pix realizado com sucesso!
			</p>
			<button
				type="button"
				onClick={handleClick}
				className="text-[0.9375rem] py-7 font-bold bg-[#004A8A] w-[475px] text-white h-[75px] flex items-center justify-center rounded-[10px] transition hover:brightness-75"
			>
				Ver comprovante
			</button>
		</div>
	);
}
