import { FC, useRef, useState } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircle, UploadSimple } from "phosphor-react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "src/services/firebase";
import { api } from "src/services/api";

export const SendDocumentPJ: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [frontImage, setFrontImage] = useState<any>(null);
  const [backImage, setBackImage] = useState<any>(null);

  const navigate = useNavigate();

  const frontImageInputRef = useRef<any>(null);
  const backImageInputRef = useRef<any>(null);

  const handleFrontImageUpload = () => {
    frontImageInputRef?.current?.click();
  };

  const handleBackImageUpload = () => {
    backImageInputRef?.current?.click();
  };

  const handleSelectFrontImage = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setFrontImage(file);
    }
  };

  const handleSelectBackImage = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setBackImage(file);
    }
  };

  const handleSavePhoto = async () => {
    if (!frontImage || !backImage) {
      return;
    }

    setLoading(true);

    // Upload da imagem frontal
    const frontStorageRef = ref(storage, `documents/${frontImage.name}`);
    const frontImageUploadTask = uploadBytesResumable(frontStorageRef, frontImage);

    frontImageUploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        setLoading(false);
        console.log(error);
      },
      async () => {
        const frontDocumentUri = await getDownloadURL(frontImageUploadTask.snapshot.ref);

        if (frontDocumentUri) {
          console.log(frontDocumentUri)
          const backStorageRef = ref(storage, `documents/${backImage.name}`);
          const backImageUploadTask = uploadBytesResumable(backStorageRef, backImage);

          backImageUploadTask.on(
            "state_changed",
            () => {},
            (error) => {
              setLoading(false);
              console.log(error);
            },
            async () => {
              const backDocumentUri = await getDownloadURL(backImageUploadTask.snapshot.ref);
              console.log(backDocumentUri)
              if (backDocumentUri) {
                // Carregar dados do localStorage
                const addressData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-03")));
                const planData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-02")));
                const userData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-01")));
                const selfUri = String(localStorage.getItem("@yntech:documentUri"));
                console.log(selfUri)

                let documentData = {};
                if (documentType === "CNH") {
                  documentData = {
                    cnhFront: frontDocumentUri,
                    cnhBack: backDocumentUri
                  };
                } else if (documentType === "RG") {
                  documentData = {
                    rgFront: frontDocumentUri,
                    rgBack: backDocumentUri
                  };
                }

                const data = {
                  ...addressData,
                  ...planData,
                  ...userData,
                  ...documentData,
                  selfUri
                };

                if(accountType == "PJ") {
                    const data = {
                      ...addressData,
                      ...planData,
                      ...userData,
                      ...documentData,
                      selfUri
                    };

                    localStorage.setItem("@yntech:pj-data", JSON.stringify(data));

                   navigate("/create-account-wallet/pj-alert", { state: { accountType } });
                } else {
                   await api.post("/verify/create", {
                    data,
                    rg_cnh: planData.documentNumber.replace(/[/,.,-\s]/g, ""),
                  });


                  localStorage.removeItem("@yntech:signup-data-03");
                  localStorage.removeItem("@yntech:signup-data-02");
                  localStorage.removeItem("@yntech:signup-data-01");
                  localStorage.removeItem("@yntech:signup-data-partner");
                  localStorage.removeItem("@yntech:signup-data-partner-address");

                  navigate("/create-account-wallet/success", { state: { accountType } });
                }
              }
            }
          );
        }
      }
    );
  };



  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background max-2xl:gap-0">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/identification-alert", { state: { accountType } })} />

      <div>
        <svg className="relative top-5 max-2xl:top-0" width="750" height="95vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#AA7339" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="700"
            height="93vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[8vh] ml-14 max-2xl:top-[6vh]">
          <p className="text-white font-extralight text-2xl max-2xl:text-base">
            Precisamos que nos envie as fotos <br />
            do <strong className="text-white font-bold text-2xl max-2xl:text-base">seu</strong> documento
          </p>

          <h3 className="text-white font-extralight text-base mt-3 mb-4 max-2xl:mt-1 max-2xl:mb-2">
            Selecione o tipo de documento:
          </h3>

          <div className="flex items-center gap-5">
            <button
              type="button"
              onClick={() => setDocumentType("RG")}
              className="flex items-center justify-center gap-2 text-white font-extralight max-2xl:text-sm"
            >
              <div className="w-4 h-4 rounded-full border-[1px] border-white flex items-center justify-center">
                {documentType === "RG" && <div className="rounded-full bg-white w-2 h-2"></div>}
              </div>
              Registro Geral (RG)
            </button>
            <button
              type="button"
              onClick={() => setDocumentType("CNH")}
              className="flex items-center justify-center gap-2 text-white font-extralight max-2xl:text-sm"
            >
              <div className="w-4 h-4 rounded-full border-[1px] border-white flex items-center justify-center">
                {documentType === "CNH" && <div className="rounded-full bg-white w-2 h-2"></div>}
              </div>
              Carteira Nacional de Habilitação (CNH)
            </button>
          </div>

          <h3
            className={`text-white font-extralight text-base mt-3 mb-4 max-2xl:text-sm max-2xl:mb-2 ${
              documentType === "" && "text-opacity-50"
            }`}
          >
            Selecione a foto em seu computador:
          </h3>

          <h3
            className={`text-white font-extralight text-lg mt-3 max-2xl:text-sm ${
              documentType === "" && "text-opacity-50"
            }`}
          >
            Frente
          </h3>
          <input
            disabled={documentType === ""}
            type="file"
            accept="image/*"
            onChange={handleSelectFrontImage}
            ref={frontImageInputRef}
            style={{ display: "none" }}
          />

          <button
            disabled={documentType === ""}
            onClick={handleFrontImageUpload}
            className={`w-[35rem] h-32 rounded-2xl bg-white flex items-center justify-center mt-3 mb-3 max-2xl:h-20 max-2xl:w-[13rem] max-2xl:rounded-lg ${
              documentType === "" && "bg-opacity-30"
            }`}
          >
            {frontImage !== null ? (
              <CheckCircle color="#4BBD9C" size={50} />
            ) : (
              <UploadSimple color={documentType === "" ? "rgba(255, 255, 255, 30%)" : "#CFCFCF"} size={32} />
            )}
          </button>

          <h3
            className={`text-white font-extralight text-lg mt-3 max-2xl:text-sm ${
              documentType === "" && "text-opacity-50"
            }`}
          >
            Verso
          </h3>

          <input
            disabled={documentType === ""}
            type="file"
            accept="image/*"
            onChange={handleSelectBackImage}
            ref={backImageInputRef}
            style={{ display: "none" }}
          />

          <button
            disabled={documentType === ""}
            onClick={handleBackImageUpload}
            className={`w-[35rem] h-32 rounded-2xl bg-white flex items-center justify-center mt-3 mb-3 max-2xl:h-20 max-2xl:w-[13rem] max-2xl:rounded-lg ${
              documentType === "" && "bg-opacity-30"
            }`}
          >
            {backImage !== null ? (
              <CheckCircle color="#4BBD9C" size={50} />
            ) : (
              <UploadSimple color={documentType === "" ? "rgba(255, 255, 255, 30%)" : "#CFCFCF"} size={32} />
            )}
          </button>

          <p className="text-white font-extralight text-sm mt-3 max-2xl:text-xs">
            O ambiente deve está bem iluminado de forma moderada. Dê <br />
            preferência á iluminação natural. Tome cuidado com os reflexos <br />
            que possam deixara sua foto com uma visualização ruim.{" "}
          </p>

          <div className="flex gap-4 mt-10 max-2xl:mt-5">
            <GradientButton
              title="Voltar"
              tailwindStyles="bg-[#171717] text-white w-[17.7rem] h-12"
              onClick={() => navigate("/create-account-wallet/identification-alert", { state: { accountType } })}
            />
            <GradientButton
              isLoading={loading}
              title="Avançar"
              tailwindStyles="bg-[#AA7339] text-white w-[17.7rem] h-12"
              onClick={handleSavePhoto}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
