import { CryptoBoard } from "../CryptoBoard";
import { Exchange } from "../Exchange";
import { Machine } from "../Machine";
import { Container } from "./MainLeftSection.styles";

const MainLeftSection: React.FC = () => {
  return (
    <Container>
      <Exchange />
      <Machine />
      <CryptoBoard />
    </Container>
  );
};

export default MainLeftSection;
