// TecladoVirtual.tsx
import styled, { css, ThemeProps } from "styled-components";
import { MdArrowUpward } from "react-icons/md";

import ButtonSignIn2 from "../../../src/assets/button-color.svg";

import { ReactComponent as EyeEnabledIconSVG } from "../../assets/eyeEnabled.svg";
import { ReactComponent as EyeDisabledIconSVG } from "../../assets/eyeDisabled.svg";
import { Link } from "react-router-dom";

interface CustomIconProps extends React.ComponentProps<typeof MdArrowUpward> {
  isClicked?: boolean;
}

interface KeyboardKeyProps {
  width?: string;
  height?: string;
  isClicked?: boolean;
}

export const KeyboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; /* Adicionado para centralizar verticalmente */
  position: relative; /* Alterado para 'fixed' para garantir que permaneça fixo na tela */
  top: 50%; /* Posiciona o elemento no centro vertical */
  left: 50%; /* Posiciona o elemento no centro horizontal */
  transform: translate(
    -50%,
    -50%
  ); /* Ajusta o centro com base nas dimensões do próprio elemento */
  z-index: 1000;
  background-color: transparent;
  //background-color: #16385b;
  // border: 5px solid red;
  border-radius: 14px;
  padding: 10px;
  width: 99%;
`;

export const InputBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; /* Adicionado para centralizar verticalmente */
  position: fixed; /* Alterado para 'fixed' para garantir que permaneça fixo na tela */
  top: 50%; /* Posiciona o elemento no centro vertical */
  left: 50%; /* Posiciona o elemento no centro horizontal */
  transform: translate(
    -50%,
    -50%
  ); /* Ajusta o centro com base nas dimensões do próprio elemento */
  z-index: 1000;
  background-color: transparent;
  //border: 5px solid #fff;
  border-radius: 28px;
  padding: 10px;
  width: 101%;
  height: 102%;
`;
export const KeyboardRow = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: colunm;
  align-items: center;
  justify-content: flex-start;
`;

export const BackLogo = styled.img<KeyboardKeyProps>`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: -11px;
  margin-top: -11px;
  color: white;
  transition: background-color 0.3s ease;
  background-color: red;
  min-width: 57.5px;
  height: 50px;
  padding: 10px;
  &:hover {
    background-color: #d0d0d0;
    color: red;
  }
`;
export const KeyboardKey = styled.button<KeyboardKeyProps>`
  font-size: 18px;
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: transparent;
  color: white;
  overflow: hidden;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}

  &:hover {
    background-color: #d0d0d0;
    color: black;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 900;
  background-color: transparent; /* Cor do fundo do modal */
  border: 11px solid #fff;
  padding: 10px;
  width: 1200px;
  height: 600px;
  //border-radius: 8px; /* Valor menor para criar um efeito de borda interna */
  //box-shadow: 0 0 0 2px #ccc; /* Adiciona uma sombra que simula a borda externa */
  //overflow: hidden;
  backdrop-filter: blur(6px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.63);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ); /* Cor escura para ofuscar o conteúdo ao redor */
  z-index: 999; /* Z-index inferior ao do modal para garantir que fique por trás */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const KeyboardKeyShift = styled.button`
  // Adicione estilos padrão aqui
  width: 57.5px;
  height: 50px;
  margin: 5px;
  // Adicione outros estilos conforme necessário

  // Estilos para a classe shift-active
  &.shift-active {
    background-color: #4caf50; /* Cor de fundo verde quando o Shift está ativado */
    color: white; /* Cor do texto branca quando o Shift está ativado */
    // Adicione outros estilos específicos para o Shift ativado
  }
`;

export const ShiftIcon = styled(MdArrowUpward)<CustomIconProps>`
  // Adicione estilos padrão para o ícone Shift
  width: 57.5px;
  height: 40px;
  margin: 5px;
  margin-top: -8px;
  margin-left: 50px;

  // Adicione estilos condicionais se o Shift estiver clicado
  ${({ isClicked }) =>
    isClicked &&
    `
    // Adicione estilos específicos quando o Shift estiver clicado
    //background-color: #333;
   // color: #fff;
    height: 50px;
    margin-top: -11px;

  `}
`;
// ...

interface WrapperInterface {
  image: string;
  bank: string;
}

export const EyeEnabledIcon = styled(EyeEnabledIconSVG)`
  fill: var(--cian);
`;
export const EyeDisabledIcon = styled(EyeDisabledIconSVG)`
  fill: var(--cian);
`;

export const Container = styled.div<WrapperInterface>`
  ${(props) =>
    props.bank === "VILAPAY-BANK"
      ? css<WrapperInterface>`
          background: url(${(props) => props.image}) no-repeat;
          background-size: cover;
        `
      : css`
          background-color: var(--loginBackground);
        `}

  @media (max-width: 720px) {
    background-image: none;
    background: linear-gradient(
      196deg,
      #1a70b4 9.36%,
      rgba(217, 217, 217, 0.1) 100.78%
    );
  }

  min-height: 100vh;
  overflow: scroll;
  display: flex;
`;

export const WrapperContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
 // border: 1px solid white;

  @media (max-width: 720px) {
    background-color: #ffffff70;
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  // border: 1px solid black;
  justify-content: center;
  padding: 0 10px;
  display: flex;
  flex-direction: column; /* Alterado para 'column' para empilhar os itens verticalmente */
`;

export const WrapperFormRow = styled.form`
  top: 50%; /* Posiciona o elemento no centro vertical */
  left: 50%; /* Posiciona o elemento no centro horizontal */
  //transform: translate(-50%, -50%); /* Ajusta o centro com base nas dimensões do próprio elemento */
  z-index: 1001;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Adicione esta linha para espaço entre os itens */
  margin-top: 12px;
  //border: 2px solid green;
  gap: 30px;
  //background-color: #16385b;
`;

export const WrapperForm = styled.form`
  top: 50%; /* Posiciona o elemento no centro vertical */
  left: 50%; /* Posiciona o elemento no centro horizontal */
  //transform: translate(-50%, -50%); /* Ajusta o centro com base nas dimensões do próprio elemento */
  z-index: 1005;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Adicione esta linha para espaço entre os itens */
  margin-top: 12px;
 // border: 2px solid green;
  gap: 30px;
  //background-color: #16385b;
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid var(--cian);
  border-radius: 10px;
  height: 66px;
  width: 100%;
  padding: 10px 11px;
  background-color: transparent;
  & + div {
    //  margin-top: 24px;
  }

  > span {
    flex: 1;
    color: var(--white);
    font-family: var(--regular);
    font-size: 14px;
  }

  > input {
    border-style: none;
    flex: 1;
    background: transparent;
    color: var(--cian);
    font-family: var(--semiBold);
    outline: 0;

    &::placeholder {
      color: var(--white);
      opacity: 1;
      font-family: var(--semiBold);
      font-size: 14px;
    }
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ButtonEyeVisibility = styled.button`
  background: none;
  border-style: none;
  cursor: pointer;
`;

export const ButtonVisibility = styled.button`
  background: none;
  border-style: none;
  cursor: pointer;
`;

export const InputPassword = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--cian);
  border-radius: 10px;
  height: 66px;
  width: 100%;
  padding: 10px 11px;
  background-color: transparent;
  & + div {
    margin-top: 24px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    > span {
      flex: 1;
      color: var(--white);
      font-family: var(--regular);
      font-size: 14px;
    }

    > input {
      border-style: none;
      flex: 1;
      background: transparent;
      color: var(--cian);
      font-family: var(--semiBold);
      outline: 0;

      &::placeholder {
        color: var(--white);
        opacity: 1;
        font-family: var(--semiBold);
        font-size: 14px;
      }
    }
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ForgetPassword = styled.a<{ isKeyboardVisible: boolean }>`
  color: var(--white);
  font-family: var(--semiBold);
  font-size: 16px;
  align-self: center;
  margin-top: 22px;
  text-decoration: ${(props) => (props.isKeyboardVisible ? 'none' : 'underline')};

  @media (max-width: 720px) {
    font-family: "Inter";
    font-weight: 400;
    font-size: 13px;
    line-height: 15.68px;
    color: #3f36a7;
    margin-top: 10px;
  }
`;

export const GoBackButtonWrapper = styled.button`
display: flex; /* Alinha os itens horizontalmente */
align-items: center; /* Centraliza os itens verticalmente */
justify-content: flex-start; /* Centraliza horizontalmente */
width: auto; /* Ajusta automaticamente à largura do conteúdo */
height: auto; /* Ajusta automaticamente à altura do conteúdo */
border: none; /* Remove bordas extras */
font-size: 14px;
color: var(--white);
background-color: transparent;
margin-right: 24px;
cursor: pointer;

> svg {
  margin-right: 8px; /* Espaço entre o ícone e o texto */
}

&:hover {
  opacity: 0.8; /* Feedback visual ao passar o mouse */
}
`;


export const Title = styled.div`
  margin-top: 24px;
  font-size: 24px;
  color: var(--colorLogin);
  font-family: var(--regular);
  width: 359px;
  height: 100px;

  > b {
    font-family: var(--semiBold);
  }

  @media (max-width: 720px) {
    .hide-on-small-screens {
      display: none;
    }
  }

  @media (min-width: 1280px) {
    font-size: 24px;
  }
`;


export const Line = styled.div`
  background-color: var(--black);
  width: 100%;
  height: 1px;
  margin: 10px 0;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const NotHaveAnAccount = styled(Link)`
  align-self: center;
  color: var(--colorLogin);
  font-family: var(--bold);
  font-size: 16px;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const NotHaveAnAccountMobile = styled(Link)`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #3f36a7;
    margin-top: 10px;
  }
`;

export const ButtonSignInMobile = styled.button`
  display: none;

  @media (max-width: 720px) {
    display: block;
    width: 100%;
    height: 64px;
    border-radius: 64px;
    box-shadow: 10px -10px 20px rgba(0, 0, 0, 0.15);
    background: linear-gradient(101deg, white 0%, rgba(255, 255, 255, 0) 100%),
      rgba(217, 217, 217, 0.25);
    margin-top: 20px;
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
  }
`;

export const ButtonSignIn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 24px;
  height: 65px;
  width: 100%;
  color: var(--white);
  font-family: var(--semiBold);
  border-style: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  //background-image: url(${ButtonSignIn2});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ButtonAcess = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  height: 69px;
  width: 100%;
  color: var(--white);
  font-family: var(--semiBold);
  border-style: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-image: url(${ButtonSignIn2});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 720px) {
    display: none;
  }
`;
// export const ButtonSignIn = styled.button`
// display: flex;
// justify-content: center;
// align-items: center;
// margin-top: 24px;
// height: 69px;
// width: 100%;
// color: var(--white);
// font-family: var(--semiBold);
// border-style: none;
// border-radius: 4px;
// font-size: 16px;
// cursor: pointer;
// backgroundImage: `url(${ButtonSignIn2})`;
//   backgroundRepeat: "no-repeat";
//   backgroundSize: "cover";
// `

export const Error = styled.div`
  color: var(--red);
  /* border: 1px solid var(--colorLogin); */
  border-radius: 4px;
  padding: 6px;
  margin-top: 1px; /* Adicionado para dar espaço acima da mensagem de erro */
  margin-bottom: -65px; /* Adicionado para dar espaço acima da mensagem de erro */
  width: 100%;
`;

export const CloseButton = styled.button`
  font-size: 18px;
  padding: 10px;
  margin: 5px;
  margin-left: 223px;
  margin-top: -141px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #ff0013;
  z-index: 1009;
  width: 58px;
  height: 49.5px;

  &:hover {
    background-color: #d0d0d0;
  }
`;
